import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContentInline from 'components/AsyncContent/AsyncContentInline';
import Authentication from 'utils/Authentication';
import GameSelectContent from './GameSelectContent';
import {useGameSelectContext} from './GameSelectProvider';

const propTypes = {
  className: PropTypes.string
};

const query = gql`
  query Game($where: game_bool_exp! = {}) {
    games: game(order_by: {executionfrom: desc}, where: $where) {
      id
      title
      boardtype
      execution
    }
  }
`;

export default function GameSelect({className}) {
  const $orgPermissionsFilter =
    Authentication.getOrganisationId() > 0
      ? {
          deleted: {_eq: false},
          organisationid: {_eq: Authentication.getOrganisationId()}
        }
      : {deleted: {_eq: false}};

  const {game, onChangeGame} = useGameSelectContext();
  const {data, error, loading} = useQuery(query, {
    variables: {
      where: $orgPermissionsFilter
    }
  });

  function onSelectGame(selectedGame) {
    onChangeGame(selectedGame);
  }

  return (
    <div className={className}>
      <AsyncContentInline error={error} loading={loading}>
        {() => (
          <GameSelectContent
            games={data.games}
            onSelectGame={onSelectGame}
            selectedGame={game}
          />
        )}
      </AsyncContentInline>
    </div>
  );
}

GameSelect.propTypes = propTypes;
