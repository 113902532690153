import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import EnvUtils from 'utils/EnvUtils';
import EconomicaccpostingsContent from './EconomicaccpostingsContent';

const propTypes = {
  id: PropTypes.number
};

const query = gql`
  query getEconomicaccposting($economicaccid: Int!) {
    page: economicaccposting_aggregate(
      where: {economicaccid: {_eq: $economicaccid}}
      limit: 10000
    ) {
      aggregate {
        totalCount: count
      }
    }
    economicaccpostings: economicaccposting(
      order_by: {id: desc}
      where: {economicaccid: {_eq: $economicaccid}}
    ) {
      id
      economicaccid
      economictranslogid
      amount
      balance
      erda
      economictranslog {
        transactiontype
        transactioncode
        txt
        gameperiod {
          name
        }
        toeconomicacc {
          player {
            title
            id
          }
        }
        fromeconomicaccid
        fromeconomicacc {
          player {
            title
            id
          }
        }
      }
    }
  }
`;

export default function Economicaccpostings({id}) {
  const {
    data: resultEconomicaccpostings,
    error,
    loading
  } = useQuery(query, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: {economicaccid: id}
  });

  return (
    <AsyncContent error={error} loading={loading}>
      <EconomicaccpostingsContent
        resultEconomicaccpostings={resultEconomicaccpostings}
      />
    </AsyncContent>
  );
}

Economicaccpostings.propTypes = propTypes;
