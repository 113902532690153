import Typography from '@mui/material/Typography';
import gql from 'graphql-tag';
import React, {useState} from 'react';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import usePaginatedQuery from 'hooks/usePaginatedQuery';
import EnvUtils from 'utils/EnvUtils';
import GlobalmarketsContent from './GlobalmarketsContent';
import labels from './labels';

const query = gql`
  query entity(
    $where: economicacc_transf_capacity_demand_bool_exp! = {}
    $limit: Int = 25
    $offset: Int
  ) {
    aggregate: entity_aggregate(
      where: {
        deleted: {_eq: false}
        _and: {
          entitytype: {
            _in: [
              "food"
              "resource"
              "industrialproducts"
              "agriculturalproducts"
            ]
          }
        }
      }
      order_by: {name: asc}
    ) {
      globalmarkets: aggregate {
        totalCount: count
      }
    }
    globalmarkets: entity(
      where: {
        deleted: {_eq: false}
        _and: {
          entitytype: {
            _in: [
              "food"
              "resource"
              "industrialproducts"
              "agriculturalproducts"
            ]
          }
        }
      }
      order_by: {name: asc}
    ) {
      id
      name
      entitytype
      economicacc_transf_capacity_demand_aggregate(
        where: $where
        limit: $limit
        offset: $offset
      ) {
        aggregate {
          sum {
            capacity
            numberrequired
          }
        }
      }
      economicacc_transf_capacity_demand(
        where: $where
        limit: $limit
        offset: $offset
        order_by: {economicacc: {player: {title: asc}}}
      ) {
        economicaccid
        capacity
        numberrequired
        economicacc {
          player {
            title
          }
          economicaccpostings(limit: 1, order_by: {id: desc}) {
            balance
          }
        }
      }
    }
  }
`;

export default function Globalmarkets() {
  const {game} = useGameSelectContext();

  const [filter, setFilter] = useState({
    role: undefined,
    region: undefined,
    numberFilter: undefined
  });

  function onChangeFilter(nextFilter) {
    setFilter((prev) => ({...prev, ...nextFilter}));
  }

  function getVariables() {
    return {
      where: {
        gameperiod: {
          gameid: {_eq: game.id}
        }
      },
      limit: 50
    };
  }

  const {
    data: resultGlobalmarkets,
    loading,
    loadingMore,
    onFetchNextPage
  } = usePaginatedQuery(query, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: getVariables(),
    listName: 'globalmarkets'
  });

  return (
    <>
      <Typography gutterBottom variant='h5'>
        {labels.title}
      </Typography>
      <GlobalmarketsContent
        loading={loading}
        loadingMore={loadingMore}
        onFetchNextPage={onFetchNextPage}
        resultGlobalmarkets={resultGlobalmarkets}
      />
    </>
  );
}
