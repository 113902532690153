import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import Form from 'components/Form/Form';
import FormColumn from 'components/Form/FormColumn';
import FormItem from 'components/Form/FormItem';
import TextInput from 'components/TextInput/TextInput';
import RegionDropDown from 'containers/Entityrole/RegionDropDown';
import LandscaperegionStatusDrowDown from 'containers/Landscaperegion/LandscaperegionStatusDropDown';
import m from '../../messages/de.json';
import cs from './LandscaperegionForm.module.scss';
import labels from './labels';

const validate = (values) => {
  const errors = {};

  if (!values.regionid || values.region === 0) {
    errors.regionid = 'Bitte eine Region wählen';
  }

  if (!values.status || values.status === 0) {
    errors.status = 'Bitte den Status wählen';
  }

  return errors;
};

const initialValues = {
  gameperiodid: ''
};

const propTypes = {
  className: PropTypes.string,
  landscaperegion: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitFinish: PropTypes.func
};

export default function LandscaperegionForm({
  className,
  landscaperegion,
  onCancel,
  onSubmit,
  onSubmitFinish
}) {
  const formik = useFormik({
    initialValues: landscaperegion || initialValues,
    validate,
    onSubmit: onSubmitForm,
    enableReinitialize: true
  });

  const {enqueueSnackbar} = useSnackbar();
  async function onSubmitForm(values) {
    return onSubmit(values)
      .then(() => {
        enqueueSnackbar(m.dataHandlingPromise.fulfilled, {variant: 'success'});
        onSubmitFinish();
      })
      .catch((e) =>
        enqueueSnackbar(`${m.dataHandlingPromise.rejected} ${e?.message}`, {
          variant: 'error',
          persist: true
        })
      );
  }

  return (
    <div className={cx(className, cs.root)}>
      <Typography gutterBottom variant='h6'>
        {labels.title}
      </Typography>
      <Form
        actions={
          <>
            <Button onClick={onCancel} variant='outlined'>
              {labels.cancel}
            </Button>
            <Button
              color='primary'
              disabled={formik.isSubmitting}
              type='submit'
              variant='contained'
            >
              {m.dataHandlingForm.save}
            </Button>
          </>
        }
        onSubmit={formik.handleSubmit}
      >
        <FormColumn>
          <FormItem
            error={formik.errors.gameperiodid}
            label={labels.gameperiodid}
            name='gameperiodid'
            value={formik.values.gameperiodid}
          >
            <TextInput readonly />
          </FormItem>
          <FormItem
            className={cs.landscape}
            error={formik.errors.landscapeid}
            label={labels.landscapeid}
            name='landscapeid'
            value={formik.values.landscape?.code}
          >
            <TextInput readonly />
          </FormItem>
          <FormItem
            error={formik.errors.regionid}
            label={labels.regionid}
            name='regionid'
            onChange={formik.handleChange}
            value={formik.values.regionid}
          >
            <RegionDropDown />
          </FormItem>
          <FormItem
            error={formik.errors.status}
            label={labels.status}
            name='status'
            onChange={formik.handleChange}
            value={formik.values.status}
          >
            <LandscaperegionStatusDrowDown />
          </FormItem>
        </FormColumn>
      </Form>
    </div>
  );
}
LandscaperegionForm.propTypes = propTypes;
