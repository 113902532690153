import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContentInline from 'components/AsyncContent/AsyncContentInline';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import PlayersSelectContent from './PlayersSelectContent';

const propTypes = {
  className: PropTypes.string,
  onChangePlayer: PropTypes.func,
  selectedPlayer: PropTypes.object
};

const query = gql`
  query getPlayers($gameid: Int!) {
    page: region_aggregate(limit: 10000) {
      aggregate {
        totalCount: count
      }
    }
    players: player(where: {gameid: {_eq: $gameid}}, order_by: {title: asc}) {
      id
      title
    }
  }
`;

export default function PlayersSelect({
  className,
  onChangePlayer,
  selectedPlayer
}) {
  const {game} = useGameSelectContext();
  const {data, error, loading} = useQuery(query, {
    variables: {gameid: game.id}
  });

  return (
    <div className={className}>
      <AsyncContentInline error={error} loading={loading}>
        {() => (
          <PlayersSelectContent
            onChangePlayer={onChangePlayer}
            players={data.players}
            selectedPlayer={selectedPlayer}
          />
        )}
      </AsyncContentInline>
    </div>
  );
}

PlayersSelect.propTypes = propTypes;
