import PropTypes from 'prop-types';
import React, {useRef, useEffect} from 'react';
import cs from './VisibilitySensor.module.scss';

const propTypes = {
  isActive: PropTypes.bool,
  onVisibile: PropTypes.func
};

export default function VisibilitySensor({isActive = true, onVisibile}) {
  const rootRef = useRef();

  useEffect(() => {
    if (!isActive) return () => {};

    const node = rootRef.current;

    function onObserve(entries) {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          onVisibile();
        }
      });
    }

    // IntersectionObserver is a browser API, during automatic-testing it is not available therefor we have to imitated it e.g. https://stackoverflow.com/questions/44249985/js-testing-code-that-uses-an-intersectionobserver
    let observer = null;
    if (window.IntersectionObserver) {
      observer = new IntersectionObserver(onObserve, {
        threshold: [0, 1]
      });
    } else {
      const IntersectionObserver = class IntersectionObserver {
        constructor() {}

        disconnect() {
          return null;
        }

        observe() {
          return null;
        }

        takeRecords() {
          return null;
        }

        unobserve() {
          return null;
        }
      };
      observer = new IntersectionObserver();
    }

    observer.observe(node);

    return () => {
      observer.unobserve(node);
    };
  }, [isActive, onVisibile]);

  return (
    <span
      ref={rootRef}
      className={cs.root}
      style={{
        position: 'relative',
        top: -100
      }}
    />
  );
}

VisibilitySensor.propTypes = propTypes;
