import {useQuery} from '@apollo/react-hooks';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import pattern from 'patternomaly';
import React from 'react';
import {Link} from 'react-router-dom';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import cs from 'containers/Entityroles/EntityrolesContent.module.scss';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import ProdaccComparedGraphContent from 'containers/Graphs/ProdaccComparedGraphContent';
import GET_PRODACCGRAPH from './ProdaccGraphSchemas';

const propTypes = {};

// for each Region
function convertProdaccregionsToChartjsDatasets(regions, etype) {
  const datasets = [];

  regions.forEach((region) => {
    const data = [];
    region.prodaccregions.forEach((prodaccregion) => {
      switch (etype) {
        case 'absorb':
          data.push(prodaccregion.sumabsorb * -1);
          break;
        case 'increase':
          data.push(prodaccregion.sumincrease);
          break;
        default:
          data.push(prodaccregion.sumvalue);
      }
    });

    let bgColor = region.color;
    if (etype === 'absorb') {
      bgColor = pattern.draw('zigzag-horizontal', region.color);
    }

    let regionName = region.name;
    if (etype === 'absorb') {
      regionName = region.name + ' - Absorption';
    }

    const dataset = {
      label: regionName,
      fill: false,
      data,
      borderColor: region.color,
      backgroundColor: bgColor,
      stack: 'Stack ' + etype
    };
    datasets.push(dataset);
  });

  return datasets;
}

// eslint-disable-next-line func-style
const convertGameperiodsToChartjsLabels = (gameperiods) =>
  gameperiods.map((c) => [c.name]);

function useProdaccGraph(game) {
  const {data, error, loading} = useQuery(GET_PRODACCGRAPH, {
    variables: {boardtype: game.boardtype, gameid: game.id}
  });

  // ... calculation of graph data
  let datasets = [];
  if (!loading && data.regions !== undefined) {
    datasets = convertProdaccregionsToChartjsDatasets(data.regions, 'increase');
    datasets = datasets.concat(
      convertProdaccregionsToChartjsDatasets(data.regions, 'absorb')
    );
  }

  let labels = [];
  if (!loading && data.gameperiods !== undefined) {
    labels = convertGameperiodsToChartjsLabels(data.gameperiods);
  }
  const graphData = {labels, datasets};

  // set a loading state until the data is fetched AND calculated
  const isLoading = loading || !graphData;

  return {
    loading: isLoading,
    error,
    data: graphData
  };
}

export default function ProdaccComparedGraph() {
  const {game} = useGameSelectContext();
  const {data, error, loading} = useProdaccGraph(game);

  return (
    <>
      <Typography gutterBottom variant='h5'>
        Treibhausgas (Emission und Absorption) pro Region [Giga Tonnen]
      </Typography>

      <AsyncContent error={error} loading={loading}>
        <ProdaccComparedGraphContent graphData={data} />
      </AsyncContent>
      <Button
        className={cs.newEntity}
        color='primary'
        component={Link}
        to='/prodaccpostings'
        variant='contained'
      >
        details
      </Button>
    </>
  );
}

ProdaccComparedGraphContent.propTypes = propTypes;
