import CheckIcon from '@mui/icons-material/Check';
import CoffeeIcon from '@mui/icons-material/Coffee';
import PauseIcon from '@mui/icons-material/Pause';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {Link, useRouteMatch} from 'react-router-dom';
import cs from 'components/EntityTable/EntityTable.module.scss';
import SimpleTable from 'components/SimpleTable/SimpleTable';
import DateUtils from 'utils/DateUtils';
import m from '../../messages/de.json';
import GameperiodTableMenu from './GameperiodTableMenu';

const propTypes = {
  gameperiods: PropTypes.array,
  selectedId: PropTypes.number
};

export default function GameperiodTable({gameperiods, selectedId}) {
  const {url} = useRouteMatch();

  function ItemDecoration(id, value, object, field) {
    let nextValue = value;

    if (field === 'erda') {
      nextValue = DateUtils.formatDateTime(value);
    }

    if (field === 'taskstatus') {
      const msgShort = m.taskStatusList.gameperiodShort[value];
      nextValue =
        value === 'creation-in-progress' || value === 'closing-in-progress' ? (
          <>
            <CircularProgress size={15} /> {msgShort}
          </>
        ) : value === 'open' ? (
          <>
            <CoffeeIcon /> {msgShort}
          </>
        ) : (
          <CheckIcon />
        );

      /*
      nextValue = (
        <Chip
          color={
            inProgress === true || value === 'open' ? 'primary' : 'secondary'
          }
          icon={
            inProgress === true ? <CheckIcon /> : <CircularProgress size={10} />
          }
          label={msgShort}
          size='small'
        />
      );
      */
    }

    return (
      <Link className={cs.a} to={`${url}/${id}`}>
        {nextValue ? nextValue : null}
      </Link>
    );
  }

  return (
    <div className={cs.root}>
      <SimpleTable
        columns={[
          {
            title: 'Jahr',
            field: 'name',
            align: 'left'
          },
          {
            title: 'Erstellt am',
            field: 'erda',
            align: 'left'
          },
          {
            title: 'Datenverarbeitung',
            field: 'taskstatus',
            align: 'right'
          }
        ]}
        data={gameperiods}
        functionMenu={({row}) => <GameperiodTableMenu gameperiod={row} />}
        itemDecoration={ItemDecoration}
        keyfield='id'
        selectedId={selectedId}
      />
    </div>
  );
}

GameperiodTable.propTypes = propTypes;
