import {useQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import EnvUtils from 'utils/EnvUtils';

export default function useRegionModel(boardtype) {
  const GET_REGIONS = gql`
    query getRegions($boardtype: String!) {
      regions: region(
        order_by: {name: asc}
        where: {
          _or: [{boardtype: {_eq: $boardtype}}, {boardtype: {_eq: "both"}}]
        }
      ) {
        id
        name
      }
    }
  `;
  const {
    data: resultRegions,
    error,
    loading
  } = useQuery(GET_REGIONS, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: boardtype
  });

  return {
    resultRegions: {...resultRegions},
    loading,
    error
  };
}
