import PropTypes from 'prop-types';
import React from 'react';
import TableNumberOfResults from 'components/TableNumberOfResult/TableNumberOfResult';
import cs from './ProtocolsContent.module.scss';
import ProtocolsTableLandscaperegion from './ProtocolsTableLandscaperegion';

const propTypes = {
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onFetchNextPage: PropTypes.func,
  resultProtocols: PropTypes.object
};

// show only updates because inserts are taking place during createion of game
export default function ProtocolsContentLandscaperegion({
  loading,
  loadingMore,
  onFetchNextPage,
  resultProtocols
}) {
  return (
    <div className={cs.root}>
      <ProtocolsTableLandscaperegion
        loading={loading}
        loadingMore={loadingMore}
        onFetchNextPage={onFetchNextPage}
        protocols={resultProtocols?.protocols.filter(
          (protocol) => protocol.operation === 'UPDATE'
        )}
      />
      <TableNumberOfResults
        count={
          resultProtocols?.protocols.filter(
            (protocol) => protocol.operation === 'UPDATE'
          ).length
        }
      />
    </div>
  );
}

ProtocolsContentLandscaperegion.propTypes = propTypes;
