import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import DropDown from 'components/DropDown/DropDown';
import useEconomicaccModel from 'containers/Economictranslog/useEconomicaccModel';
import cs from 'containers/Entitylink/EntityDropDown.module.scss';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';

export default function EconomicaccDropDown({
  autoComplete,
  id,
  name,
  onChange,
  value
}) {
  const {game} = useGameSelectContext();
  const {error, loading, resultEconomicaccs} = useEconomicaccModel({
    gameid: game.id
  });

  function MyShowFieldDecorator(row) {
    return `${row.player.title}`;
  }

  return (
    <div className={cs.root}>
      <AsyncContent error={error} loading={loading}>
        <div>
          <DropDown
            autoComplete={autoComplete}
            data={resultEconomicaccs.economicaccs}
            emptyrow='-'
            id={id}
            keyfield='id'
            name={name}
            onChange={onChange}
            showfielddecorator={MyShowFieldDecorator}
            value={value}
          />
        </div>
      </AsyncContent>
    </div>
  );
}
