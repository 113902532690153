/**
 * Simple Experiment with SVG:
 * import SVGs directly as React component: https://create-react-app.dev/docs/adding-images-fonts-and-files/#adding-svgs (this is the module https://react-svgr.com/ which is part of the create-react-app)
 * to use a svg modivied with inkscape:
 * - Save images as: when you save the SVG in Inkscape as an "Optimized SVG", you do not get this error.
 * - Svg output remove metadata, remove comments
 *
 * Minimize svg: https://www.svgminify.com/ then save it as optimised image with inkscape
 *
 * Image Source: https://de.scoutwiki.org/index.php?title=Datei:Earth_globe.svg
 */
import React from 'react';

export default function Home() {
  return (
    <div>
      <h2>Willkommen in der UI-Admin App vom Weltklimaspiel</h2>
      <h3>Ziel ist es den Planeten Orasis zu retten.</h3>
    </div>
  );
}
