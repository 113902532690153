export default {
  title: 'Weltmarkt'
};

export const GlobalmarketsTable = {
  header: {
    entity: 'Produkt',
    player: 'Besitzer/Produzent/Konsument',
    capacity: 'Kapazität',
    capacityquotient: 'quotient**',
    stock: 'Lager',
    stockquotient: 'quotient*',
    numberrequired: 'Anzahl benötigt'
  },
  footer: {
    title: 'Legende:',
    quotientStock:
      '*) Quotient Lager: Verhältniskennzahlen von "Lager" und "Anzahl benötigt" (=Lager/ Anzahl benötigt). Ist der Wert z.B. grösser als eins kann der aktuelle Lagerstand den Bedarf mehr als eine Spielperiode abdecken.',
    quotientCapacity:
      '**) Quotient Produktion: Verhältniskennzahlen von "Kapazität" und "Anzahl benötigt" (=Kapazität / Anzahl benötigt). Ist der Wert z.B. grösser als eins kann die aktuelle Produktions-Kapazität den Bedarf mehr als eine Spielperiode abdecken.',
    attentionmarkerLow:
      'Achtung zu viel! Produkt bei dem der Lagerstand und die Produktions-Kapazität für zwei oder mehr Spielperioden ausreiched (beide Quotienten >= 2).',
    attentionmarkerHigh:
      'Achtung zu wenig! Produkt bei dem der Lagerstand und Produktions-Kapazität nicht für die nächste Spielperiode ausreicht (beide Quotienten < 1).'
  }
};
