import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useRouteMatch, useHistory} from 'react-router-dom';
import GameperiodCreationDialogDialog from 'components/GameperiodCreationDialog/GameperiodCreationDialog';
import GameperiodTable from 'components/GameperiodTable/GameperiodTable';
import SidebarDrawer from 'components/SidebarDrawer/SidebarDrawer';
import cs from 'containers/Entity/Entity.module.scss';
import Gameperiod from 'containers/Gameperiod/Gameperiod';

const propTypes = {
  onGameperiodCreation: PropTypes.func,
  refetch: PropTypes.func,
  resultGameperiods: PropTypes.object
};

export default function GameperiodsContent({
  onGameperiodCreation,
  refetch,
  resultGameperiods
}) {
  const history = useHistory();
  // const {path, url} = useRouteMatch();
  const {params, path} = useRouteMatch([
    '/gameperiods/new',
    '/gameperiods/:id?'
  ]);

  function onRequestCloseMainContent() {
    refetch();
    history.push('/gameperiods');
  }

  const [isGameperiodCreationOpen, setIsGameperiodCreationOpen] =
    useState(false);

  function onClickGameperiodCreation() {
    setIsGameperiodCreationOpen(true);
  }

  function onConfirmGameperiodCreation(game) {
    return onGameperiodCreation(game);
  }

  const openSidebarDrawer = params.id != null || path === '/gameperiods/new';

  return (
    <div className={cs.root}>
      <Typography gutterBottom variant='h5'>
        Spielperioden
      </Typography>
      <Button
        color='primary'
        onClick={onClickGameperiodCreation}
        startIcon={<AddIcon />}
        variant='contained'
      >
        Nächste Spielperiode erstellen
      </Button>
      <GameperiodTable
        gameperiods={resultGameperiods.gameperiods}
        selectedId={parseInt(params.id)}
      />
      <SidebarDrawer open={openSidebarDrawer}>
        <Gameperiod id={params.id} onClose={onRequestCloseMainContent} />
      </SidebarDrawer>
      <GameperiodCreationDialogDialog
        game={resultGameperiods.game}
        isOpen={isGameperiodCreationOpen}
        lastgameperiod={resultGameperiods?.gameperiods[0]}
        onClose={() => setIsGameperiodCreationOpen(false)}
        onConfirm={onConfirmGameperiodCreation}
      />
    </div>
  );
}

GameperiodsContent.propTypes = propTypes;
