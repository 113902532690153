import PropTypes from 'prop-types';
import React from 'react';
import ResearchCardForm from 'components/ResearchCardForm/ResearchCardForm';

const propTypes = {
  onCancel: PropTypes.func,
  resultResearchCard: PropTypes.object
};

export default function ResearchCardContent({onCancel, resultResearchCard}) {
  return (
    <ResearchCardForm
      onCancel={onCancel}
      researchcardplayer={resultResearchCard?.researchcardplayer}
    />
  );
}

ResearchCardContent.propTypes = propTypes;
