import PropTypes from 'prop-types';
import React from 'react';
import EntityroleForm from 'components/EntityroleForm/EntityroleForm';

const propTypes = {
  resultEntityrole: PropTypes.object,
  onSubmit: PropTypes.func,
  onSubmitFinish: PropTypes.func,
  onCancel: PropTypes.func
};

export default function EntityroleContent({
  onCancel,
  onSubmit,
  onSubmitFinish,
  resultEntityrole
}) {
  return (
    <EntityroleForm
      entityrole={resultEntityrole?.entityrole}
      onCancel={onCancel}
      onSubmit={onSubmit}
      onSubmitFinish={onSubmitFinish}
    />
  );
}

EntityroleContent.propTypes = propTypes;
