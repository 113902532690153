import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import cs from './SimpleTable.module.scss';

const propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  functionMenu: PropTypes.func,
  itemDecoration: PropTypes.func,
  keyfield: PropTypes.string,
  selectedId: PropTypes.number,
  tableHeader: PropTypes.node
};

// this function can handle date from the perl-api and graphql (then the object parameter is required)
export default function SimpleTable({
  columns,
  data,
  functionMenu,
  itemDecoration,
  keyfield,
  selectedId,
  tableHeader
}) {
  const isMobile = useMediaQuery({maxWidth: 767});
  const numberOfColumnsMobile = 3;

  if (isMobile && columns.length > numberOfColumnsMobile) {
    columns = columns.slice(0, numberOfColumnsMobile);
  }

  // filter logic based on Leigh Halliday: https://www.youtube.com/watch?v=oWVW8IqpQ-A
  const [filterCategory, setFilterCategory] = useState(null);
  const [filterAttribute, setFilterAttribute] = useState(null);

  function setFilter(attribute, category) {
    setFilterCategory(category);
    setFilterAttribute(attribute);
  }

  const filteredData = filterCategory
    ? data.filter((d) => d[filterAttribute] === filterCategory)
    : data;

  return (
    <TableContainer component={Paper}>
      {tableHeader && <div className={cs.tableHeader}>{tableHeader}</div>}
      <Table aria-label='Table' size='small'>
        <TableHead>
          <TableRow>
            {columns.map((c) => (
              <TableCell
                key={c.object ? c.object + c.field : c.field}
                align={c.align ? c.align : 'left'}
              >
                <b>{c.title}</b>
                {c.filtercategories !== undefined
                  ? c.filtercategories.map((category) => (
                      <button
                        key={category}
                        onClick={() => setFilter(c.field, category)}
                      >
                        {category}
                      </button>
                    ))
                  : null}
                {c.filtercategories !== undefined && filterCategory && (
                  <button onClick={() => setFilter(null, null)}>-reset-</button>
                )}
              </TableCell>
            ))}
            {functionMenu && <TableCell key='f' />}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((row, index) => (
            <SimpleTableRow
              key={row[keyfield]}
              columns={columns}
              functionMenu={functionMenu}
              itemDecoration={itemDecoration}
              keyfield={keyfield}
              row={row}
              selected={row[keyfield] === selectedId}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
SimpleTable.propTypes = propTypes;

const propTypes1 = {
  columns: PropTypes.array,
  functionMenu: PropTypes.func,
  itemDecoration: PropTypes.func,
  keyfield: PropTypes.string,
  onClick: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool
};

// fetch a property from a nested object (object within an object) e.g. property1.property2
// https://stackoverflow.com/questions/4255472/javascript-object-access-variable-property-by-name-as-string
function fetchFromObject(obj, prop) {
  // property not found
  if (typeof obj === 'undefined') return false;

  // index of next property split
  const index = prop.indexOf('.');

  // property split found; recursive call
  if (index > -1) {
    // get object at property (before split), pass on remainder
    return fetchFromObject(
      obj[prop.substring(0, index)],
      prop.substr(index + 1)
    );
  }

  // no split; get property
  if (obj != null) {
    return obj[prop];
  }
}

function SimpleTableRow({
  columns,
  functionMenu,
  itemDecoration,
  keyfield,
  onClick,
  row,
  selected
}) {
  return (
    <TableRow
      key={row[keyfield]}
      className={selected ? cs.trSelected : cs.trUnselected}
      onClick={onClick}
    >
      {columns.map((c, indexsub) => (
        <TableCell
          key={
            c.object
              ? `f${c.object}${c.field}${indexsub}`
              : row[keyfield] + indexsub
          }
          align={c.align ? c.align : 'left'}
        >
          {c.object
            ? itemDecoration(
                row[keyfield],
                c.field,
                fetchFromObject(row, c.object),
                c.field,
                row
              )
            : itemDecoration(row[keyfield], row[c.field], undefined, c.field)}
        </TableCell>
      ))}
      {functionMenu !== undefined ? (
        <TableCell key={`${row[keyfield]}f`}>{functionMenu({row})}</TableCell>
      ) : null}
    </TableRow>
  );
}
SimpleTableRow.propTypes = propTypes1;
