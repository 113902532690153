import {useQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import UserlistContent from 'containers/Userlist/UserlistContent';

const query = gql`
  query getUserlist($id: Int!) {
    userlist: userlist_by_pk(id: $id) {
      id
      longname
      email
      userrole
      organisationid
    }
  }
`;

const createUserlistMutation = gql`
  mutation insertUserlist(
    $longname: String
    $email: String
    $organisationid: Int
    $userrole: String
    $pwd: String
  ) {
    insert_userlist(
      objects: {
        longname: $longname
        email: $email
        organisationid: $organisationid
        userrole: $userrole
        pwd: $pwd
      }
    ) {
      returning {
        id
      }
    }
  }
`;

const updateUserlistMutation = gql`
  mutation updateUserlist(
    $id: Int!
    $longname: String
    $email: String
    $organisationid: Int
    $userrole: String
  ) {
    update_userlist_by_pk(
      pk_columns: {id: $id}
      _set: {
        longname: $longname
        email: $email
        organisationid: $organisationid
        userrole: $userrole
      }
    ) {
      id
      longname
      email
      userrole
      organisationid
    }
  }
`;

const propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func
};

export default function Userlist({id, onClose}) {
  const nextId = !parseInt(id) ? 0 : id;

  const {
    data: resultUserlist,
    error,
    loading
  } = useQuery(query, {
    variables: {id: nextId}
  });
  const [onUpdateUserlistMutation] = useMutation(updateUserlistMutation);
  const [onCreateUserlistMutation] = useMutation(createUserlistMutation);

  async function onSubmit(dUserlist) {
    if (dUserlist.id > 0) {
      return onUpdateUserlistMutation({
        variables: dUserlist
      });
    } else {
      // @TODO: currently it is hardcoded for a new user: change to action
      dUserlist.pwd =
        '$2a$06$.Y6kMczcFGn3MVbRuoH3feoyN5JUWGRcRdIcca8vRTd4aCkPi/I7a';

      return onCreateUserlistMutation({
        variables: dUserlist
      });
    }
  }

  return (
    <AsyncContent error={error} loading={loading}>
      <UserlistContent
        onCancel={onClose}
        onSubmit={onSubmit}
        onSubmitFinish={onClose}
        resultUserlist={resultUserlist}
      />
    </AsyncContent>
  );
}
Userlist.propTypes = propTypes;
