import {Line as LineJS} from 'chart.js/auto'; // this import is important to solve this issue: https://stackoverflow.com/questions/67727603/error-category-is-not-a-registered-scale
import PropTypes from 'prop-types';
import React from 'react';
import {Bar} from 'react-chartjs-2';
import cs from 'containers/Entity/Entity.module.scss';

const propTypes = {
  graphData: PropTypes.object
};

export default function ProdaccPlayerGraphContent({graphData}) {
  return (
    <div className={cs.root}>
      <Bar
        data={graphData}
        options={{
          fill: false,
          responsive: true,
          scales: {
            x: {
              stacked: true
            },
            y: {
              stacked: true
            }
          },
          plugins: {
            title: {
              display: false
            }
          }
        }}
      />
    </div>
  );
}

ProdaccPlayerGraphContent.propTypes = propTypes;
