/* eslint-disable react/no-array-index-key */
import {Typography} from '@mui/material';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import m from '../../messages/de.json';
import cs from './AsyncContentError.module.scss';

const propTypes = {
  error: PropTypes.object
};

export default function AsyncContentError({error}) {
  let displayError;

  if (error.graphQLErrors?.length > 0) {
    displayError = error.graphQLErrors.map((e) => e.message);
  } else {
    displayError = JSON.stringify(error);
  }

  const {enqueueSnackbar} = useSnackbar(); // error handling
  const msg = `${m.dataHandlingLoading.error} (Error: ${displayError})`;

  enqueueSnackbar(msg, {
    variant: 'error'
  });

  return (
    <div className={cs.root}>
      <Typography component='div' variant='body1' weigth='medium'>
        {msg}
      </Typography>
    </div>
  );
}

AsyncContentError.propTypes = propTypes;
