import PropTypes from 'prop-types';
import React from 'react';
import OrganisationForm from 'components/OrganisationForm/OrganisationForm';

const propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitFinish: PropTypes.func,
  resultOrganisation: PropTypes.object
};

export default function OrganisationContent({
  onCancel,
  onSubmit,
  onSubmitFinish,
  resultOrganisation
}) {
  return (
    <OrganisationForm
      onCancel={onCancel}
      onSubmit={onSubmit}
      onSubmitFinish={onSubmitFinish}
      organisation={resultOrganisation?.organisation}
    />
  );
}

OrganisationContent.propTypes = propTypes;
