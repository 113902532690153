import {MenuItem, Select, Typography} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';
import React from 'react';
import TopBar from 'components/TopBar/TopBar';
import GameperiodsSelect from 'containers/GameperiodsSelect/GameperiodsSelect';
import cs from './ProdaccpostingsTopBar.module.scss';
import etypeTypes from './etypeTypes';
import {ProdaccpostingsTopBar as labels} from './labels';

const propTypes = {
  filter: PropTypes.object.isRequired,
  onChangeFilter: PropTypes.func.isRequired
};

function ProdaccpostingsTopBar({filter, onChangeFilter}) {
  function onChangeGameperiod(gameperiod) {
    onChangeFilter({gameperiod});
  }

  function onChangeOuttype(e) {
    const etype = e.target.value === '' ? undefined : e.target.value;

    onChangeFilter({etype});
  }

  return (
    <TopBar className={cs.root}>
      <Toolbar className={cs.toolbar} variant='dense'>
        <Typography className={cs.filter}>{labels.gameperiod}</Typography>
        <GameperiodsSelect
          onChangeGameperiod={onChangeGameperiod}
          selectedGameperiod={filter.gameperiod}
        />
        <Typography className={cs.filter}>{labels.etype}</Typography>
        <Select
          displayEmpty
          onChange={onChangeOuttype}
          value={filter.etype || ''}
        >
          <MenuItem value=''>alle</MenuItem>
          <MenuItem value={etypeTypes.ABSORB}>{labels.absorb}</MenuItem>
          <MenuItem value={etypeTypes.INCREASE}>{labels.increase}</MenuItem>
        </Select>
      </Toolbar>
    </TopBar>
  );
}

ProdaccpostingsTopBar.propTypes = propTypes;
export default ProdaccpostingsTopBar;
