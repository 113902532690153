import {useQuery, useMutation} from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import React from 'react';
import TaxlawheadquarterContent from 'containers/Taxlawheadquarter/TaxlawheadquarterContent';
import {
  GET_TAXLAWHEADQUARTER,
  UPDATE_TAXLAWHEADQUARTER
} from 'containers/Taxlawheadquarter/TaxlawheadquarterSchemas';
import AsyncContent from '../../components/AsyncContent/AsyncContent';

const propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func
};

export default function Taxlawheadquarter({id, onClose}) {
  id === undefined ? (id = 0) : (id = id);

  const {
    data: resultTaxlawheadquarter,
    error,
    loading
  } = useQuery(GET_TAXLAWHEADQUARTER, {
    variables: {id}
  });

  const [updateTaxlawheadquarter] = useMutation(UPDATE_TAXLAWHEADQUARTER);

  async function onSubmit(dTaxlawheadquarter) {
    let res;

    if (resultTaxlawheadquarter.taxlawheadquarter !== null) {
      const dTaxlawheadquarterNext = {...dTaxlawheadquarter};
      delete dTaxlawheadquarterNext.player;
      res = updateTaxlawheadquarter({variables: dTaxlawheadquarterNext});
    }

    return res;
  }

  return (
    <AsyncContent error={error} loading={loading}>
      <TaxlawheadquarterContent
        id={id}
        onClose={onClose}
        onSubmit={onSubmit}
        resultTaxlawheadquarter={resultTaxlawheadquarter}
      />
    </AsyncContent>
  );
}
Taxlawheadquarter.propTypes = propTypes;
