import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import QRCode from 'react-qr-code';
import EnvUtils from 'utils/EnvUtils';

const propTypes = {
  players: PropTypes.array
};

// problem page break: to fix it play with the marginBottom of the paper
export default function PlayerPrint({players}) {
  return (
    <div
      style={{
        padding: 20,
        position: 'relative',
        display: 'contents'
      }}
    >
      <Grid
        container
        direction='column'
        justifyContent='center'
        style={{margin: '20px'}}
      >
        {players.map((row) => (
          <Paper
            key={row.id}
            elevation={3}
            style={{
              breakBefore: 'always',
              breakAfter: 'always',
              breakInside: 'avoid-page',
              marginBottom: '10px'
            }}
          >
            <Grid container spacing={3} style={{padding: 0}}>
              <Grid item xs={9}>
                <VpnKeyIcon />
                <b>Geheimer Zugangscode für das Weltklimaspiel</b>
                <List>
                  <ListItem>Rolle: {row.title}</ListItem>
                  <ListItem>Passwort: {row.pwd}</ListItem>
                  <ListItem style={{fontSize: 12}}>
                    {EnvUtils.getUiplayUrl()}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={3}>
                <QRCode size={110} value={EnvUtils.getUiplayUrl()} />
              </Grid>
            </Grid>
          </Paper>
        ))}
      </Grid>
    </div>
  );
}

PlayerPrint.propTypes = propTypes;
