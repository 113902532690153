import {useQuery} from '@apollo/react-hooks';
import Typography from '@mui/material/Typography';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import ProdaccAllGraphContent from 'containers/Graphs/ProdaccAllGraphContent';
import GET_PRODACCALLGRAPH from './ProdaccAllGraphSchemas';

const propTypes = {};

function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

// overall Sum "World"
function convertProdaccallToChartjsDatasets(all) {
  const datasets = [];

  const data = [];
  all.forEach((prodaccallposting) => {
    data.push(prodaccallposting.gametotal);
  });
  const dataset = {
    label: 'Treibhausgas Menge auf Orasis',
    fill: false,
    borderDash: [10, 5],
    pointRadius: 9,
    data,
    borderColor: 'rgb(0,0,0)'
  };
  datasets.push(dataset);

  return datasets;
}

function convertClimatepathToChartjsDatasets(
  datasets,
  gameperiods,
  label,
  valuestart,
  backgroundColor
) {
  const data = [];

  for (let i = 0; i <= gameperiods.length; i += 1) {
    data.push(valuestart);
  }

  const color = `rgb(${getRndInteger(0, 255)},${getRndInteger(
    0,
    255
  )},${getRndInteger(0, 255)},0.1)`;

  const dataset = {
    label,
    borderDash: [1, 1],
    fill: '+1', // fill to the next dataset
    data,
    backgroundColor, // The line fill color.
    borderColor: color
  };
  datasets.push(dataset);

  return datasets;
}

const convertGameperiodsToChartjsLabels = (gameperiods) =>
  gameperiods.map((c) => [c.name]);

function useProdaccGraph(game) {
  const {data, error, loading} = useQuery(GET_PRODACCALLGRAPH, {
    variables: {gameid: game.id}
  });

  // ... calculation of graph data
  let datasets = [];
  if (!loading && data.prodaccallpostings !== undefined) {
    datasets = convertProdaccallToChartjsDatasets(data.prodaccallpostings);
    datasets = convertClimatepathToChartjsDatasets(
      datasets,
      data.gameperiods,
      'Klimapfad 1.5°c',
      2250,
      'rgb(64,224,208,0.5)'
    );
    datasets = convertClimatepathToChartjsDatasets(
      datasets,
      data.gameperiods,
      'Klimapfad 2°c',
      2550,
      'rgb(255,143,0,0.5)'
    );
    datasets = convertClimatepathToChartjsDatasets(
      datasets,
      data.gameperiods,
      'Klimapfad 3°c',
      3300,
      'rgb(255,0,42,0.5)'
    );
    datasets = convertClimatepathToChartjsDatasets(
      datasets,
      data.gameperiods,
      'Klimapfad 4°c',
      4850,
      'rgb(255,0,250,0.1)'
    );
    datasets = convertClimatepathToChartjsDatasets(
      datasets,
      data.gameperiods,
      'Klimapfad +4°c',
      5000,
      'rgb(72,0,255,0.1)'
    );
  }

  let labels = [];
  if (!loading && data.gameperiods !== undefined) {
    labels = convertGameperiodsToChartjsLabels(data.gameperiods);
  }

  const graphData = {labels, datasets}; // ..... calculated data

  // set a loading state until the data is fetched AND calculated
  const isLoading = loading || !graphData;

  return {
    loading: isLoading,
    error,
    data: graphData
  };
}

export default function ProdaccGraph() {
  const {game} = useGameSelectContext();

  const {data, error, loading} = useProdaccGraph(game);

  return (
    <>
      <Typography gutterBottom variant='h5'>
        Entwicklung der kummulierten Treibhausgas Menge in der Atmosphäre [Giga
        Tonnen]
      </Typography>
      <AsyncContent error={error} loading={loading}>
        <ProdaccAllGraphContent graphData={data} />
      </AsyncContent>
    </>
  );
}

ProdaccGraph.propTypes = propTypes;
