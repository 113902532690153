import gql from 'graphql-tag';

const GET_ENTITYLINKS = gql`
  query getEntitylinks($id: Int!) {
    page: entitylink_aggregate(
      where: {deleted: {_eq: false}, _and: {entityid: {_eq: $id}}}
      limit: 10000
    ) {
      aggregate {
        totalCount: count
      }
    }
    entitylinks: entitylink(
      order_by: {type: asc}
      where: {deleted: {_eq: false}, _and: {entityid: {_eq: $id}}}
    ) {
      id
      entity {
        name
      }
      entityByLinktoentityid {
        name
      }
      type
    }
  }
`;

export {GET_ENTITYLINKS};
