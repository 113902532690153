import gql from 'graphql-tag';

const GET_ENTITY = gql`
  query getEntity($id: Int!) {
    entity: entity_by_pk(id: $id) {
      id
      name
      entitytype
      ghgemissionperperiodsustainable
      ghgemissionperperiodunsustainable
      buildingcosts
      updatetosustainablecosts
      destructioncosts
      salesprice
      accommodationcapacity
      population
      statustype
      icon
      iconsustainable
      cardfile
      code
      entitycategory
      settings
      earningperperiodsustainable
      earningperperiodunsustainable
    }
  }
`;

const UPDATE_ENTITY = gql`
  mutation updateEntity(
    $id: Int!
    $name: String
    $entitytype: String
    $ghgemissionperperiodsustainable: numeric
    $ghgemissionperperiodunsustainable: numeric
    $buildingcosts: numeric
    $updatetosustainablecosts: numeric
    $destructioncosts: numeric
    $salesprice: numeric
    $population: Int
    $accommodationcapacity: Int
    $statustype: String
    $icon: String
    $cardfile: String
    $entitycategory: String
    $earningperperiodsustainable: numeric
    $earningperperiodunsustainable: numeric
  ) {
    update_entity_by_pk(
      pk_columns: {id: $id}
      _set: {
        name: $name
        entitytype: $entitytype
        ghgemissionperperiodsustainable: $ghgemissionperperiodsustainable
        ghgemissionperperiodunsustainable: $ghgemissionperperiodunsustainable
        buildingcosts: $buildingcosts
        updatetosustainablecosts: $updatetosustainablecosts
        destructioncosts: $destructioncosts
        salesprice: $salesprice
        population: $population
        accommodationcapacity: $accommodationcapacity
        statustype: $statustype
        icon: $icon
        cardfile: $cardfile
        entitycategory: $entitycategory
        earningperperiodsustainable: $earningperperiodsustainable
        earningperperiodunsustainable: $earningperperiodunsustainable
      }
    ) {
      id
    }
  }
`;

const INSERT_ENTITY = gql`
  mutation insertEntity(
    $name: String
    $entitytype: String
    $ghgemissionperperiodsustainable: numeric
    $ghgemissionperperiodunsustainable: numeric
    $buildingcosts: numeric
    $updatetosustainablecosts: numeric
    $salesprice: numeric
    $population: Int
    $accommodationcapacity: Int
    $icon: String
    $cardfile: String
    $entitycategory: String
    $earningperperiodsustainable: numeric
    $earningperperiodunsustainable: numeric
  ) {
    insert_entity(
      objects: {
        name: $name
        entitytype: $entitytype
        ghgemissionperperiodsustainable: $ghgemissionperperiodsustainable
        ghgemissionperperiodunsustainable: $ghgemissionperperiodunsustainable
        buildingcosts: $buildingcosts
        updatetosustainablecosts: $updatetosustainablecosts
        salesprice: $salesprice
        population: $population
        accommodationcapacity: $accommodationcapacity
        icon: $icon
        cardfile: $cardfile
        entitycategory: $entitycategory
        earningperperiodsustainable: $earningperperiodsustainable
        earningperperiodunsustainable: $earningperperiodunsustainable
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export {GET_ENTITY, UPDATE_ENTITY, INSERT_ENTITY};
