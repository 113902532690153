import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import TableFixedInfiniteLoading from 'components/TableFixedInfiniteLoading/TableFixedInfiniteLoading';
import cs from './GlobalmarketsTable.module.scss';
import GlobalmarketsTableRow from './GlobalmarketsTableRow';
import {GlobalmarketsTable as labels} from './labels';

const propTypes = {
  className: PropTypes.string,
  globalmarkets: PropTypes.array,
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onFetchNextPage: PropTypes.func
};

function GlobalmarketsTable({
  className,
  globalmarkets,
  loading,
  loadingMore,
  onFetchNextPage
}) {
  const [isVisibleQuoteStock, setVisibleQuoteStock] = useState(false);
  function handleToggleStock() {
    setVisibleQuoteStock(!isVisibleQuoteStock);
  }

  const [isVisibleQuoteCapacity, setVisibleQuoteCapacity] = useState(false);
  function handleToggleCapacity() {
    setVisibleQuoteCapacity(!isVisibleQuoteCapacity);
  }

  function renderTableHead() {
    return (
      <TableHead component='div'>
        <TableRow component='div'>
          <TableCell align='left' component='div' />
          <TableCell align='left' component='div'>
            {labels.header.entity}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.player}
          </TableCell>
          <TableCell align='right' component='div'>
            {labels.header.stock}
          </TableCell>
          <TableCell
            align='right'
            component='div'
            onClick={() => handleToggleStock()}
          >
            {isVisibleQuoteStock ? (
              <small>{labels.header.stockquotient}</small>
            ) : (
              <small>...</small>
            )}
          </TableCell>
          <TableCell align='right' component='div'>
            {labels.header.capacity}
          </TableCell>
          <TableCell
            align='right'
            component='div'
            onClick={() => handleToggleCapacity()}
          >
            {isVisibleQuoteCapacity ? (
              <small>{labels.header.capacityquotient}</small>
            ) : (
              <small>...</small>
            )}
          </TableCell>
          <TableCell align='right' component='div'>
            {labels.header.numberrequired}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Paper className={cx(cs.root, className)}>
      <TableFixedInfiniteLoading
        loading={loading}
        loadingMore={loadingMore}
        onFetchNextPage={onFetchNextPage}
      >
        {() => (
          <>
            {renderTableHead()}
            <TableBody className={cs.table} component='div' size='small'>
              {globalmarkets.map((entity) => (
                <GlobalmarketsTableRow
                  key={'entities_' + entity.id}
                  entity={entity}
                  isTableQuoteCapacityVisible={isVisibleQuoteCapacity}
                  isTableQuoteStockVisible={isVisibleQuoteStock}
                />
              ))}
            </TableBody>
          </>
        )}
      </TableFixedInfiniteLoading>
    </Paper>
  );
}

GlobalmarketsTable.propTypes = propTypes;
export default GlobalmarketsTable;
