import gql from 'graphql-tag';

const GET_PLAYERS = gql`
  query getPlayers($gameid: Int!) {
    players: player(
      order_by: {title: asc}
      where: {
        gameid: {_eq: $gameid}
        _and: {role: {_not: {code: {_eq: "CONSUMERROLE"}}}}
      }
    ) {
      id
      title
      pwd
    }
  }
`;

export default GET_PLAYERS;
