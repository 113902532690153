import PropTypes from 'prop-types';
import React from 'react';
import {Route, Switch} from 'react-router-dom';
import TaxlawheadquarterForm from 'components/TaxlawheadquarterForm/TaxlawheadquarterForm';

const propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  resultTaxlawheadquarter: PropTypes.object
};

export default function TaxlawheadquarterContent({
  onClose,
  onSubmit,
  resultTaxlawheadquarter
}) {
  return (
    <>
      <Switch>
        <Route>
          <TaxlawheadquarterForm
            onClose={onClose}
            onSubmit={onSubmit}
            taxlawheadquarter={
              resultTaxlawheadquarter
                ? resultTaxlawheadquarter.taxlawheadquarter
                : undefined
            }
          />
        </Route>
      </Switch>
    </>
  );
}

TaxlawheadquarterContent.propTypes = propTypes;
