import {useQuery, useMutation} from '@apollo/react-hooks';
import Typography from '@mui/material/Typography';
import gql from 'graphql-tag';
import React, {useState} from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import {GET_GAMES} from 'containers/Games/GamesSchemas';
import Authentication from 'utils/Authentication';
import m from '../../messages/de.json';
import GamesContent from './GamesContent';
import GamesTopBar from './GamesTopBar';

export default function Games() {
  const [filter, setFilter] = useState({
    organisationid: undefined
  });

  const $orgPermissionsFilter =
    Authentication.getOrganisationId() > 0
      ? {
          deleted: {_eq: false},
          organisationid: {_eq: Authentication.getOrganisationId()}
        }
      : {
          deleted: {_eq: false},
          organisationid: {_eq: filter.organisationid}
        };

  const {
    data: resultGames,
    error,
    loading,
    refetch
  } = useQuery(GET_GAMES, {
    pollInterval: 60000,
    variables: {
      where: $orgPermissionsFilter
    }
  });

  const UPDATE_GAME = gql`
    mutation updateGame($id: Int!, $deleted: Boolean) {
      update_game_by_pk(pk_columns: {id: $id}, _set: {deleted: $deleted}) {
        id
      }
    }
  `;

  // challenge: during mutation react re-reanders, why?? solution ignore results
  // https://medium.com/@seniv/improve-performance-of-your-react-apollo-application-440692e37026
  const [updateGame, {errorDel, loadingDel}] = useMutation(UPDATE_GAME, {
    ignoreResults: true
  });

  async function onDeleteGame(game) {
    const dGame = {id: game.id, deleted: true};
    updateGame({variables: dGame})
      .then(() => {
        refetch();
      })
      .catch((res) => {
        const errors = res.graphQLErrors.map((error) => error.message);
        console.log(errors);
      });
  }

  function onChangeFilter(nextFilter) {
    setFilter((prev) => ({...prev, ...nextFilter}));
  }

  const queryGamesExport = gql`
    mutation actionGamesExport($organisationid: Int) {
      actionGamesExport(organisationid: $organisationid) {
        code
        data
      }
    }
  `;

  const [onGamesExportQuery] = useMutation(queryGamesExport);

  async function onGamesExport(organisationid) {
    return onGamesExportQuery({
      variables: {organisationid}
    });
  }

  return (
    <>
      <Typography gutterBottom variant='h5'>
        {m.GamesContent.title}
      </Typography>
      <GamesTopBar filter={filter} onChangeFilter={onChangeFilter} />
      <AsyncContent error={error} loading={loading}>
        <GamesContent
          onDeleteGame={onDeleteGame}
          onGamesExport={onGamesExport}
          refetch={refetch}
          resultGames={resultGames}
        />
      </AsyncContent>
    </>
  );
}
