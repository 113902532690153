import {Line as LineJS} from 'chart.js/auto'; // this import is important to solve this issue: https://stackoverflow.com/questions/67727603/error-category-is-not-a-registered-scale
import PropTypes from 'prop-types';
import React from 'react';
import {Line} from 'react-chartjs-2';
import cs from 'containers/Entity/Entity.module.scss';

const propTypes = {
  graphData: PropTypes.object
};

export default function ProdaccGraphContent({graphData}) {
  return (
    <div className={cs.root}>
      <Line
        data={graphData}
        options={{
          fill: false,
          plugins: {
            title: {
              display: false
            }
          }
        }}
      />
    </div>
  );
}

ProdaccGraphContent.propTypes = propTypes;
