import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import TaxlawheadquarterTable from 'components/TaxlawheadquarterTable/TaxlawheadquarterTable';
import Taxlawheadquarter from 'containers/Taxlawheadquarter/Taxlawheadquarter';

const propTypes = {
  id: PropTypes.number,
  refetch: PropTypes.object,
  resultTaxlawheadquarters: PropTypes.object
};

export default function TaxlawheadquartersContent({
  id,
  refetch,
  resultTaxlawheadquarters
}) {
  const history = useHistory();

  function onRequestCloseMainContent() {
    refetch();
    history.push(`/gameperiods/${id}/taxlawheadquarters`);
  }

  const [isTaxlawheadquarterDialogOpen, setIsTaxlawheadquarterDialogOpen] =
    useState(false);
  const [taxlawId, setTaxlawheadquarterId] = useState(0);

  function onClickShowTaxlawheadquarter(entitylinkid) {
    setTaxlawheadquarterId(entitylinkid);
    setIsTaxlawheadquarterDialogOpen(true);
  }

  return (
    <div>
      <Dialog
        onClose={onRequestCloseMainContent}
        open={isTaxlawheadquarterDialogOpen}
      >
        <DialogContent>
          <Taxlawheadquarter
            id={taxlawId}
            onClose={onRequestCloseMainContent}
          />
        </DialogContent>
      </Dialog>
      <TaxlawheadquarterTable
        onClickShowTaxlawheadquarter={onClickShowTaxlawheadquarter}
        taxlawheadquarters={resultTaxlawheadquarters.taxlawheadquarters}
      />
    </div>
  );
}

TaxlawheadquartersContent.propTypes = propTypes;
