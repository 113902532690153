import {useQuery} from '@apollo/react-hooks';
import Typography from '@mui/material/Typography';
import React, {useState} from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import EntityDropDown from 'containers/Entitylink/EntityDropDown';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import SupplyRatioGraphContent from 'containers/Graphs/SupplyRatioGraphContent';
import GET_SUPPLYRATIO from './SupplyRatioGraphSchemas';

const propTypes = {};

/* 
query for the supply of the consumers 
the view economictranslog_supplyratio is grouped by playerid
but customers wants to have the data on playerid and transformerentityid (e.g. Konsumgeist (Wasser), ...)
therefor this view was created and the aggreation takes place in the browser
with hasura it was not possible to aggregate on that level
*/
function supplyRatioFilterPlayerid(supplyratiosGameperiod, playerid) {
  return supplyratiosGameperiod.filter((item) => item.playerid === playerid);
}

function supplyRatioSum(supplyratiosGameperiod) {
  return supplyratiosGameperiod.reduce(
    (subresult, subitem) => subresult + subitem.supplyratio,
    0
  );
}

function supplyRatioAvg(supplyratiosGameperiod) {
  const sum = supplyRatioSum(supplyratiosGameperiod);
  if (sum <= 0) {
    return 0;
  } else {
    return sum / supplyratiosGameperiod.length;
  }
}

// for each Region
function convertSupplyRatioregionsToChartjsDatasets(
  players,
  gameperiods,
  supplyratios
) {
  const datasets = [];

  players.forEach((player) => {
    const data = [];
    supplyratios.forEach((gameperiod) => {
      data.push(
        supplyRatioAvg(
          supplyRatioFilterPlayerid(
            gameperiod.economictranslog_supplyratio_basic,
            player.id
          )
        )
      );
    });
    const dataset = {
      label: player.title,
      fill: false,
      data,
      borderColor: player.region.color,
      backgroundColor: player.region.color
    };
    datasets.push(dataset);
  });

  return datasets;
}

// eslint-disable-next-line func-style
const convertGameperiodsToChartjsLabels = (gameperiods) =>
  gameperiods.map((c) => [c.name]);

function useSupplyRatioGraph(game, entitytype) {
  const {data, error, loading} = useQuery(
    GET_SUPPLYRATIO,
    entitytype > 0
      ? {
          variables: {
            gameid: game.id,
            where: {transformerentityid: {_eq: entitytype}}
          }
        }
      : {
          variables: {
            gameid: game.id
          }
        }
  );

  // ... calculation of graph data
  let datasets = [];
  if (!loading && data.players !== undefined) {
    datasets = convertSupplyRatioregionsToChartjsDatasets(
      data.players,
      data.gameperiods,
      data.supplyratios
    );
    // datasets = convertSupplyRatioallToChartjsDatasets(datasets);
  }

  let labels = [];
  if (!loading && data.gameperiods !== undefined) {
    labels = convertGameperiodsToChartjsLabels(data.gameperiods);
  }

  const graphData = {labels, datasets}; // ..... calculated data

  // set a loading state until the data is fetched AND calculated
  const isLoading = loading || !graphData;

  return {
    loading: isLoading,
    error,
    data: graphData
  };
}

export default function SupplyRatioGraph() {
  const {game} = useGameSelectContext();
  const [entitytype, setEntitytype] = useState(undefined);
  const {data, error, loading} = useSupplyRatioGraph(game, entitytype);

  function handleChange(e) {
    setEntitytype(e.target.value);
  }

  return (
    <>
      <Typography gutterBottom variant='h5'>
        Konsumbevölkerung Versorgung pro Jahrzehnt und Land [%]
      </Typography>
      <EntityDropDown
        filterEntitytype={['ghost']}
        onChange={handleChange}
        value={entitytype}
      />
      <AsyncContent error={error} loading={loading}>
        <SupplyRatioGraphContent graphData={data} />
      </AsyncContent>
    </>
  );
}

SupplyRatioGraph.propTypes = propTypes;
