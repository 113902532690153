import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import DeletionDialog from 'components/DeletionDialog/DeletionDialog';
import Authentication from 'utils/Authentication';
import labels from './labels';

const propTypes = {
  entity: PropTypes.object.isRequired,
  onDeleteEntity: PropTypes.func.isRequired
};

export default function EntityTableMenu({entity, onDeleteEntity}) {
  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState(false);

  function onClickDelete() {
    setIsDeletionDialogOpen(true);
  }

  function onConfirmDelete() {
    return onDeleteEntity(entity);
  }

  return (
    <>
      {Authentication.hasUserRole('uiadmin_admin') && (
        <>
          <IconButton color='primary' onClick={onClickDelete} size='small'>
            <DeleteIcon />
          </IconButton>

          <DeletionDialog
            isOpen={isDeletionDialogOpen}
            onClose={() => setIsDeletionDialogOpen(false)}
            onConfirm={onConfirmDelete}
            title={labels.confirmDelete(entity)}
          />
        </>
      )}
    </>
  );
}

EntityTableMenu.propTypes = propTypes;
