import React from 'react';
import DropDown from 'components/DropDown/DropDown';
import cs from 'containers/Entitylink/EntityDropDown.module.scss';

export default function UserroleDropDown({
  autoComplete,
  id,
  name,
  onChange,
  value
}) {
  const userroles = [
    {id: 'admin', name: 'admin'},
    {id: 'default', name: 'default'}
  ];

  return (
    <div className={cs.root}>
      <DropDown
        autoComplete={autoComplete}
        data={userroles}
        emptyrow='-'
        id={id}
        keyfield='id'
        name={name}
        onChange={onChange}
        showfield='name'
        value={value}
      />
    </div>
  );
}
