import gql from 'graphql-tag';

export default gql`
  fragment Landscaperegion_landscape_region_mutable on landscape_region {
    id
    landscapeid
    gameperiodid
    status
    regionid
  }
`;
