import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import React from 'react';
import SimpleTable from 'components/SimpleTable/SimpleTable';
import cs from './EntitylinkTable.module.scss';
import EntitylinkTableMenu from './EntitylinkTableMenu';

const propTypes = {
  entitylinks: PropTypes.array,
  onClickShowEntityLink: PropTypes.func.isRequired,
  onDeleteEntitylink: PropTypes.func.isRequired
};
export default function EntitylinkTable({
  entitylinks,
  onClickShowEntityLink,
  onDeleteEntitylink
}) {
  function ItemDecoration(id, value, object) {
    return (
      <Link
        color='inherit'
        component='button'
        onClick={() => onClickShowEntityLink(id)}
        style={{textDecoration: 'none'}}
      >
        {object ? object[value] : value}
      </Link>
    );
  }

  if (entitylinks === undefined) {
    return null;
  }

  return (
    <div className={cs.root}>
      <SimpleTable
        columns={[
          {title: 'Richtung', field: 'type'},
          {
            title: 'Entität',
            object: 'entityByLinktoentityid',
            field: 'name'
          },
          {
            title: 'nicht nachhaltig',
            field: 'numberunsustainable',
            align: 'right'
          },
          {
            title: 'nachhaltig',
            field: 'numbersustainable',
            align: 'right'
          }
        ]}
        data={entitylinks}
        functionMenu={({row}) => (
          <EntitylinkTableMenu
            entitylink={row}
            onDeleteEntitylink={onDeleteEntitylink}
          />
        )}
        itemDecoration={ItemDecoration}
        keyfield='id'
      />
    </div>
  );
}

EntitylinkTable.propTypes = propTypes;
