import PropTypes from 'prop-types';
import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import ATabs from 'components/ATabs/ATabs';
import EntityForm from 'components/EntityForm/EntityForm';
import EntitylinkResearches from 'containers/EntitylinkResearches/EntitylinkResearches';
import Entitylinks from 'containers/Entitylinks/Entitylinks';

const propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  resultEntity: PropTypes.object
};

export default function EntityContent({onClose, onSubmit, resultEntity}) {
  const {params, path} = useRouteMatch(['/entities/new', '/entities/:id?']);

  const tabs = [
    {
      title: 'Stammdaten',
      to: `/entities/${
        resultEntity.entity !== null ? resultEntity.entity.id : null
      }`
    }
  ];

  const isBuilding =
    resultEntity?.entity?.entitytype === 'energy' ||
    resultEntity?.entity?.entitytype === 'company' ||
    resultEntity?.entity?.entitytype === 'miningarea' ||
    resultEntity?.entity?.entitytype === 'landuse' ||
    resultEntity?.entity?.entitytype === 'traffic' ||
    resultEntity?.entity?.entitytype === 'ghost' ||
    resultEntity?.entity?.entitytype === 'city';

  if (resultEntity.entity !== null && isBuilding) {
    tabs.push({
      title: 'Transformation',
      to: `/entities/${resultEntity.entity.id}/entitylinks`
    });
  }

  if (
    resultEntity.entity !== null &&
    resultEntity?.entity?.entitytype === 'research'
  ) {
    tabs.push({
      title: 'Research-Einstellungen',
      to: `/entities/${resultEntity.entity.id}/entitylinkresearches`
    });
  }

  return (
    <>
      <ATabs onClose={onClose} tabs={tabs} />
      <Switch>
        <Route path={`${path}/${params.id}/entitylinks`}>
          <Entitylinks
            entity={resultEntity.entity}
            id={
              resultEntity.entity !== null ? resultEntity.entity.id : undefined
            }
          />
        </Route>
        <Route path={`${path}/${params.id}/entitylinkresearches`}>
          <EntitylinkResearches
            entity={resultEntity.entity}
            id={
              resultEntity.entity !== null ? resultEntity.entity.id : undefined
            }
          />
        </Route>
        <Route>
          <EntityForm
            entity={resultEntity ? resultEntity.entity : undefined}
            onClose={onClose}
            onSubmit={onSubmit}
          />
        </Route>
      </Switch>
    </>
  );
}

EntityContent.propTypes = propTypes;
