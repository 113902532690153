import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BuildIcon from '@mui/icons-material/Build';
import CloudIcon from '@mui/icons-material/Cloud';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import EventIcon from '@mui/icons-material/Event';
import ExploreRoundedIcon from '@mui/icons-material/ExploreRounded';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import LandscapeIcon from '@mui/icons-material/Landscape';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import PollIcon from '@mui/icons-material/Poll';
import ScienceIcon from '@mui/icons-material/Science';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import Authentication from 'utils/Authentication';
import cs from './Nav.module.scss';
import NavListItem from './NavListItem';

const propTypes = {
  onLogout: PropTypes.func
};

export default function Nav({onLogout}) {
  const menu = {
    title: 'Home',
    exact: true,
    icon: <HomeTwoToneIcon />,
    to: '/'
  };

  const login = {
    title: 'Login',
    exact: true,
    icon: <VpnKeyIcon />,
    to: '/login'
  };

  const setupPermission = [
    {
      title: 'Organisationen',
      icon: <Diversity3Icon />,
      to: '/organisations'
    },
    {
      title: 'Benutzer',
      icon: <PersonIcon />,
      to: '/userlists'
    }
  ];

  const setupdata = [
    {
      title: 'Spiele',
      icon: <EventIcon />,
      to: '/games'
    },
    {
      title: 'Entities',
      icon: <BuildIcon />,
      to: '/entities'
    }
  ];

  const gameinstancedata = [
    {
      title: 'SpielerInnen',
      icon: <VpnKeyIcon />,
      to: '/players'
    },
    {
      title: 'Umfragen',
      icon: <PollIcon />,
      to: '/surveys'
    },
    {
      title: 'Spielperioden',
      icon: <AccessTimeIcon />,
      to: '/gameperiods'
    },
    {
      title: 'Hexagone',
      icon: <LandscapeIcon />,
      to: '/landscaperegions'
    },
    {
      title: 'Bauwerke',
      icon: <LocationCityIcon />,
      to: '/entityroles'
    },
    {
      title: 'Buchungen',
      icon: <SwapHorizIcon />,
      to: '/economictranslogs'
    },
    {
      title: 'Kontostand',
      icon: <AttachMoneyIcon />,
      to: '/economicaccs'
    },
    {
      title: 'Entwicklungskarten',
      icon: <ScienceIcon />,
      to: '/researchcards'
    },
    {
      title: 'Weltmarkt',
      icon: <ExploreRoundedIcon />,
      to: '/globalmarkets'
    },
    {
      title: 'Bevölkerung',
      icon: <PeopleIcon />,
      to: '/inhabitants'
    },
    {
      title: 'Treibhausgase',
      icon: <CloudIcon />,
      to: '/graphs'
    }
  ];

  return (
    <>
      <List>
        <NavListItem exact={menu.exact} to={menu.to}>
          <ListItemIcon className={cs.icon}>{menu.icon}</ListItemIcon>
          <ListItemText primary={menu.title} />
        </NavListItem>
        {!Authentication.isAuthenticated() && (
          <NavListItem exact={login.exact} to={login.to}>
            <ListItemIcon className={cs.icon}>{login.icon}</ListItemIcon>
            <ListItemText primary={login.title} />
          </NavListItem>
        )}

        <Divider sx={{my: 1}} />

        {Authentication.isAuthenticated() && (
          <>
            {Authentication.hasUserRole('uiadmin_admin') &&
              setupPermission.map((menuItem) => (
                <NavListItem
                  key={menuItem.to}
                  exact={menuItem.exact}
                  to={menuItem.to}
                >
                  <ListItemIcon className={cs.icon}>
                    {menuItem.icon}
                  </ListItemIcon>
                  <ListItemText primary={menuItem.title} />
                </NavListItem>
              ))}

            {setupdata.map((menuItem) => (
              <NavListItem
                key={menuItem.to}
                exact={menuItem.exact}
                to={menuItem.to}
              >
                <ListItemIcon className={cs.icon}>{menuItem.icon}</ListItemIcon>
                <ListItemText primary={menuItem.title} />
              </NavListItem>
            ))}

            <Divider sx={{my: 1}} />
            {gameinstancedata.map((menuItem) => (
              <NavListItem
                key={menuItem.to}
                exact={menuItem.exact}
                to={menuItem.to}
              >
                <ListItemIcon className={cs.icon}>{menuItem.icon}</ListItemIcon>
                <ListItemText primary={menuItem.title} />
              </NavListItem>
            ))}
          </>
        )}
      </List>
    </>
  );
}

Nav.propTypes = propTypes;
