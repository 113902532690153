import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import usePromiseState, {PromiseState} from 'hooks/usePromiseState';
import ObjectUtils from 'utils/ObjectUtils';
import m from '../../messages/de.json';
import cs from './GameperiodCreationDialog.module.scss';

const propTypes = {
  game: PropTypes.object,
  isOpen: PropTypes.bool,
  lastgameperiod: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default function GameperiodCreationDialogDialog({
  game,
  isOpen,
  lastgameperiod,
  onClose,
  onConfirm
}) {
  const [confirmPromise, setConfirmPromise] = usePromiseState();

  function onClickConfirm() {
    setConfirmPromise(onConfirm(game));
  }

  useEffect(() => {
    if (confirmPromise.state === PromiseState.FULFILLED) {
      onClose();
    }
  }, [onClose, confirmPromise]);

  function isEnabled() {
    if (
      game.execution === 'running' &&
      game.taskstatus === 'completed' &&
      lastgameperiod.taskstatus === 'creation-completed'
    ) {
      return true;
    } else {
      return false;
    }
  }

  function showVisualStatus(fine) {
    if (fine) {
      return <CheckBoxIcon />;
    } else {
      return <CheckBoxOutlineBlankIcon />;
    }
  }

  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle>{m.GameperiodCreationDialog.title}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {m.GameperiodCreationDialog.description}
        </DialogContentText>
        {confirmPromise.state === PromiseState.REJECTED && (
          <Typography className={cs.error} variant='body2'>
            {m.GameperiodCreationDialog.buttons.error}
          </Typography>
        )}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Parameter</TableCell>
              <TableCell>Soll</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {showVisualStatus(game?.execution === 'running')}
              </TableCell>
              <TableCell>Spiel Status</TableCell>
              <TableCell>
                {
                  ObjectUtils.getMessageObjById(
                    m.ExecutionDropDown.status,
                    'running'
                  ).name
                }
              </TableCell>
              <TableCell>
                <i>
                  {
                    ObjectUtils.getMessageObjById(
                      m.ExecutionDropDown.status,
                      game?.execution
                    ).name
                  }
                </i>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {showVisualStatus(game?.taskstatus === 'completed')}
              </TableCell>
              <TableCell>Spiel Erstellungs-Status</TableCell>
              <TableCell>{m.taskStatusList.game.completed}</TableCell>
              <TableCell>
                <i>{m.taskStatusList.game[game?.taskstatus]}</i>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {showVisualStatus(
                  lastgameperiod?.taskstatus === 'creation-completed'
                )}
              </TableCell>
              <TableCell>
                Letzte Spielperiode ({lastgameperiod?.name})
              </TableCell>
              <TableCell>
                {m.taskStatusList.gameperiod['creation-completed']}
              </TableCell>
              <TableCell>
                <i>{m.taskStatusList.gameperiod[lastgameperiod?.taskstatus]}</i>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {isEnabled()
          ? m.GameperiodCreationDialog.requirements.fullfilled
          : m.GameperiodCreationDialog.requirements.missing}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          {m.GameperiodCreationDialog.buttons.cancel}
        </Button>
        <Button
          key='confirm'
          className={cs.delete}
          color='primary'
          disabled={!isEnabled()}
          onClick={onClickConfirm}
        >
          {m.GameperiodCreationDialog.buttons.confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

GameperiodCreationDialogDialog.propTypes = propTypes;
