import {Typography} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';
import React from 'react';
import TopBar from 'components/TopBar/TopBar';
import cs from 'containers/Entityroles/EntityrolesTopBar.module.scss';
import PlayersSelect from 'containers/PlayersSelect/PlayersSelect';
import {ResearchCardsTopBar as labels} from './labels';

const propTypes = {
  filter: PropTypes.object.isRequired,
  onChangeFilter: PropTypes.func.isRequired
};

function ResearchCardsTopBar({filter, onChangeFilter}) {
  function onChangePlayer(player) {
    onChangeFilter({player});
  }

  return (
    <TopBar className={cs.root}>
      <Toolbar className={cs.toolbar} variant='dense'>
        <Typography className={cs.filter}>{labels.player}</Typography>
        <PlayersSelect
          onChangePlayer={onChangePlayer}
          selectedPlayer={filter.player}
        />
      </Toolbar>
    </TopBar>
  );
}

ResearchCardsTopBar.propTypes = propTypes;
export default ResearchCardsTopBar;
