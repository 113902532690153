import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import {useRouteMatch, Link, useHistory} from 'react-router-dom';
import EntityTable from 'components/EntityTable/EntityTable';
import SidebarDrawer from 'components/SidebarDrawer/SidebarDrawer';
import Entity from 'containers/Entity/Entity';
import cs from 'containers/Entityroles/EntityrolesContent.module.scss';
import m from '../../messages/de.json';

const propTypes = {
  onDeleteEntity: PropTypes.func.isRequired,
  refetch: PropTypes.func,
  resultEntities: PropTypes.object
};

export default function EntitiesContent({
  onDeleteEntity,
  refetch,
  resultEntities
}) {
  const history = useHistory();
  const {params, path} = useRouteMatch(['/entities/new', '/entities/:id?']);

  function onRequestCloseMainContent() {
    refetch();
    history.push('/entities');
  }

  const openSidebarDrawer = params.id != null || path === '/entities/new';

  return (
    <>
      <div className={cs.root}>
        <Button
          className={cs.newEntity}
          color='primary'
          component={Link}
          startIcon={<AddIcon />}
          to='/entities/new'
          variant='contained'
        >
          {m.EntitiesContent.new}
        </Button>

        <EntityTable
          entities={resultEntities.entities}
          onDeleteEntity={onDeleteEntity}
          selectedId={parseInt(params.id)}
        />
        <SidebarDrawer open={openSidebarDrawer}>
          <Entity id={params.id} onClose={onRequestCloseMainContent} />
        </SidebarDrawer>
      </div>
    </>
  );
}

EntitiesContent.propTypes = propTypes;
