import {Typography} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';
import React from 'react';
import TopBar from 'components/TopBar/TopBar';
import PlayersSelect from 'containers/PlayersSelect/PlayersSelect';
import RegionsSelect from 'containers/RegionsSelect/RegionsSelect';
import cs from './ProtocolsTopBar.module.scss';
import {ProtocolsTopBar as labels} from './labels';

const propTypes = {
  filter: PropTypes.object.isRequired,
  onChangeFilter: PropTypes.func.isRequired
};

function ProtocolsTopBar({filter, onChangeFilter}) {
  function onChangePlayer(player) {
    onChangeFilter({player});
  }

  function onChangeRegion(region) {
    onChangeFilter({region});
  }

  return (
    <TopBar className={cs.root}>
      <Toolbar className={cs.toolbar} variant='dense'>
        <Typography className={cs.filter}>{labels.region}</Typography>
        <RegionsSelect
          onChangeRegion={onChangeRegion}
          selectedRegion={filter.region}
        />
        <Typography className={cs.filter}>{labels.player}</Typography>
        <PlayersSelect
          onChangePlayer={onChangePlayer}
          selectedPlayer={filter.player}
        />
      </Toolbar>
    </TopBar>
  );
}

ProtocolsTopBar.propTypes = propTypes;
export default ProtocolsTopBar;
