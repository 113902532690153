import PropTypes from 'prop-types';
import React from 'react';
import DropDown from 'components/DropDown/DropDown';
import cs from 'containers/Entitylink/EntityDropDown.module.scss';

const propTypes = {
  autoComplete: PropTypes.bool,
  id: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.object,
  typeFilter: PropTypes.array,
  value: PropTypes.string
};

export default function TypeDropDown({
  autoComplete,
  id,
  name,
  onChange,
  typeFilter,
  value
}) {
  const types = typeFilter.map((type) => ({id: type, name: type}));
  return (
    <div className={cs.root}>
      <div>
        <DropDown
          autoComplete={autoComplete}
          data={types}
          emptyrow='-'
          id={id}
          keyfield='id'
          name={name}
          onChange={onChange}
          showfield='name'
          value={value}
        />
      </div>
    </div>
  );
}

TypeDropDown.propTypes = propTypes;
