import PropTypes from 'prop-types';
import React from 'react';
import {Route, Switch} from 'react-router-dom';
import EntitylinkForm from 'components/EntitylinkForm/EntitylinkForm';

const propTypes = {
  entityid: PropTypes.number,
  id: PropTypes.number,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  resultEntitylink: PropTypes.object,
  typeFilter: PropTypes.array
};

export default function EntitylinkContent({
  entityid,
  onClose,
  onSubmit,
  resultEntitylink,
  typeFilter
}) {
  const isNew = resultEntitylink ? !('entitylink' in resultEntitylink) : true;

  return (
    <>
      <Switch>
        <Route>
          <EntitylinkForm
            key={
              resultEntitylink.entitylink !== null
                ? resultEntitylink.entitylink.id
                : undefined
            }
            entityid={entityid}
            entitylink={resultEntitylink ? resultEntitylink.entitylink : null}
            isNew={isNew}
            onClose={onClose}
            onSubmit={onSubmit}
            typeFilter={typeFilter}
          />
        </Route>
      </Switch>
    </>
  );
}

EntitylinkContent.propTypes = propTypes;
