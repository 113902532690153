import {useQuery, useMutation} from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import React from 'react';
import GameContent from 'containers/Game/GameContent';
import {GET_GAME, UPDATE_GAME, INSERT_GAME} from 'containers/Game/GameSchemas';
import AsyncContent from '../../components/AsyncContent/AsyncContent';

const propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func
};

export default function Game({id, onClose}) {
  id === undefined ? (id = 0) : (id = id);

  const {
    data: resultGame,
    error,
    loading
  } = useQuery(GET_GAME, {
    variables: {id}
  });
  const [updateGame] = useMutation(UPDATE_GAME);
  const [insertGame] = useMutation(INSERT_GAME);

  async function onSubmit(dGame) {
    let res;

    if (dGame.id && dGame.id > 0) {
      res = updateGame({variables: dGame});
    } else {
      res = insertGame({variables: dGame});
    }

    return res;
  }

  return (
    <AsyncContent error={error} loading={loading}>
      <GameContent
        onClose={onClose}
        onSubmit={onSubmit}
        resultGame={resultGame}
      />
    </AsyncContent>
  );
}
Game.propTypes = propTypes;
