import gql from 'graphql-tag';

const GET_TAXLAWHEADQUARTERS = gql`
  query getTaxlawheadquarter($gameperiodid: Int!) {
    page: taxlaw_headquarter_aggregate(
      where: {gameperiodid: {_eq: $gameperiodid}}
      limit: 10000
    ) {
      aggregate {
        totalCount: count
      }
    }
    taxlawheadquarters: taxlaw_headquarter(
      order_by: {id: desc}
      where: {gameperiodid: {_eq: $gameperiodid}}
    ) {
      id
      player {
        title
      }
      playerByPlayeridtaxreceiver {
        title
      }
    }
  }
`;

export default GET_TAXLAWHEADQUARTERS;
