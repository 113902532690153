import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import DeletionDialog from 'components/DeletionDialog/DeletionDialog';
import Authentication from 'utils/Authentication';
import labels from './labels';

const propTypes = {
  entitylink: PropTypes.object.isRequired,
  onDeleteEntitylink: PropTypes.func.isRequired
};

export default function EntitylinkResearchTableMenu({
  entitylink,
  onDeleteEntitylink
}) {
  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState(false);

  function onClickDelete() {
    setIsDeletionDialogOpen(true);
  }

  function onConfirmDelete() {
    return onDeleteEntitylink(entitylink);
  }

  return (
    <>
      {Authentication.hasUserRole('uiadmin_admin') && (
        <>
          <IconButton color='primary' onClick={onClickDelete} size='small'>
            <DeleteIcon />
          </IconButton>

          <DeletionDialog
            isOpen={isDeletionDialogOpen}
            onClose={() => setIsDeletionDialogOpen(false)}
            onConfirm={onConfirmDelete}
            title={labels.confirmDelete(entitylink)}
          />
        </>
      )}
    </>
  );
}

EntitylinkResearchTableMenu.propTypes = propTypes;
