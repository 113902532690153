import DeleteIcon from '@mui/icons-material/Delete';
import PasswordIcon from '@mui/icons-material/Password';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import DeletionDialog from 'components/DeletionDialog/DeletionDialog';
import UserlistPwdSetDialog from 'components/UserlistPwdSetDialog/UserlistPwdSetDialog';
import m from '../../messages/de.json';

const propTypes = {
  onDeleteUserlist: PropTypes.func.isRequired,
  onPwdSetUserlist: PropTypes.func.isRequired,
  userlist: PropTypes.object.isRequired
};

export default function UserlistsTableMenu({
  onDeleteUserlist,
  onPwdSetUserlist,
  userlist
}) {
  const userid = localStorage.getItem('userid');
  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState(false);

  function onClickDelete() {
    setIsDeletionDialogOpen(true);
  }

  function onConfirmDelete() {
    return onDeleteUserlist(userlist);
  }

  const [isPwdSetDialogOpen, setIsPwdSetDialogOpen] = useState(false);

  function onClickPwdSet() {
    setIsPwdSetDialogOpen(true);
  }

  function onConfirmPwdSet(newPwd) {
    return onPwdSetUserlist(userlist, newPwd);
  }

  return (
    <>
      <IconButton
        color='primary'
        disabled={userlist.id === parseInt(userid)}
        onClick={onClickPwdSet}
        size='small'
      >
        <PasswordIcon />
      </IconButton>
      <IconButton color='primary' onClick={onClickDelete} size='small'>
        <DeleteIcon />
      </IconButton>

      <DeletionDialog
        isOpen={isDeletionDialogOpen}
        onClose={() => setIsDeletionDialogOpen(false)}
        onConfirm={onConfirmDelete}
        title={m.dataHandlingDelete.confirm}
      />
      <UserlistPwdSetDialog
        isOpen={isPwdSetDialogOpen}
        onClose={() => setIsPwdSetDialogOpen(false)}
        onConfirm={onConfirmPwdSet}
        title={m.dataHandlingPwdSet.confirm}
      />
    </>
  );
}

UserlistsTableMenu.propTypes = propTypes;
