import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import MuiAppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {styled, createTheme, ThemeProvider} from '@mui/material/styles';
import {useSnackbar} from 'notistack';
import * as React from 'react';
import {useHistory, Link} from 'react-router-dom';
import GameSelect from 'containers/GameSelect/GameSelect';
import EnvUtils from 'utils/EnvUtils';
import Nav from '../../components/Nav/Nav';

// this is based on the material ui Dashboard: https://mui.com/material-ui/getting-started/templates/dashboard/
// @TODO: if the drawer is close and a menu from the game-instance menue is called it opens again, this could be because in App.js "GameSelectedRoute" is used.

// AppBar Z-Index needs to be below the drawer, so that the sidebar drawer to edit is always abouve the top drawer (zIndex: theme.zIndex.drawer - 1,)

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({open, theme}) => ({
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({open, theme}) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9)
      }
    })
  }
}));

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Dashboard({children}) {
  const {enqueueSnackbar} = useSnackbar();

  const history = useHistory();

  async function onLogout() {
    localStorage.removeItem('jsonwebtoken');
    history.push('/login');

    enqueueSnackbar(`Du hast dich erfolgreich abgemeldet.`, {
      variant: 'success'
    });
  }

  const [open, setOpen] = React.useState(true);
  // eslint-disable-next-line func-style
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{display: 'flex'}}>
        <CssBaseline />
        <AppBar open={open} position='absolute'>
          <Toolbar
            sx={{
              pr: '24px' // keep right padding when drawer closed
            }}
          >
            <IconButton
              aria-label='open drawer'
              color='inherit'
              edge='start'
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && {display: 'none'})
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              color='inherit'
              component='h1'
              noWrap
              sx={{flexGrow: 1}}
              variant='h6'
            >
              Weltklimaspiel UI-Admin ({EnvUtils.getEnvironmentName()})
            </Typography>
            <GameSelect />
            &nbsp;&nbsp;
            <Tooltip title='Benutzer-Profil'>
              <Link to='/userprofile'>
                <Avatar sx={{width: 24, height: 24}}>
                  <PersonIcon />
                </Avatar>
              </Link>
            </Tooltip>
            <IconButton color='inherit'>
              <Tooltip title='Abmelden'>
                <ExitToAppIcon onClick={() => onLogout()} />
              </Tooltip>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer open={open} variant='permanent'>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1]
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component='nav'>
            <Nav onLogout={onLogout} />
          </List>
        </Drawer>
        <Box
          component='main'
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
          }}
        >
          <Toolbar />
          <Container maxWidth='false' sx={{mt: 4, mb: 4}}>
            {children}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
