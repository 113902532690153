import {createTheme} from '@mui/material/styles';
import ObjectUtils from 'utils/ObjectUtils';
import sassVars from './styles/_colors.scss';

const base = {
  palette: {
    primary: {
      main: sassVars.colorBrand
    }
  }
};

export default {
  light: createTheme(
    ObjectUtils.deepMerge(base, {
      palette: {
        type: 'light',
        primary: {
          main: '#1565c0'
        }
      }
    })
  ),
  dark: createTheme(
    ObjectUtils.deepMerge(base, {
      palette: {
        primary: {
          main: '#1565c0'
        },
        type: 'dark',
        action: {
          disabled: 'rgba(255, 255, 255, 0.6)'
        }
      }
    })
  )
};
