import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';

const propTypes = {
  children: PropTypes.node,
  initialGame: PropTypes.object
};

export const GameSelectContext = React.createContext();
export const useGameSelectContext = () => React.useContext(GameSelectContext);

const STORAGE_KEY = 'orasis-initial-game';
const storedGame = window.localStorage.getItem(STORAGE_KEY);
const initialStoredGame =
  storedGame !== null ? JSON.parse(storedGame) : undefined;

export default function GameSelectProvider({
  children,
  initialGame = initialStoredGame
}) {
  const [game, setGame] = useState(initialGame);

  function onChangeGame(nextGame) {
    setGame(nextGame);
  }

  useEffect(() => {
    if (!game) return;

    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(game));
  }, [game]);

  return (
    <GameSelectContext.Provider value={{game, onChangeGame}}>
      {children}
    </GameSelectContext.Provider>
  );
}

GameSelectProvider.propTypes = propTypes;
