import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import DropDown from 'components/DropDown/DropDown';
import cs from 'containers/Entitylink/EntityDropDown.module.scss';
import useOrganisationModel from 'containers/Userlist/useOrganisationModel';

export default function OrganisationDropDown({
  autoComplete,
  id,
  name,
  onChange,
  value
}) {
  const {error, loading, resultOrganisations} = useOrganisationModel();

  return (
    <div className={cs.root}>
      <AsyncContent error={error} loading={loading}>
        <DropDown
          autoComplete={autoComplete}
          data={resultOrganisations.organisations}
          emptyrow='-'
          id={id}
          keyfield='id'
          name={name}
          onChange={onChange}
          showfield='name'
          value={value}
        />
      </AsyncContent>
    </div>
  );
}
