import {useQuery, useMutation} from '@apollo/react-hooks';
import {filter} from 'graphql-anywhere';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import LandscaperegionContent from 'containers/Landscaperegion/LandscaperegionContent';
import landscaperegionFragment from './landscaperegionFragment';
import landscaperegionMutableFragment from './landscaperegionMutableFragment';

const propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func
};

const query = gql`
  query landscape_region_by_pk($id: Int!) {
    landscaperegion: landscape_region_by_pk(id: $id) {
      ...Landscaperegion_landscape_region
    }
  }
  ${landscaperegionFragment}
`;

const updateLandscaperegionMutation = gql`
  mutation updateLandscaperegion(
    $id: Int!
    $landscapeid: Int
    $gameperiodid: Int
    $status: String
    $regionid: Int
  ) {
    update_landscape_region_by_pk(
      pk_columns: {id: $id}
      _set: {
        landscapeid: $landscapeid
        gameperiodid: $gameperiodid
        status: $status
        regionid: $regionid
      }
    ) {
      ...Landscaperegion_landscape_region
    }
  }

  ${landscaperegionFragment}
`;

const createLandscaperegionMutation = gql`
  mutation createLandscaperegion(
    $landscapeid: Int
    $gameperiodid: Int
    $status: String
    $regionid: Int
  ) {
    insert_landscape_region(
      objects: {
        landscapeid: $landscapeid
        gameperiodid: $gameperiodid
        status: $status
        regionid: $regionid
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export default function Landscaperegion({id, onClose}) {
  if (!id) {
    id = 0;
  }
  const {
    data: resultLandscaperegion,
    error,
    loading
  } = useQuery(query, {
    variables: {id}
  });
  const [onUpdateLandscaperegion] = useMutation(updateLandscaperegionMutation);
  const [onCreateLandscaperegion] = useMutation(createLandscaperegionMutation);

  async function onSubmit(dLandscaperegion) {
    let res;

    if (resultLandscaperegion.landscaperegion !== null) {
      res = onUpdateLandscaperegion({
        variables: filter(landscaperegionMutableFragment, dLandscaperegion)
      });
    } else {
      // not implemented in the frontend
      res = onCreateLandscaperegion({variables: dLandscaperegion});
    }

    return res;
  }

  return (
    <AsyncContent error={error} loading={loading}>
      <LandscaperegionContent
        onCancel={onClose}
        onSubmit={onSubmit}
        onSubmitFinish={onClose}
        resultLandscaperegion={resultLandscaperegion}
      />
    </AsyncContent>
  );
}
Landscaperegion.propTypes = propTypes;
