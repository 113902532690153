export default {
  title: 'Hexagone'
};

export const LandscaperegionsContent = {
  protocol: 'Änderungen'
};

export const LandscaperegionsTable = {
  header: {
    gameperiod: 'Spielperiode',
    region: 'Region',
    landscape: 'Hexagon',
    landscapecategorie: 'Hex. Kat.',
    status: 'Status'
  }
};

export const LandscaperegionsTopBar = {
  landscapecategorie: 'Hex. Kategorie:',
  region: 'Region:',
  status: 'Status:'
};
