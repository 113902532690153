import {useMutation} from '@apollo/react-hooks';
import Typography from '@mui/material/Typography';
import gql from 'graphql-tag';
import React, {useState} from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import usePaginatedQuery from 'hooks/usePaginatedQuery';
import EnvUtils from 'utils/EnvUtils';
import m from '../../messages/de.json';
import EntitiesContent from './EntitiesContent';
import EntitiesTopBar from './EntittiesTopBar';

const query = gql`
  query entities(
    $where: entity_bool_exp! = {}
    $limit: Int = 25
    $offset: Int
  ) {
    page: entity_aggregate(where: $where, limit: 10000) {
      aggregate {
        totalCount: count
      }
    }
    entities: entity(where: $where, order_by: {name: asc}) {
      id
      name
      entitytype
      ghgemissionperperiodsustainable
      ghgemissionperperiodunsustainable
    }
  }
`;

export default function Entities() {
  const [filter, setFilter] = useState({
    entitytype: undefined
  });

  const {
    data: resultEntities,
    error,
    loading,
    refetch
  } = usePaginatedQuery(query, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: {
      where: {
        _and: [{entitytype: {_eq: filter.entitytype}}, {deleted: {_eq: false}}]
      }
    }
  });

  const UPDATE_ENTITY = gql`
    mutation updateEntity($id: Int!, $deleted: Boolean) {
      update_entity_by_pk(pk_columns: {id: $id}, _set: {deleted: $deleted}) {
        id
      }
    }
  `;
  const [updateEntity, {errorDel, loadingDel}] = useMutation(UPDATE_ENTITY, {
    ignoreResults: true
  });

  async function onDeleteEntity(entity) {
    const dEntity = {id: entity.id, deleted: true};
    updateEntity({variables: dEntity})
      .then(() => {
        refetch();
      })
      .catch((res) => {
        const errors = res.graphQLErrors.map((error) => error.message);
        console.log(errors);
      });
  }

  function onChangeFilter(nextFilter) {
    setFilter((prev) => ({...prev, ...nextFilter}));
  }

  return (
    <>
      <Typography gutterBottom variant='h5'>
        {m.EntitiesContent.title}
      </Typography>
      <EntitiesTopBar filter={filter} onChangeFilter={onChangeFilter} />

      <AsyncContent error={error} loading={loading}>
        <EntitiesContent
          onDeleteEntity={onDeleteEntity}
          refetch={refetch}
          resultEntities={resultEntities}
        />
      </AsyncContent>
    </>
  );
}
