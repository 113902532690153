import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContentInline from 'components/AsyncContent/AsyncContentInline';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import EntitiesSelectContent from './EntitiesSelectContent';

const propTypes = {
  className: PropTypes.string,
  entitytypeCategory: PropTypes.array,
  onChangeEntity: PropTypes.func,
  selectedEntity: PropTypes.object
};

const query = gql`
  query getEntities($entitytype: [String]) {
    page: entity_aggregate(limit: 10000) {
      aggregate {
        totalCount: count
      }
    }
    entities: entity(
      order_by: {name: asc}
      where: {deleted: {_neq: true}, _and: {entitytype: {_in: $entitytype}}}
    ) {
      id
      name
    }
  }
`;

export default function EntitiesSelect({
  className,
  entitytypeCategory,
  onChangeEntity,
  selectedEntity
}) {
  const {game} = useGameSelectContext();
  const {data, error, loading} = useQuery(query, {
    variables: {
      boardtype: game.boardtype,
      entitytype: entitytypeCategory
    }
  });

  return (
    <div className={className}>
      <AsyncContentInline error={error} loading={loading}>
        {() => (
          <EntitiesSelectContent
            entities={data.entities}
            onChangeEntity={onChangeEntity}
            selectedEntity={selectedEntity}
          />
        )}
      </AsyncContentInline>
    </div>
  );
}

EntitiesSelect.propTypes = propTypes;
