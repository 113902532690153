import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Authentication from 'utils/Authentication';
import EnvUtils from 'utils/EnvUtils';

export default function useOrganisationModelOrgPermission() {
  const query = gql`
    query Organisation($where: organisation_bool_exp! = {}) {
      organisations: organisation(order_by: {name: asc}, where: $where) {
        id
        name
      }
    }
  `;

  const $orgPermissionsFilter =
    Authentication.getOrganisationId() > 0
      ? {
          id: {_eq: Authentication.getOrganisationId()},
          deleted: {_eq: false}
        }
      : {deleted: {_eq: false}};

  const {
    data: resultOrganisations,
    error,
    loading
  } = useQuery(query, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: {
      where: $orgPermissionsFilter
    }
  });

  return {
    resultOrganisations: {...resultOrganisations},
    loading,
    error
  };
}
