import {DateTime} from 'luxon';

/**
 * documentation for postgresTsFormat
 * server date is GMT+0 (greenw.)
 * we have explicit tell the javascript that our Time-Value is in that timezone
 * therefor we need luxon. Because the javascript Date() object always uses the timezone of browser
 * then we finaly convert it to the timezone of the user
 */

export default {
  moneyFormat(value) {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 1,
      maximumFractionDigits: 2
    }).format(value);
  },

  numberFormat(value) {
    return new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2
    }).format(value);
  },

  postgresTsFormat(value) {
    if (value === null) {
      return undefined;
    }

    const overrideZone = DateTime.fromISO(value, {
      zone: 'GMT+0'
    });
    const options = {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    };
    return new Intl.DateTimeFormat('de-DE', options).format(overrideZone);
  },

  jsonToText(jsonstr) {
    return JSON.stringify(jsonstr)
      .replace(/[{}"]/g, '')
      .replace(/[:]/g, ': ')
      .replace(/null/g, '')
      .replace(/[,]/g, ', ');
  }
};
