import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import cs from './TextInput.module.scss';

const propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  autoComplete: PropTypes.bool,
  readonly: PropTypes.bool,
  type: PropTypes.string
};

export default function TextInput({
  autoComplete,
  className,
  name,
  onChange,
  readonly = false,
  type = 'text',
  value
}) {
  return (
    <input
      autoComplete={autoComplete ? true : 'none'}
      className={cx(className, cs.input, {[cs.input_readOnly]: readonly})}
      id={name}
      name={name}
      onChange={onChange}
      readOnly={readonly}
      type={type}
      value={value}
    />
  );
}

TextInput.propTypes = propTypes;
