import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContentInline from 'components/AsyncContent/AsyncContentInline';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import RegionsSelectContent from './RegionsSelectContent';

const propTypes = {
  className: PropTypes.string,
  onChangeRegion: PropTypes.func,
  selectedRegion: PropTypes.object
};

const query = gql`
  query getRegions($boardtype: String!) {
    page: region_aggregate(limit: 10000) {
      aggregate {
        totalCount: count
      }
    }
    regions: region(
      order_by: {name: asc}
      where: {_or: [{boardtype: {_eq: $boardtype}}, {boardtype: {_eq: "both"}}]}
    ) {
      id
      name
    }
  }
`;

export default function RegionsSelect({
  className,
  onChangeRegion,
  selectedRegion
}) {
  const {game} = useGameSelectContext();
  const {data, error, loading} = useQuery(query, {
    variables: {boardtype: game.boardtype}
  });

  return (
    <div className={className}>
      <AsyncContentInline error={error} loading={loading}>
        {() => (
          <RegionsSelectContent
            onChangeRegion={onChangeRegion}
            regions={data.regions}
            selectedRegion={selectedRegion}
          />
        )}
      </AsyncContentInline>
    </div>
  );
}

RegionsSelect.propTypes = propTypes;
