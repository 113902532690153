import PrintIcon from '@mui/icons-material/Print';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {useReactToPrint} from 'react-to-print';
import PlayerTable from 'components/PlayerTable/PlayerTable';
import cs from 'containers/Economicacc/Economicacc.module.scss';
import {PlayersContentPrint} from 'containers/Players/PlayersContentPrint';

const propTypes = {
  resultPlayers: PropTypes.object
};

export default function PlayersContent({resultPlayers}) {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <div className={cs.root}>
      <Typography gutterBottom variant='h5'>
        SpielerInnen
      </Typography>
      <Button
        color='primary'
        onClick={handlePrint}
        startIcon={<PrintIcon />}
        variant='contained'
      >
        Drucken
      </Button>
      <Paper style={{display: 'none'}}>
        <PlayersContentPrint
          ref={componentRef}
          players={resultPlayers.players}
        />
      </Paper>
      <PlayerTable players={resultPlayers.players} />
    </div>
  );
}

PlayersContent.propTypes = propTypes;
