export default {
  title: 'Entwicklungskarten Besitz'
};

export const ResearchCardsTable = {
  header: {
    purchasegameperiod: 'Spielperiode',
    purchasetime: 'Kauf am',
    player: 'SpielerIn',
    entity: 'Entwicklungskarte',
    effectregion: 'Region'
  }
};

export const ResearchCardsTopBar = {
  player: 'SpielerIn:'
};
