import ListItem from '@mui/material/ListItem';
import PropTypes from 'prop-types';
import React from 'react';
import {NavLink} from 'react-router-dom';
import cs from './NavListItem.module.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired
};

export default function NavListItem({children, to, ...rest}) {
  return (
    <ListItem
      activeClassName={cs.item_active}
      component={NavLink}
      sx={{color: 'black'}}
      to={to}
      {...rest}
    >
      {children}
    </ListItem>
  );
}

NavListItem.propTypes = propTypes;
