export default {
  destructionOfBuilding: '🏗 Abriß Bauwerk',
  initalLoadOfMoney: '🧧 Eröffungsüberweisung',
  labourCostsToConsumptionPopulation: '🧑‍🔧 Lohnkosten für die Konsumbevölkerung',
  labourTaxToGovernment: '🧑‍⚖️ Lohnsteuer für die Regierung',
  newBuilding: '🧱 Neubau',
  nirvanaToPlayerTransfer: '🎁 Überweisung vom Spielleiter',
  playerToPlayerMoneytransfer: '💰 Überweisung',
  salesTaxToGovernmentAtHeadquarter:
    '🧑‍⚖️ Umsatzsteuer für die Regierung am Firmenhauptsitz',
  statusChangeToSustainable: '💚 Verbesserung Umweltschutzklasse',
  statusChangeToUnsustainable: '💔 Rückbau Umweltschutzklasse',
  tradingOfGoods: '🍗 Handel von Waren',
  researchcardPurchase: '🎴 Entwicklungskarte',
  entityEarnings: '🤠 Profit'
};
