import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import EnvUtils from 'utils/EnvUtils';

export default function useOrganisationModel(boardtype) {
  const query = gql`
    query getOrganisations {
      organisations: organisation(
        order_by: {name: asc}
        where: {deleted: {_eq: false}}
      ) {
        id
        name
      }
    }
  `;
  const {
    data: resultOrganisations,
    error,
    loading
  } = useQuery(query, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: boardtype
  });

  return {
    resultOrganisations: {...resultOrganisations},
    loading,
    error
  };
}
