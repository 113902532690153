import {useQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {useSnackbar} from 'notistack';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import m from '../../messages/de.json';
import OrganisationsContent from './OrganisationsContent';

const query = gql`
  query getOrganisations {
    organisations: organisation(
      order_by: {name: asc}
      where: {deleted: {_eq: false}}
    ) {
      id
      name
    }
  }
`;

const deleteOrganisationMuatation = gql`
  mutation updateOrganisation($id: Int!, $deleted: Boolean) {
    update_organisation_by_pk(
      pk_columns: {id: $id}
      _set: {deleted: $deleted}
    ) {
      id
    }
  }
`;

export default function Organisations() {
  const {
    data: resultOrganisations,
    error,
    loading,
    refetch
  } = useQuery(query, {
    pollInterval: 60000
  });

  const [onDeleteOrganisationMutation] = useMutation(
    deleteOrganisationMuatation,
    {
      ignoreResults: true
    }
  );

  const {enqueueSnackbar} = useSnackbar();

  async function onDeleteOrganisation(organisation) {
    const dOrganisation = {id: organisation.id, deleted: true};
    onDeleteOrganisationMutation({variables: dOrganisation})
      .then(() => {
        // console.log('Result-Data: ', data);
        enqueueSnackbar(m.dataHandlingDelete.fulfilled, {
          variant: 'success'
        });
        refetch();
      })
      .catch((error) => {
        console.log('Result-Error', error);
        enqueueSnackbar(`${m.dataHandlingDelete.rejected} (Error: ${error})`, {
          variant: 'error'
        });
      });
  }

  return (
    <AsyncContent error={error} loading={loading}>
      <OrganisationsContent
        onDeleteOrganisation={onDeleteOrganisation}
        refetch={refetch}
        resultOrganisations={resultOrganisations}
      />
    </AsyncContent>
  );
}
