import Typography from '@mui/material/Typography';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import cs from './FormItem.module.scss';

const propTypes = {
  label: PropTypes.node,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  className: PropTypes.string
};

function FormItem({
  children,
  className,
  error,
  id,
  label,
  name,
  onChange,
  sx,
  value
}) {
  return (
    <div className={cx(cs.root, className)}>
      {error ? (
        <Typography className={cs.error} variant='caption'>
          {error}
        </Typography>
      ) : (
        <Typography className={cs.label} sx={sx} variant='caption'>
          {label}
        </Typography>
      )}
      <div className={cs.input}>
        {children &&
          React.cloneElement(children, {
            onChange,
            name,
            id,
            value: value != null ? value : ''
          })}
      </div>
    </div>
  );
}

FormItem.propTypes = propTypes;

export default FormItem;
