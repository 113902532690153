import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import DeletionDialog from 'components/DeletionDialog/DeletionDialog';
import m from '../../messages/de.json';

const propTypes = {
  onDeleteOrganisation: PropTypes.func.isRequired,
  userlist: PropTypes.object.isRequired
};

export default function OrganisationsTableMenu({
  onDeleteOrganisation,
  userlist
}) {
  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState(false);

  function onClickDelete() {
    setIsDeletionDialogOpen(true);
  }

  function onConfirmDelete() {
    return onDeleteOrganisation(userlist);
  }

  return (
    <>
      <IconButton color='primary' onClick={onClickDelete} size='small'>
        <DeleteIcon />
      </IconButton>

      <DeletionDialog
        isOpen={isDeletionDialogOpen}
        onClose={() => setIsDeletionDialogOpen(false)}
        onConfirm={onConfirmDelete}
        title={m.dataHandlingDelete.confirm}
      />
    </>
  );
}

OrganisationsTableMenu.propTypes = propTypes;
