import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import React from 'react';
import DateUtils from 'utils/DateUtils';

const propTypes = {
  data: PropTypes.object
};
export default function RecordInfo({data}) {
  return (
    <>
      <hr />
      <small>
        <TableContainer>
          <Table aria-label='Record Information' size='small'>
            <TableBody>
              {data?.erda ? (
                <TableRow>
                  <TableCell>Erstellungsdatum</TableCell>
                  <TableCell>{DateUtils.formatDateTime(data?.erda)}</TableCell>
                </TableRow>
              ) : null}
              {data?.eruser ? (
                <TableRow>
                  <TableCell>Erstellungsbenutzer</TableCell>
                  <TableCell>{data?.eruser}</TableCell>
                </TableRow>
              ) : null}
              {data?.aeda ? (
                <TableRow>
                  <TableCell>Änderungsdatum</TableCell>
                  <TableCell>{data?.aeda}</TableCell>
                </TableRow>
              ) : null}
              {data?.aeuser ? (
                <TableRow>
                  <TableCell>Änderungsbenutzer</TableCell>
                  <TableCell>{data?.aeuser}</TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </small>
    </>
  );
}

RecordInfo.propTypes = propTypes;
