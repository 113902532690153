import React from 'react';
import DropDown from 'components/DropDown/DropDown';
import cs from 'containers/Entitylink/EntityDropDown.module.scss';
import m from '../../messages/de.json';

export default function EntitytypeDropDown({
  autoComplete,
  id,
  name,
  onChange,
  value
}) {
  const resultEntitytype = {entitytypes: m.EntitytypeDropDown.entitytypes};

  return (
    <div className={cs.root}>
      <div>
        <DropDown
          autoComplete={autoComplete}
          data={resultEntitytype.entitytypes}
          emptyrow='-'
          id={id}
          keyfield='id'
          name={name}
          onChange={onChange}
          showfield='name'
          value={value}
        />
      </div>
    </div>
  );
}
