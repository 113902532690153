import React from 'react';
import DropDown from 'components/DropDown/DropDown';
import cs from 'containers/Entitylink/EntityDropDown.module.scss';

export default function StatustypeDropDown({
  autoComplete,
  id,
  name,
  onChange,
  value
}) {
  const resultStatustype = {
    statustypes: [
      {id: 'sustainable', name: 'Nachhaltig'},
      {id: 'advancedtech', name: 'fortgeschrittene Technologie'}
    ]
  };

  return (
    <div className={cs.root}>
      <div>
        <DropDown
          autoComplete={autoComplete}
          data={resultStatustype.statustypes}
          emptyrow='-'
          id={id}
          keyfield='id'
          name={name}
          onChange={onChange}
          showfield='name'
          value={value}
        />
      </div>
    </div>
  );
}
