import PropTypes from 'prop-types';
import React from 'react';
import TableNumberOfResults from 'components/TableNumberOfResult/TableNumberOfResult';
import cs from './ProtocolsContent.module.scss';
import ProtocolsTableEntityrole from './ProtocolsTableEntityrole';

const propTypes = {
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onFetchNextPage: PropTypes.func,
  resultProtocols: PropTypes.object
};

export default function ProtocolsContentEntityrole({
  loading,
  loadingMore,
  onFetchNextPage,
  resultProtocols
}) {
  return (
    <div className={cs.root}>
      <ProtocolsTableEntityrole
        loading={loading}
        loadingMore={loadingMore}
        onFetchNextPage={onFetchNextPage}
        protocols={resultProtocols?.protocols}
      />
      <TableNumberOfResults
        count={resultProtocols?.aggregate?.protocols?.totalCount}
      />
    </div>
  );
}

ProtocolsContentEntityrole.propTypes = propTypes;
