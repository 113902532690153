import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import {useFormik} from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Form from 'components/Form/Form';
import FormColumn from 'components/Form/FormColumn';
import FormItem from 'components/Form/FormItem';
import TextInput from 'components/TextInput/TextInput';
import EnvUtils from 'utils/EnvUtils';
import Formater from 'utils/Formater';
import cs from './ResearchCardForm.module.scss';
import labels from './labels';

const initialValues = {
  gameperiodid: ''
};

const propTypes = {
  className: PropTypes.string,
  onCancel: PropTypes.func,
  researchcardplayer: PropTypes.object
};

export default function ResearchCardForm({
  className,
  onCancel,
  researchcardplayer
}) {
  const formik = useFormik({
    initialValues: researchcardplayer || initialValues,
    enableReinitialize: true
  });

  return (
    <div className={cx(className, cs.root)}>
      <Typography gutterBottom variant='h6'>
        {labels.title}
      </Typography>
      <Form
        actions={
          <>
            <Button onClick={onCancel} variant='outlined'>
              {labels.cancel}
            </Button>
          </>
        }
      >
        <FormColumn>
          <FormItem
            error={formik.errors.name}
            label={labels.name}
            name='name'
            value={formik.values.entity.name}
          >
            <TextInput readonly />
          </FormItem>
          <FormItem
            error={formik.errors.salesprice}
            label={labels.salesprice}
            name='salesprice'
            value={Formater.numberFormat(formik.values.entity.salesprice)}
          >
            <TextInput readonly />
          </FormItem>
          <FormItem>
            <Paper variant='outlined'>
              <img
                alt='cardfile'
                src={
                  EnvUtils.getUiplayUrl() +
                  `/assets/researchCardVisualisation/${formik.values.entity.cardfile}`
                }
                style={{width: '100%'}}
              />
            </Paper>
          </FormItem>
        </FormColumn>
      </Form>
    </div>
  );
}
ResearchCardForm.propTypes = propTypes;
