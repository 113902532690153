import React from 'react';
import DropDown from 'components/DropDown/DropDown';
import cs from 'containers/Entitylink/EntityDropDown.module.scss';

export default function EntitycategoryDropDown({
  autoComplete,
  id,
  name,
  onChange,
  value
}) {
  const resultEntitycategory = {
    statustypes: [
      {id: 'workingcondition', name: 'Arbeits- und Produktionsbedingungen'},
      {id: 'qualityoflife', name: 'Bildung, Gesundheit, Kultur'},
      {id: 'emergencyaid', name: 'Krisenbekämpfung, Katastrophenhilfe'},
      {id: 'consumerbehaviour', name: 'Konsumverhalten'},
      {id: 'agricultur', name: 'Landwirtschaft'},
      {id: 'military', name: 'Militär, Geheimdienste'},
      {id: 'establishngo', name: 'NGO-Gründung'},
      {id: 'futurecity', name: 'Stadt der Zukunft'},
      {id: 'empowerment', name: 'Stärkung der Zivilgesellschaft'},
      {id: 'technology', name: 'Technologie und Forschung'},
      {id: 'statuschange', name: 'Umbau freischalten'},
      {id: 'conservation', name: 'Umweltschutz'}
    ]
  };

  return (
    <div className={cs.root}>
      <div>
        <DropDown
          autoComplete={autoComplete}
          data={resultEntitycategory.statustypes}
          emptyrow='-'
          id={id}
          keyfield='id'
          name={name}
          onChange={onChange}
          showfield='name'
          value={value}
        />
      </div>
    </div>
  );
}
