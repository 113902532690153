import PauseIcon from '@mui/icons-material/Pause';
import {FormControl, MenuItem, Select} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  games: PropTypes.array,
  onSelectGame: PropTypes.func,
  selectedGame: PropTypes.object
};

export default function GameSelectContent({games, onSelectGame, selectedGame}) {
  const value = selectedGame?.id || '';

  function onChange(e) {
    onSelectGame(games.find((game) => game.id === e.target.value));
  }

  return (
    <FormControl fullWidth>
      <Select
        autoWidth
        onChange={onChange}
        sx={{
          '& .MuiSelect-select': {
            color: 'black',
            backgroundColor: 'white'
          }
        }}
        value={value}
      >
        {games.map((game) => (
          <MenuItem
            key={game.id}
            sx={
              game.execution === 'running'
                ? {fontWeight: 'medium', m: 1}
                : {fontWeight: 'light', m: 1}
            }
            value={game.id}
          >
            {game.execution === 'running' ? '▶' : '⏸'} {game.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

GameSelectContent.propTypes = propTypes;
