export default {
  building: [
    'energy',
    'company',
    'miningarea',
    'landuse',
    'traffic',
    'ghost',
    'city'
  ],
  product: ['resource', 'food', 'industrialproducts', 'agriculturalproducts'],
  research: ['research']
};
