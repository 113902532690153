import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import cs from './AsyncContentSpinner.module.scss';

const propTypes = {
  className: PropTypes.string,
  loadingMessage: PropTypes.string
};

export default function AsyncContentSpinner({className, loadingMessage}) {
  return (
    <div className={cx(className, cs.root)}>
      <CircularProgress disableShrink size={30} />
      {loadingMessage && (
        <Typography className={cs.loadingMessage}>{loadingMessage}</Typography>
      )}
    </div>
  );
}

AsyncContentSpinner.propTypes = propTypes;
