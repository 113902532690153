import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {Link, useRouteMatch} from 'react-router-dom';
import SimpleTable from 'components/SimpleTable/SimpleTable';
import m from '../../messages/de.json';
import cs from './EntityTable.module.scss';
import EntityTableMenu from './EntityTableMenu';

const propTypes = {
  entities: PropTypes.array,
  onDeleteEntity: PropTypes.func.isRequired,
  selectedId: PropTypes.number
};

export default function EntitieTable({entities, onDeleteEntity, selectedId}) {
  const {url} = useRouteMatch();

  function ItemDecoration(id, value, object, field) {
    let nextValue = value;

    if (field === 'entitytype') {
      const tr = m.EntitytypeDropDown.entitytypes.filter(
        (item) => item?.id === value
      );

      nextValue = tr[0]?.name;
    }

    return (
      <Link className={cs.a} to={`${url}/${id}`}>
        {nextValue}
      </Link>
    );
  }

  return (
    <div className={cs.root}>
      <SimpleTable
        columns={[
          {
            title: 'Name',
            field: 'name',
            align: 'left'
          },
          {
            title: 'Entität-Type',
            field: 'entitytype',
            align: 'left'
          },
          {
            title: 'Treibhausg. pp. nachhaltig',
            field: 'ghgemissionperperiodsustainable',
            align: 'right'
          },
          {
            title: 'Treibhausg. pp. nicht nachhaltig',
            field: 'ghgemissionperperiodunsustainable',
            align: 'right'
          }
        ]}
        data={entities}
        functionMenu={({row}) => (
          <EntityTableMenu entity={row} onDeleteEntity={onDeleteEntity} />
        )}
        itemDecoration={ItemDecoration}
        keyfield='id'
        selectedId={selectedId}
      />
    </div>
  );
}

EntitieTable.propTypes = propTypes;
