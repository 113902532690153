export default {
  isAuthenticated() {
    // @TODO simple auth check, not perfekt because token is not validated
    if (localStorage.getItem('jsonwebtoken')) {
      return true;
    } else {
      return false;
    }
  },
  hasUserRole(name) {
    if (localStorage.getItem('userroles').indexOf(name) >= 0) {
      return true;
    } else {
      return false;
    }
  },
  getOrganisationId() {
    return localStorage.getItem('organisationid');
  }
};
