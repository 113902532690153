import gql from 'graphql-tag';

const GET_ENTITYLINK = gql`
  query getEntitylink($id: Int!) {
    entitylink: entitylink_by_pk(id: $id) {
      id
      entityid
      entity {
        name
      }
      numbersustainable
      numberunsustainable
      type
      linktoentityid
    }
  }
`;

const UPDATE_ENTITYLINK = gql`
  mutation updateEntitylink(
    $id: Int!
    $entityid: Int
    $numbersustainable: Int
    $numberunsustainable: Int
    $type: String
    $linktoentityid: Int
  ) {
    update_entitylink_by_pk(
      pk_columns: {id: $id}
      _set: {entityid: $entityid, type: $type, linktoentityid: $linktoentityid}
    ) {
      id
    }
  }
`;

const INSERT_ENTITYLINK = gql`
  mutation insertEntitylink(
    $entityid: Int
    $numbersustainable: Int
    $numberunsustainable: Int
    $type: String
    $linktoentityid: Int
  ) {
    insert_entitylink(
      objects: {
        numbersustainable: $numbersustainable
        numberunsustainable: $numberunsustainable
        entityid: $entityid
        type: $type
        linktoentityid: $linktoentityid
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export {GET_ENTITYLINK, UPDATE_ENTITYLINK, INSERT_ENTITYLINK};
