export default {
  title: 'Buchungen'
};

export const EconomictranslogsContent = {
  protocol: 'Änderungen'
};

export const EconomictranslogsTable = {
  header: {
    gameperiod: 'Spielperiode',
    transactiontype: 'Buchungstyp',
    fromeconomicacc: 'von',
    toeconomicacc: 'nach',
    amountmoney: 'Betrage [$]',
    entity: 'Entität',
    amountentity: 'Menge Entität',
    description: 'Beschreibung',
    status: 'Status',
    transactioncode: 'Transactioncode'
  }
};

export const EconomictranslogsTopBar = {
  gameperiod: 'Spielperiode',
  transactiontype: 'Buchungstyp'
};
