import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  count: PropTypes.number
};

export default function TableNumberOfResults({count}) {
  return <small>{count > 0 ? `${count} Zeilen Daten` : 'keine Daten'}</small>;
}

TableNumberOfResults.propTypes = propTypes;
