import {useMutation} from '@apollo/react-hooks';
import Typography from '@mui/material/Typography';
import gql from 'graphql-tag';
import React, {useState} from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import usePaginatedQuery from 'hooks/usePaginatedQuery';
import EnvUtils from 'utils/EnvUtils';
import EntityrolesContent from './EntityrolesContent';
import EntityrolesTopBar from './EntityrolesTopBar';
import labels from './labels';

export const query = gql`
  query entityroles(
    $where: entity_role_view_bool_exp! = {}
    $limit: Int = 25
    $offset: Int
  ) {
    aggregate: entity_role_view_aggregate(where: $where) {
      entityroles: aggregate {
        totalCount: count
      }
    }
    entityroles: entity_role_view(
      where: $where
      order_by: [
        {gameperiodid: desc}
        {region: {name: asc}}
        {entity: {name: asc}}
      ]
      limit: $limit
      offset: $offset
    ) {
      id: entityroleid
      gameperiodid
      entityid
      entity {
        id
        name
      }
      player {
        id
        title
      }
      region {
        name
      }
      number
      gameperiod {
        name
      }
      status
      transformationpause
    }
  }
`;

export default function Entityroles() {
  const {game} = useGameSelectContext();

  const [filter, setFilter] = useState({
    player: undefined,
    region: undefined
  });

  function onChangeFilter(nextFilter) {
    setFilter((prev) => ({...prev, ...nextFilter}));
  }

  const {
    data: resultEntityroles,
    error,
    loading,
    loadingMore,
    onFetchNextPage,
    refetch
  } = usePaginatedQuery(query, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: {
      limit: 15,
      where: {
        gameid: {_eq: game.id},
        playerid: {_eq: filter.player?.id},
        regionid: {_eq: filter.region?.id},
        entityid: {_eq: filter.entity?.id}
      }
    },
    listName: 'entityroles'
  });

  const destructionEntityroleMutation = gql`
    mutation actionEntityRoleDestruction($entityroleid: Int!) {
      actionEntityRoleDestruction(entityroleid: $entityroleid) {
        code
        message
      }
    }
  `;

  const toggleEntityroleSstatusMutation = gql`
    mutation actionEntityRoleStatusToggle($entityroleid: Int!) {
      actionEntityRoleStatusToggle(entityroleid: $entityroleid) {
        code
        message
      }
    }
  `;

  // challenge: during mutation react re-reanders, why?? solution ignore results
  // https://medium.com/@seniv/improve-performance-of-your-react-apollo-application-440692e37026
  const [onDesctructionEntityroleMutation] = useMutation(
    destructionEntityroleMutation,
    {
      ignoreResults: true
    }
  );

  const [onToggleEntityroleStatusMutation] = useMutation(
    toggleEntityroleSstatusMutation,
    {
      ignoreResults: true
    }
  );

  async function onDesctructionEntityrole(entityrole) {
    await onDesctructionEntityroleMutation({
      variables: {entityroleid: entityrole.id}
    });

    refetch();
  }

  async function onToggleEntityroleStatus(entityrole) {
    await onToggleEntityroleStatusMutation({
      variables: {entityroleid: entityrole.id}
    });

    refetch();
  }

  return (
    <>
      <Typography gutterBottom variant='h5'>
        {labels.title}
      </Typography>
      <EntityrolesTopBar filter={filter} onChangeFilter={onChangeFilter} />

      <AsyncContent error={error} loading={loading}>
        <EntityrolesContent
          loading={loading}
          loadingMore={loadingMore}
          onDesctructionEntityrole={onDesctructionEntityrole}
          onFetchNextPage={onFetchNextPage}
          onToggleEntityroleStatus={onToggleEntityroleStatus}
          refetch={refetch}
          resultEntityroles={resultEntityroles}
        />
      </AsyncContent>
    </>
  );
}
