import PropTypes from 'prop-types';
import React from 'react';
import cs from './Form.module.scss';

const propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.func
};

export default function Form({actions, children, onSubmit}) {
  return (
    <form className={cs.root} onSubmit={onSubmit}>
      <div className={cs.content}>{children}</div>
      {actions && <div className={cs.actions}>{actions}</div>}
    </form>
  );
}

Form.propTypes = propTypes;
