import {useQuery, useMutation} from '@apollo/react-hooks';
import {filter} from 'graphql-anywhere';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import EntityroleContent from 'containers/Entityrole/EntityroleContent';
import {query as EntityRolesQuery} from 'containers/Entityroles/Entityroles';
import entityroleFragment from './entityroleFragment';
import entityroleMutableFragment from './entityroleMutableFragment';

const propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func
};

const query = gql`
  query getEntityrole($id: Int!) {
    entityrole: entity_role_by_pk(id: $id) {
      ...Entityrole_entity_role
    }
  }
  ${entityroleFragment}
`;

const createEntityroleMutation = gql`
  mutation actionEntityRoleCreation(
    $regionid: Int!
    $entityid: Int!
    $playerid: Int!
    $status: String
    $number: Int
  ) {
    actionEntityRoleCreation(
      regionid: $regionid
      entityid: $entityid
      playerid: $playerid
      status: $status
      number: $number
    ) {
      code
      message
    }
  }
`;

const updateEntityroleMutation = gql`
  mutation updateEntityrole(
    $id: Int!
    $entityid: Int
    $gameperiodid: Int
    $number: Int
    $playerid: Int
    $status: String
    $transformationpause: Boolean
  ) {
    update_entity_role_by_pk(
      pk_columns: {id: $id}
      _set: {
        entityid: $entityid
        gameperiodid: $gameperiodid
        number: $number
        playerid: $playerid
        status: $status
        transformationpause: $transformationpause
      }
    ) {
      ...Entityrole_entity_role
    }
  }

  ${entityroleFragment}
`;

export default function Entityrole({id, onClose}) {
  id === undefined ? (id = 0) : (id = id);

  const {
    data: resultEntityrole,
    error,
    loading
  } = useQuery(query, {
    variables: {id}
  });
  const [onUpdateEntityroleMutation] = useMutation(updateEntityroleMutation);
  const [onCreateEntityroleMutation] = useMutation(createEntityroleMutation);

  async function onSubmit(dEntityrole) {
    const dEntityroleNext = {...dEntityrole}; // clone the obj. without reverence to later delete attribute
    dEntityroleNext.number = 1; // default value is allways 1

    if (id > 0) {
      delete dEntityroleNext.regionid;
      return onUpdateEntityroleMutation({
        variables: filter(entityroleMutableFragment, dEntityroleNext)
      });
    } else {
      return onCreateEntityroleMutation({
        variables: dEntityroleNext,
        refetchQueries: [{query: EntityRolesQuery}]
      });
    }
  }

  return (
    <AsyncContent error={error} loading={loading}>
      {() => (
        <EntityroleContent
          onCancel={onClose}
          onSubmit={onSubmit}
          onSubmitFinish={onClose}
          resultEntityrole={resultEntityrole}
        />
      )}
    </AsyncContent>
  );
}
Entityrole.propTypes = propTypes;
