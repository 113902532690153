import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import LandscapecategoryChip from 'components/LandscapecategoryChip/LandscapecategoryChip';
import TableFixedInfiniteLoading from 'components/TableFixedInfiniteLoading/TableFixedInfiniteLoading';
import cs from './LandscaperegionsTable.module.scss';
import {LandscaperegionsTable as labels} from './labels';

const propTypes = {
  className: PropTypes.string,
  landscaperegions: PropTypes.array,
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onFetchNextPage: PropTypes.func,
  selectedId: PropTypes.string
};

function LandscaperegionsTable({
  className,
  landscaperegions,
  loading,
  loadingMore,
  onFetchNextPage,
  selectedId
}) {
  function renderTableHead() {
    return (
      <TableHead component='div'>
        <TableRow component='div'>
          <TableCell
            align='left'
            className={cs.gameperiodColumn}
            component='div'
          >
            {labels.header.gameperiod}
          </TableCell>
          <TableCell align='left' className={cs.regionColumn} component='div'>
            {labels.header.region}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.landscape}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.landscapecategorie}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.status}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Paper className={cx(cs.root, className)}>
      <TableFixedInfiniteLoading
        loading={loading}
        loadingMore={loadingMore}
        onFetchNextPage={onFetchNextPage}
      >
        {() => (
          <>
            {renderTableHead()}
            <TableBody component='div'>
              {landscaperegions.map((landscaperegion) => (
                <TableRow
                  key={landscaperegion.id}
                  className={cx(cs.rowLink, {
                    [cs.rowLink_selected]:
                      landscaperegion.id === parseFloat(selectedId)
                  })}
                  component={(props) => (
                    <Link
                      to={`/landscaperegions/${landscaperegion.id}`}
                      {...props}
                    />
                  )}
                  hover
                >
                  <TableCell
                    align='left'
                    className={cs.gameperiodColumn}
                    component='div'
                  >
                    {landscaperegion.gameperiod.name}
                  </TableCell>
                  <TableCell
                    align='left'
                    className={cs.regionColumn}
                    component='div'
                  >
                    {landscaperegion.region?.name}
                  </TableCell>
                  <TableCell
                    align='left'
                    className={cs.landscapeColumn}
                    component='div'
                  >
                    {landscaperegion.landscape?.code}
                  </TableCell>
                  <TableCell align='left' component='div'>
                    <LandscapecategoryChip
                      value={landscaperegion.landscape?.landscapetype.categorie}
                    />
                  </TableCell>
                  <TableCell align='left' component='div'>
                    {landscaperegion.status}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </>
        )}
      </TableFixedInfiniteLoading>
    </Paper>
  );
}

LandscaperegionsTable.propTypes = propTypes;
export default LandscaperegionsTable;
