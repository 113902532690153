export default {
  title: 'Treibhausgas Emissionen und Absorption'
};

export const ProdaccpostingsTable = {
  header: {
    gameperiod: 'Spielperiode',
    region: 'Region',
    player: 'SpielerIn',
    entity: 'Entity',
    landscape: 'Landscape',
    landscapecategorie: 'LS category',
    sum: 'Sum [Giga Tonnen]',
    etype: 'Wirkung'
  }
};

export const ProdaccpostingsTopBar = {
  gameperiod: 'Gameperiod:',
  etype: 'Impact:',
  absorb: 'Absorption',
  increase: 'Emission'
};

export const etype = {
  absorb: 'Absorption',
  increase: 'Emission'
};
