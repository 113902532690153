import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import EnvUtils from 'utils/EnvUtils';
import TrashPerPlayerContent from './TrashPerPlayerContent';

const propTypes = {
  entityCode: PropTypes.string
};

export const query = gql`
  query trashPerPlayer($gameid: Int!, $code: String!) {
    entity(where: {code: {_eq: $code}}) {
      id
      name
      economicaccs(where: {player: {gameid: {_eq: $gameid}}}) {
        id
        player {
          title
        }
        economicaccpostings(order_by: {id: desc}, limit: 1) {
          balance
        }
      }
      entitylinksByLinktoentityid(
        where: {type: {_eq: "input"}, _and: {deleted: {_neq: true}}}
      ) {
        numbersustainable
        numberunsustainable
        entity {
          name
          id
          demandSustainable: entity_roles_aggregate(
            where: {
              gameperiod: {gameid: {_eq: $gameid}}
              _and: {status: {_eq: "sustainable"}}
            }
          ) {
            aggregate {
              count
            }
          }
          demandUnSustainable: entity_roles_aggregate(
            where: {
              gameperiod: {gameid: {_eq: $gameid}}
              _and: {status: {_eq: "unsustainable"}}
            }
          ) {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`;

export default function TrashPerPlayer({entityCode}) {
  const {game} = useGameSelectContext();

  const {
    data: resultTrashPerPlayer,
    error,
    loading
  } = useQuery(query, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: {gameid: game.id, code: entityCode}
  });

  return (
    <AsyncContent error={error} loading={loading}>
      <TrashPerPlayerContent resultTrashPerPlayer={resultTrashPerPlayer} />
    </AsyncContent>
  );
}

TrashPerPlayer.propTypes = propTypes;
