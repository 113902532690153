import gql from 'graphql-tag';

const GET_GAME = gql`
  query getGame($id: Int!) {
    game: game_by_pk(id: $id) {
      id
      title
      boardtype
      templategameperiodid
      initialghglevel
      execution
      executionfrom
      note
      researchcardpurchaseperperiode
      numberofplayers
      organisationid
    }
  }
`;

const UPDATE_GAME = gql`
  mutation updateGame(
    $id: Int!
    $title: String
    $execution: String
    $executionfrom: date
    $note: String
    $researchcardpurchaseperperiode: Int
    $numberofplayers: Int
    $organisationid: Int
  ) {
    update_game_by_pk(
      pk_columns: {id: $id}
      _set: {
        title: $title
        execution: $execution
        executionfrom: $executionfrom
        note: $note
        researchcardpurchaseperperiode: $researchcardpurchaseperperiode
        numberofplayers: $numberofplayers
        organisationid: $organisationid
      }
    ) {
      id
    }
  }
`;

const INSERT_GAME = gql`
  mutation insertGame(
    $title: String
    $templategameperiodid: Int
    $initialghglevel: numeric
    $execution: String
    $executionfrom: date
    $note: String
    $researchcardpurchaseperperiode: Int
    $numberofplayers: Int
    $organisationid: Int
  ) {
    insert_game(
      objects: {
        title: $title
        templategameperiodid: $templategameperiodid
        initialghglevel: $initialghglevel
        execution: $execution
        executionfrom: $executionfrom
        note: $note
        researchcardpurchaseperperiode: $researchcardpurchaseperperiode
        numberofplayers: $numberofplayers
        organisationid: $organisationid
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export {GET_GAME, UPDATE_GAME, INSERT_GAME};
