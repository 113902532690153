import PropTypes from 'prop-types';
import React from 'react';
import {useRouteMatch, useHistory} from 'react-router-dom';
import SidebarDrawer from 'components/SidebarDrawer/SidebarDrawer';
import TableNumberOfResults from 'components/TableNumberOfResult/TableNumberOfResult';
import cs from 'containers/Prodaccpostings/ProdaccpostingsContent.module.scss';
import ResearchCard from 'containers/ResearchCard/ResearchCard';
import ResearchCardsTable from './ResearchCardsTable';

const propTypes = {
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onFetchNextPage: PropTypes.func,
  resultResearchCards: PropTypes.object
};

export default function ResearchCardsContent({
  loading,
  loadingMore,
  onFetchNextPage,
  resultResearchCards
}) {
  const history = useHistory();
  const {params} = useRouteMatch('/researchcards/:id?');

  function onClose() {
    history.push('/researchcards');
  }

  return (
    <div className={cs.root}>
      <ResearchCardsTable
        loading={loading}
        loadingMore={loadingMore}
        onFetchNextPage={onFetchNextPage}
        researchcardsplayers={resultResearchCards?.researchcardsplayers}
        selectedId={params.id}
      />

      <SidebarDrawer onClose={onClose} open={params.id != null}>
        {params.id != null ? (
          <ResearchCard id={params.id} onClose={onClose} />
        ) : (
          ''
        )}
      </SidebarDrawer>

      <TableNumberOfResults
        count={resultResearchCards?.aggregate?.researchcardsplayers?.totalCount}
      />
    </div>
  );
}

ResearchCardsContent.propTypes = propTypes;
