import {useQuery, useMutation} from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import React from 'react';
import EntityContent from 'containers/Entity/EntityContent';
import {
  GET_ENTITY,
  UPDATE_ENTITY,
  INSERT_ENTITY
} from 'containers/Entity/EntitySchemas';
import AsyncContent from '../../components/AsyncContent/AsyncContent';

const propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func
};

export default function Entity({id, onClose}) {
  id === undefined ? (id = 0) : (id = id);

  const {
    data: resultEntity,
    error,
    loading
  } = useQuery(GET_ENTITY, {
    variables: {id}
  });

  const [updateEntity] = useMutation(UPDATE_ENTITY);
  const [insertEntity] = useMutation(INSERT_ENTITY);

  async function onSubmit(dEntity) {
    let res;

    if (resultEntity.entity !== null) {
      res = updateEntity({variables: dEntity});
    } else {
      dEntity.salesprice = !dEntity.salesprice ? 1 : dEntity.salesprice; // darf nicht 0 sein
      dEntity.ghgemissionperperiodsustainable =
        !dEntity.ghgemissionperperiodsustainable
          ? 0
          : dEntity.ghgemissionperperiodsustainable;
      dEntity.ghgemissionperperiodunsustainable =
        !dEntity.ghgemissionperperiodunsustainable
          ? 0
          : dEntity.ghgemissionperperiodunsustainable;
      dEntity.updatetosustainablecosts = !dEntity.updatetosustainablecosts
        ? 0
        : dEntity.updatetosustainablecosts;
      dEntity.buildingcosts = !dEntity.buildingcosts
        ? 0
        : dEntity.buildingcosts;
      res = insertEntity({variables: dEntity});
    }

    return res;
  }

  return (
    <AsyncContent error={error} loading={loading}>
      <EntityContent
        onClose={onClose}
        onSubmit={onSubmit}
        resultEntity={resultEntity}
      />
    </AsyncContent>
  );
}
Entity.propTypes = propTypes;
