import {useQuery} from '@apollo/react-hooks';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import cs from 'containers/Entityroles/EntityrolesContent.module.scss';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import ProdaccPlayerGraphContent from 'containers/Graphs/ProdaccPlayerGraphContent';
import GET_PRODACCPLAYERGRAPH from './ProdaccPlayerGraphSchemas';

const propTypes = {};

// for each Region
function convertProdaccplayersToChartjsDatasets(players, etype) {
  const datasets = [];

  players
    .filter((player) => player.prodaccplayers.length > 0)
    .forEach((player) => {
      const data = [];
      player.prodaccplayers.forEach((prodaccplayer) => {
        switch (etype) {
          case 'absorb':
            data.push(prodaccplayer.sumabsorb * -1);
            break;
          case 'increase':
            data.push(prodaccplayer.sumincrease);
            break;
          default:
            data.push(prodaccplayer.sumvalue);
        }
      });
      const dataset = {
        label: player.title,
        fill: false,
        data,
        backgroundColor:
          player.role.code === 'GOVERNMENTROLE'
            ? player.region.color
            : player.role.color
      };
      datasets.push(dataset);
    });

  return datasets;
}

// overall Sum "World"
/*
function convertProdaccallToChartjsDatasets(datasets, all) {
  const data = [];
  all.forEach((prodaccregion) => {
    data.push(prodaccregion.sumvalue);
  });
  const dataset = {
    label: 'Planet Orasis',
    fill: false,
    borderDash: [10, 5],
    pointRadius: 9,
    data,
    borderColor: 'rgb(0,0,0)'
  };
  datasets.push(dataset);

  return datasets;
}
*/

// eslint-disable-next-line func-style
const convertGameperiodsToChartjsLabels = (gameperiods) =>
  gameperiods.map((c) => [c.name]);

function useProdaccGraph(game, etype) {
  const {data, error, loading} = useQuery(GET_PRODACCPLAYERGRAPH, {
    variables: {gameid: game.id}
  });

  // ... calculation of graph data
  let datasets = [];
  if (!loading && data.players !== undefined) {
    datasets = convertProdaccplayersToChartjsDatasets(data.players, etype);
    // datasets = convertProdaccallToChartjsDatasets(datasets, data.prodaccall);
  }

  let labels = [];
  if (!loading && data.gameperiods !== undefined) {
    labels = convertGameperiodsToChartjsLabels(data.gameperiods);
  }
  const graphData = {labels, datasets};

  // set a loading state until the data is fetched AND calculated
  const isLoading = loading || !graphData;

  return {
    loading: isLoading,
    error,
    data: graphData
  };
}

export default function ProdaccPlayerGraph() {
  const {game} = useGameSelectContext();
  const [etype, setEtype] = useState('both');
  const {data, error, loading} = useProdaccGraph(game, etype);

  function handleChange(e) {
    setEtype(e.target.value);
  }

  return (
    <>
      <Typography gutterBottom variant='h5'>
        Treibhausgas Bilanz (Emissionen und Absorption) pro SpielerIn [Giga
        Tonnen]
      </Typography>
      <RadioGroup
        aria-labelledby='demo-row-radio-buttons-group-label'
        defaultValue='both'
        name='row-radio-buttons-group'
        onChange={handleChange}
        row
      >
        <FormControlLabel
          control={<Radio />}
          label='Emission'
          value='increase'
        />
        <FormControlLabel
          control={<Radio />}
          label='Absorption'
          value='absorb'
        />
        <FormControlLabel
          control={<Radio />}
          label='Bilanz (= Emission + Absorption)'
          value='both'
        />
      </RadioGroup>
      <AsyncContent error={error} loading={loading}>
        <ProdaccPlayerGraphContent graphData={data} />
      </AsyncContent>
      <Button
        className={cs.newEntity}
        color='primary'
        component={Link}
        to='/prodaccpostings'
        variant='contained'
      >
        details
      </Button>
    </>
  );
}

ProdaccPlayerGraph.propTypes = propTypes;
