import {useQuery, useMutation} from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import React from 'react';
import TaxlawContent from 'containers/Taxlaw/TaxlawContent';
import {GET_TAXLAW, UPDATE_TAXLAW} from 'containers/Taxlaw/TaxlawSchemas';
import AsyncContent from '../../components/AsyncContent/AsyncContent';

const propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func
};

export default function Taxlaw({id, onClose}) {
  id === undefined ? (id = 0) : (id = id);

  const {
    data: resultTaxlaw,
    error,
    loading
  } = useQuery(GET_TAXLAW, {
    variables: {id}
  });

  const [updateTaxlaw] = useMutation(UPDATE_TAXLAW);

  async function onSubmit(dTaxlaw) {
    let res;

    if (resultTaxlaw.taxlaw !== null) {
      const dTaxlawNew = {...dTaxlaw};
      delete dTaxlawNew.player;
      res = updateTaxlaw({variables: dTaxlawNew});
    }

    return res;
  }

  return (
    <AsyncContent error={error} loading={loading}>
      <TaxlawContent
        id={id}
        onClose={onClose}
        onSubmit={onSubmit}
        resultTaxlaw={resultTaxlaw}
      />
    </AsyncContent>
  );
}
Taxlaw.propTypes = propTypes;
