import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import Form from 'components/Form/Form';
import FormColumn from 'components/Form/FormColumn';
import FormItem from 'components/Form/FormItem';
import TextInput from 'components/TextInput/TextInput';
import cs from '../LandscaperegionForm/LandscaperegionForm.module.scss';
import labels from './labels';

const validate = (values) => {
  const errors = {};

  if (!values.oldPwd) {
    errors.oldPwd = 'Bitte das Aktuelle Passwort eingeben';
  }

  if (!values.newPwd) {
    errors.newPwd = 'Bitte ein Neues Passwort eingeben';
  }

  if (!values.newPwd2) {
    errors.newPwd2 = 'Bitte das Neues Passwort ein zweites mal eingeben';
  }

  if (values.newPwd2 !== values.newPwd) {
    errors.newPwd2 =
      'Das Neue Passwort und die Wiederholung stimmen nicht überein';
  }

  return errors;
};

const propTypes = {
  onAdminPwdChange: PropTypes.func
};

export default function AdminPwdChangeForm({onAdminPwdChange}) {
  const {enqueueSnackbar} = useSnackbar();

  const formik = useFormik({
    initialValues: {oldPwd: undefined, newPwd: undefined},
    validate,
    onSubmit: onSubmitForm,
    enableReinitialize: true
  });

  function onSubmitForm(values) {
    return onAdminPwdChange(values)
      .then((res) => {
        if (res?.data?.actionAdminPwdChange?.code === '200') {
          enqueueSnackbar(`${res?.data?.actionAdminPwdChange?.message}`, {
            variant: 'success'
          });
          formik.resetForm();
        } else {
          enqueueSnackbar(`${res?.data?.actionAdminPwdChange?.message}`, {
            variant: 'warning'
          });
        }
      })
      .catch(() => {
        enqueueSnackbar(labels.error, {
          variant: 'error'
        });
      });
  }

  return (
    <div className={cx(cs.root)}>
      <Typography gutterBottom variant='h6'>
        {labels.title}
      </Typography>
      <Form
        actions={
          <>
            <Button
              color='primary'
              disabled={formik.isSubmitting || !formik.isValid}
              type='submit'
              variant='contained'
            >
              {labels.save}
            </Button>
          </>
        }
        onSubmit={formik.handleSubmit}
      >
        <FormColumn>
          <FormItem
            error={formik.errors.oldPwd}
            label={labels.oldpwd}
            name='oldPwd'
            onChange={formik.handleChange}
            value={formik.values?.oldPwd}
          >
            <TextInput type='password' />
          </FormItem>
          <FormItem
            error={formik.errors.newPwd}
            label={labels.newpwd}
            name='newPwd'
            onChange={formik.handleChange}
            value={formik.values?.newPwd}
          >
            <TextInput type='password' />
          </FormItem>
          <FormItem
            error={formik.errors.newPwd2}
            label={labels.newpwd2}
            name='newPwd2'
            onChange={formik.handleChange}
            value={formik.values?.newPwd2}
          >
            <TextInput type='password' />
          </FormItem>
        </FormColumn>
      </Form>
    </div>
  );
}
AdminPwdChangeForm.propTypes = propTypes;
