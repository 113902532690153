// eslint-disable-next-line import/no-extraneous-dependencies
import {useFormik} from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'components/Button/Button';
import Form from 'components/Form/Form';
import FormColumn from 'components/Form/FormColumn';
import FormItem from 'components/Form/FormItem';
import TextInput from 'components/TextInput/TextInput';
import cs from './EconomicaccForm.module.scss';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'name is required';
  }

  if (!values.economicacctype) {
    errors.economicacctype = 'economicacctype is required';
  }

  return errors;
};

const initialValues = {
  name: ''
};

const propTypes = {
  economicacc: PropTypes.object,
  onClose: PropTypes.func
};
export default function EconomicaccForm({economicacc, onClose}) {
  const formik = useFormik({
    initialValues: economicacc || initialValues,
    validate,
    enableReinitialize: true
  });

  return (
    <div className={cs.root}>
      <Form
        actions={
          <Button onClick={onClose} variant='outlined'>
            Abbrechen
          </Button>
        }
        onSubmit={formik.handleSubmit}
      >
        <FormColumn>
          <FormItem
            error={formik.errors.accounttype}
            label='Kontotyp'
            name='accounttype'
            onChange={formik.handleChange}
            value={formik.values.accounttype}
          >
            <TextInput readonly />
          </FormItem>
          <FormItem
            error={formik.errors?.entity?.name}
            label='Entität'
            name='name'
            onChange={formik.handleChange}
            value={formik.values?.entity?.name}
          >
            <TextInput readonly />
          </FormItem>
          <FormItem
            error={formik.errors.player}
            label='Besitzer'
            name='player'
            onChange={formik.handleChange}
            value={formik.values.player?.title}
          >
            <TextInput readonly />
          </FormItem>
        </FormColumn>
      </Form>
    </div>
  );
}
EconomicaccForm.propTypes = propTypes;
