import {useQuery} from '@apollo/react-hooks';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import cs from 'containers/Entityroles/EntityrolesContent.module.scss';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import ProdaccGraphContent from 'containers/Graphs/ProdaccGraphContent';
import GET_PRODACCGRAPH from './ProdaccGraphSchemas';

const propTypes = {};

// for each Region
function convertProdaccregionsToChartjsDatasets(regions, etype) {
  const datasets = [];

  regions.forEach((region) => {
    const data = [];
    region.prodaccregions.forEach((prodaccregion) => {
      switch (etype) {
        case 'absorb':
          data.push(prodaccregion.sumabsorb * -1);
          break;
        case 'increase':
          data.push(prodaccregion.sumincrease);
          break;
        default:
          data.push(prodaccregion.sumvalue);
      }
    });
    const dataset = {
      label: region.name,
      fill: false,
      data,
      borderColor: region.color,
      backgroundColor: region.color
    };
    datasets.push(dataset);
  });

  return datasets;
}

// overall Sum "World"
function convertProdaccallToChartjsDatasets(datasets, all, etype) {
  const data = [];
  all.forEach((prodaccregion) => {
    switch (etype) {
      case 'absorb':
        data.push(prodaccregion.sumabsorb * -1);
        break;
      case 'increase':
        data.push(prodaccregion.sumincrease);
        break;
      default:
        data.push(prodaccregion.sumvalue);
    }
  });
  const dataset = {
    label: 'Planet Orasis',
    fill: false,
    borderDash: [10, 5],
    pointRadius: 9,
    data,
    borderColor: 'rgb(0,0,0)'
  };
  datasets.push(dataset);

  return datasets;
}

// eslint-disable-next-line func-style
const convertGameperiodsToChartjsLabels = (gameperiods) =>
  gameperiods.map((c) => [c.name]);

function useProdaccGraph(game, etype) {
  const {data, error, loading} = useQuery(GET_PRODACCGRAPH, {
    variables: {boardtype: game.boardtype, gameid: game.id}
  });

  // ... calculation of graph data
  let datasets = [];
  if (!loading && data.regions !== undefined) {
    datasets = convertProdaccregionsToChartjsDatasets(data.regions, etype);
    datasets = convertProdaccallToChartjsDatasets(
      datasets,
      data.prodaccall,
      etype
    );
  }

  let labels = [];
  if (!loading && data.gameperiods !== undefined) {
    labels = convertGameperiodsToChartjsLabels(data.gameperiods);
  }
  const graphData = {labels, datasets};

  // set a loading state until the data is fetched AND calculated
  const isLoading = loading || !graphData;

  return {
    loading: isLoading,
    error,
    data: graphData
  };
}

export default function ProdaccGraph() {
  const {game} = useGameSelectContext();
  const [etype, setEtype] = useState('both');
  const {data, error, loading} = useProdaccGraph(game, etype);

  function handleChange(e) {
    setEtype(e.target.value);
  }

  return (
    <>
      <Typography gutterBottom variant='h5'>
        Treibhausgas Bilanz (Emission und Absorption) pro Region [Giga Tonnen]
      </Typography>
      <RadioGroup
        aria-labelledby='demo-row-radio-buttons-group-label'
        defaultValue='both'
        name='row-radio-buttons-group'
        onChange={handleChange}
        row
      >
        <FormControlLabel
          control={<Radio />}
          label='Emission'
          value='increase'
        />
        <FormControlLabel
          control={<Radio />}
          label='Absorption'
          value='absorb'
        />
        <FormControlLabel
          control={<Radio />}
          label='Bilanz (= Emission + Absorption)'
          value='both'
        />
      </RadioGroup>
      <AsyncContent error={error} loading={loading}>
        <ProdaccGraphContent graphData={data} />
      </AsyncContent>
      <Button
        className={cs.newEntity}
        color='primary'
        component={Link}
        to='/prodaccpostings'
        variant='contained'
      >
        details
      </Button>
    </>
  );
}

ProdaccGraph.propTypes = propTypes;
