import {FormControl, MenuItem, Select} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import cs from './PlayersSelectContent.module.scss';

const propTypes = {
  onChangePlayer: PropTypes.func,
  players: PropTypes.array,
  selectedPlayer: PropTypes.object
};

export default function PlayersSelectContent({
  onChangePlayer,
  players,
  selectedPlayer
}) {
  function onChange(e) {
    onChangePlayer(e.target.value === '' ? undefined : e.target.value);
  }

  return (
    <FormControl fullWidth>
      <Select
        autoWidth
        className={cs.select}
        displayEmpty
        onChange={onChange}
        value={selectedPlayer || ''}
      >
        <MenuItem value=''>alle</MenuItem>
        {players.map((player) => (
          <MenuItem key={player.id} value={player}>
            {player.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

PlayersSelectContent.propTypes = propTypes;
