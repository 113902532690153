import {useQuery} from '@apollo/react-hooks';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import pattern from 'patternomaly';
import React from 'react';
import {Link} from 'react-router-dom';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import cs from 'containers/Entityroles/EntityrolesContent.module.scss';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import InhabitantGraphContent from 'containers/Graphs/InhabitantGraphContent';
import GET_INHABITANTGRAPH from './InhabitantGraphSchemas';

const propTypes = {};

// for each Region
function convertInhabitantregionsToChartjsDatasets(regions, etype) {
  const datasets = [];

  regions.forEach((region) => {
    const data = [];
    region.inhabitants.forEach((inhabitant) => {
      switch (etype) {
        case 'population':
          data.push(inhabitant.population_sum);
          break;
        case 'accomodation':
          data.push(inhabitant.accomodationcapacity_sum);
          break;
        default:
          data.push(inhabitant.population_sum);
      }
    });

    let bgColor = region.color;
    if (etype === 'accomodation') {
      bgColor = pattern.draw('zigzag-horizontal', region.color);
    }

    let regionName = region.name;
    if (etype === 'accomodation') {
      regionName = region.name + ' - Wohnraum';
    }

    const dataset = {
      label: regionName,
      fill: false,
      data,
      borderColor: region.color,
      backgroundColor: bgColor
    };
    datasets.push(dataset);
  });

  return datasets;
}

// overall Sum "World"
function convertInhabitantsallToChartjsDatasets(datasets, all) {
  const data = [];
  all.forEach((gameperiod) => {
    data.push(
      gameperiod.inhabitantacc_view_aggregate.aggregate.sum.population_sum
    );
  });
  const dataset = {
    label: 'Planet Orasis',
    fill: false,
    borderDash: [10, 5],
    pointRadius: 9,
    data,
    borderColor: 'rgb(0,0,0)'
  };
  datasets.push(dataset);

  return datasets;
}

// eslint-disable-next-line func-style
const convertGameperiodsToChartjsLabels = (gameperiods) =>
  gameperiods.map((c) => [c.name]);

function useInhabitantGraph(game) {
  const {data, error, loading} = useQuery(GET_INHABITANTGRAPH, {
    variables: {boardtype: game.boardtype, gameid: game.id}
  });

  // ... calculation of graph data
  let datasets = [];
  if (!loading && data.regions !== undefined) {
    datasets = convertInhabitantregionsToChartjsDatasets(
      data.regions,
      'population'
    );
    /**
    datasets = datasets.concat(
      convertInhabitantregionsToChartjsDatasets(data.regions, 'accomodation')
    );*/

    datasets = convertInhabitantsallToChartjsDatasets(
      datasets,
      data.inhabitantsall
    );
  }

  let labels = [];
  if (!loading && data.gameperiods !== undefined) {
    labels = convertGameperiodsToChartjsLabels(data.gameperiods);
  }
  const graphData = {labels, datasets};

  // set a loading state until the data is fetched AND calculated
  const isLoading = loading || !graphData;

  return {
    loading: isLoading,
    error,
    data: graphData
  };
}

export default function InhabitantGraph() {
  const {game} = useGameSelectContext();
  const {data, error, loading} = useInhabitantGraph(game);

  return (
    <>
      <Typography gutterBottom variant='h5'>
        Bevölkerung
      </Typography>

      <AsyncContent error={error} loading={loading}>
        <InhabitantGraphContent graphData={data} />
      </AsyncContent>
      <Button
        className={cs.newEntity}
        color='primary'
        component={Link}
        to='/prodaccpostings'
        variant='contained'
      >
        details
      </Button>
    </>
  );
}

InhabitantGraphContent.propTypes = propTypes;
