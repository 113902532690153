import gql from 'graphql-tag';

const GET_PRODACCALLGRAPH = gql`
  query ProdaccAllGraph($gameid: Int!) {
    prodaccallpostings: prodaccallposting(
      order_by: {gameperiodid: asc}
      where: {
        gameperiod: {gameid: {_eq: $gameid}}
        outtype: {_eq: "ghgemissionacc"}
      }
    ) {
      periodcontribution
      gametotal
    }

    gameperiods: gameperiod(
      order_by: {id: asc}
      where: {gameid: {_eq: $gameid}, closed: {_eq: true}}
    ) {
      id
      gameid
      period
      name
      closed
    }
  }
`;

export default GET_PRODACCALLGRAPH;
