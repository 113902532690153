import PropTypes from 'prop-types';
import React from 'react';
import {Route, Switch} from 'react-router-dom';
import EconomictranslogForm from 'components/EconomictranslogForm/EconomictranslogForm';

const propTypes = {
  onClose: PropTypes.func,
  resultEconomictranslog: PropTypes.object
};

export default function EconomictranslogContent({
  onClose,
  resultEconomictranslog
}) {
  const tabs = [
    {
      title: `${
        resultEconomictranslog.economictranslog !== null
          ? 'Buchung-Nr: ' +
            resultEconomictranslog.economictranslog.gameperiodid
          : 'Neue'
      }`,
      to: `/economictranslogs/${
        resultEconomictranslog.economictranslog !== null
          ? resultEconomictranslog.economictranslog.id
          : null
      }`
    }
  ];

  return (
    <>
      <Switch>
        <Route>
          <EconomictranslogForm
            economictranslog={
              resultEconomictranslog
                ? resultEconomictranslog.economictranslog
                : undefined
            }
            onClose={onClose}
            onSubmit={undefined}
          />
        </Route>
      </Switch>
    </>
  );
}

EconomictranslogContent.propTypes = propTypes;
