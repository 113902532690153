import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {Link, useRouteMatch} from 'react-router-dom';
import cs from 'components/EntityTable/EntityTable.module.scss';
import SimpleTable from 'components/SimpleTable/SimpleTable';
import OrganisationsTableMenu from './OrganisationsTableMenu';

const propTypes = {
  onDeleteOrganisation: PropTypes.func,
  organisations: PropTypes.array,
  selectedId: PropTypes.number
};

export default function OrganisationsTable({
  onDeleteOrganisation,
  organisations,
  selectedId
}) {
  const {url} = useRouteMatch();

  function ItemDecoration(id, value, object) {
    let nextValue = value;
    if (object) {
      nextValue = object[value];
    } else if (value === 'name') {
      nextValue = '';
    }

    return (
      <Link className={cs.a} to={`${url}/${id}`}>
        {nextValue}
      </Link>
    );
  }

  return (
    <div className={cs.root}>
      <SimpleTable
        columns={[
          {
            title: 'Name',
            field: 'name',
            align: 'left'
          }
        ]}
        data={organisations}
        functionMenu={({row}) => (
          <OrganisationsTableMenu
            onDeleteOrganisation={onDeleteOrganisation}
            userlist={row}
          />
        )}
        itemDecoration={ItemDecoration}
        keyfield='id'
        selectedId={selectedId}
      />
    </div>
  );
}

OrganisationsTable.propTypes = propTypes;
