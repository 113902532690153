export default {
  title: 'Bauwerke'
};

export const EntityrolesContent = {
  new: 'Neues Bauwerk',
  protocol: 'Änderungen'
};

export const EntityrolesTableMenu = {
  confirmDelete: (entityrole) =>
    `Wollen Sie das ausgewählte Bauwerk ${entityrole.id} wirklich abbrechen?`,
  confirmStatusToggle: (entityrole) =>
    `Wollen Sie den Status '${entityrole.status}' von ${entityrole.id} wirklich ändern?`
};

export const EntityrolesTable = {
  header: {
    gameperiod: 'Spielperiode',
    landscapecode: 'Hexagon',
    landscaperegion: 'Region',
    landscapecategorie: 'Hex. Kat.',
    entity: 'Entität',
    player: 'Besitzer',
    transformationpause: 'pausiert',
    status: 'Status',
    number: 'Nr'
  }
};

export const EntityrolesTopBar = {
  region: 'Region',
  player: 'Besitzer:',
  entity: 'Entität'
};
