export default {
  destructioncosts: '🏗 Abrißkosten',
  buildingcosts: '🧱 Baukosten',
  transformationfreshwater: '🌧 Frisches Wasser',
  transfer: '💰 Geldüberweisung',
  initalloadentity: '🎁 Buchung Produkte',
  initalloadmoney: '🧧 Buchung Kapital',
  transferentity: '🚍 Transport',
  transformationreturns: '🍇 Transformations Ertrag',
  transformationcosts: '🍖 Transformations Verbrauch',
  waterlossgovernments: '☀ Verdunstung Wasser',
  demolitionreturns: '🪨 Vergütung Zurückstufung'
};
