import {useQuery} from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import GET_TAXLAWS from 'containers/Taxlaws/TaxlawsSchemas';
import EnvUtils from 'utils/EnvUtils';
import TaxlawsContent from './TaxlawsContent';

const propTypes = {
  id: PropTypes.number
};

export default function Taxlaws({id}) {
  const {
    data: resultTaxlaws,
    error,
    loading,
    refetch
  } = useQuery(GET_TAXLAWS, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: {gameperiodid: id}
  });

  return (
    <AsyncContent error={error} loading={loading}>
      <TaxlawsContent id={id} refetch={refetch} resultTaxlaws={resultTaxlaws} />
    </AsyncContent>
  );
}

Taxlaws.propTypes = propTypes;
