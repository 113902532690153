import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {Link, useRouteMatch} from 'react-router-dom';
import cs from 'components/EntityTable/EntityTable.module.scss';
import SimpleTable from 'components/SimpleTable/SimpleTable';
import UserlistsTableMenu from './UserlistsTableMenu';

const propTypes = {
  onDeleteUserlist: PropTypes.func,
  onPwdSetUserlist: PropTypes.func,
  selectedId: PropTypes.number,
  userlists: PropTypes.array
};

export default function UserlistsTable({
  onDeleteUserlist,
  onPwdSetUserlist,
  selectedId,
  userlists
}) {
  const {url} = useRouteMatch();

  function ItemDecoration(id, value, object) {
    let nextValue = value;
    if (object) {
      nextValue = object[value];
    } else if (value === 'name') {
      nextValue = '';
    }

    return (
      <Link className={cs.a} to={`${url}/${id}`}>
        {nextValue}
      </Link>
    );
  }

  return (
    <div className={cs.root}>
      <SimpleTable
        columns={[
          {
            title: 'Name',
            field: 'longname',
            align: 'left'
          },
          {
            title: 'E-Mail',
            field: 'email',
            align: 'left'
          },
          {
            title: 'Organisation',
            field: 'name',
            object: 'organisation',
            align: 'left'
          },
          {
            title: 'Rollen',
            field: 'userrole',
            align: 'left'
          }
        ]}
        data={userlists}
        functionMenu={({row}) => (
          <UserlistsTableMenu
            onDeleteUserlist={onDeleteUserlist}
            onPwdSetUserlist={onPwdSetUserlist}
            userlist={row}
          />
        )}
        itemDecoration={ItemDecoration}
        keyfield='id'
        selectedId={selectedId}
      />
    </div>
  );
}

UserlistsTable.propTypes = propTypes;
