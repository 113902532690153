export default {
  title: 'Bevölkerung und Wohnraum'
};

export const InhabitantsTable = {
  header: {
    region: 'Region',
    ghost: 'Geist/Stadt',
    unsustainable: 'Nicht Nachhaltig',
    sustainable: 'Nachhaltig',
    population: 'Bevölkerung',
    accommodationcapacity: 'Wohnraum'
  }
};
