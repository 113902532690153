import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';

export default function usePlayerModel(code) {
  const GET_ROLES = gql`
    query getPlayers($where: player_bool_exp! = {}) {
      players: player(order_by: {title: asc}, where: $where) {
        id
        title
      }
    }
  `;
  const {game} = useGameSelectContext();

  const whereAdd = {
    gameid: {_eq: game.id}
  };

  if (code) {
    whereAdd.role = {code: {_eq: code}};
  }

  const {
    data: resultPlayers,
    error,
    loading
  } = useQuery(GET_ROLES, {
    variables: {
      where: whereAdd
    }
  });

  return {
    resultPlayers,
    loading,
    error
  };
}
