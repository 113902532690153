import PropTypes from 'prop-types';
import React from 'react';
import LandscaperegionForm from 'components/LandscaperegionForm/LandscaperegionForm';

const propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitFinish: PropTypes.func,
  resultLandscaperegion: PropTypes.object
};

export default function LandscaperegionContent({
  onCancel,
  onSubmit,
  onSubmitFinish,
  resultLandscaperegion
}) {
  return (
    <LandscaperegionForm
      landscaperegion={resultLandscaperegion?.landscaperegion}
      onCancel={onCancel}
      onSubmit={onSubmit}
      onSubmitFinish={onSubmitFinish}
    />
  );
}

LandscaperegionContent.propTypes = propTypes;
