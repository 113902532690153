export default {
  title: 'Bauwerke Änderungen',
  explanation:
    'Änderungen werden nur 21 Tage aufbewahrt (um Speicherplatz zu sparen)'
};

export const ProtocolsTableEntityrole = {
  header: {
    gameperiod: 'Spielperiode',
    ts: 'Zeitpunkt',
    region: 'Region',
    player: 'Besitzer',
    entity: 'Bauwerk',
    entityroleid: 'Bauw. #',
    new_region: 'Neue Region',
    new_player: 'Neuer Besitzer',
    delta: 'Änderungen'
  },
  operations: {
    INSERT: 'Neu Bau',
    DELETE: 'Abbruch'
  },
  status: {
    unsustainable: 'Nicht Nachh./gewöhnliche Tech.',
    sustainable: 'Nachh./fortgesch. Tech.'
  },
  transformationpause: {
    true: 'Pause',
    false: 'Aktiv'
  }
};

export const ProtocolsTableLandscaperegion = {
  header: {
    gameperiod: 'Spielperiode',
    ts: 'Zeitpunkt',
    region: 'Region',
    landscapecategorie: 'Hex. Kat.',
    entityroleid: 'Bauw. #',
    new_region: 'Neue Region',
    new_player: 'Neuer Besitzer',
    delta: 'Änderungen',
    code: 'Hexagon'
  },
  status: {
    healthy: 'healthy',
    utilized: 'utilized',
    dead: 'dead'
  }
};

export const ProtocolsTopBar = {
  region: 'Region:',
  player: 'Besitzer'
};
