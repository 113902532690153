import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {useRouteMatch} from 'react-router-dom';
import SimpleTable from 'components/SimpleTable/SimpleTable';
import cs from './TaxlawheadquarterTable.module.scss';

const propTypes = {
  onClickShowTaxlawheadquarter: PropTypes.func,
  taxlawheadquarters: PropTypes.array
};
export default function TaxlawheadquarterTable({
  onClickShowTaxlawheadquarter,
  taxlawheadquarters
}) {
  const {url} = useRouteMatch();

  function ItemDecoration(id, value, object) {
    let nextValue = value;
    if (object) {
      nextValue = object[value];
    } else if (value === 'name') {
      nextValue = '';
    }

    return (
      <Link
        color='inherit'
        component='button'
        onClick={() => onClickShowTaxlawheadquarter(id)}
        style={{textDecoration: 'none'}}
      >
        {nextValue}
      </Link>
    );
  }

  if (taxlawheadquarters === undefined) {
    return null;
  }

  return (
    <div className={cs.root}>
      <SimpleTable
        columns={[
          {
            title: 'Unternehmen',
            object: 'player',
            field: 'title',
            align: 'left'
          },
          {
            title: 'zahlt Steuern an',
            object: 'playerByPlayeridtaxreceiver',
            field: 'title',
            align: 'left'
          }
        ]}
        data={taxlawheadquarters}
        itemDecoration={ItemDecoration}
        keyfield='id'
      />
    </div>
  );
}

TaxlawheadquarterTable.propTypes = propTypes;
