import gql from 'graphql-tag';

const GET_TAXLAW = gql`
  query getTaxlaw($id: Int!) {
    taxlaw: taxlaw_by_pk(id: $id) {
      id
      player {
        title
      }
      transformtax_government_quota
      transformtax_labour_quota
      headquarter_salestax_ratio
    }
  }
`;

const UPDATE_TAXLAW = gql`
  mutation updateTaxlaw(
    $id: Int!
    $transformtax_government_quota: Int
    $transformtax_labour_quota: Int
    $headquarter_salestax_ratio: Int
  ) {
    update_taxlaw_by_pk(
      pk_columns: {id: $id}
      _set: {
        transformtax_government_quota: $transformtax_government_quota
        transformtax_labour_quota: $transformtax_labour_quota
        headquarter_salestax_ratio: $headquarter_salestax_ratio
      }
    ) {
      id
    }
  }
`;

export {GET_TAXLAW, UPDATE_TAXLAW};
