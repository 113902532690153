import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import EnvUtils from 'utils/EnvUtils';

export default function useEntityModel(filterEntitytype) {
  const GET_ENTITIES = gql`
    query entities($filterEntitytype: [String!]) {
      entities: entity(
        order_by: {name: asc}
        where: {
          _and: {entitytype: {_in: $filterEntitytype}, deleted: {_eq: false}}
        }
      ) {
        id
        name
      }
    }
  `;

  const {
    data: resultEntities,
    error,
    loading
  } = useQuery(GET_ENTITIES, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: {filterEntitytype}
  });

  return {
    resultEntities: {...resultEntities},
    loading,
    error
  };
}
