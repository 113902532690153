import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import TableFixedInfiniteLoading from 'components/TableFixedInfiniteLoading/TableFixedInfiniteLoading';
import Formater from 'utils/Formater';
import cs from './InhabitantsTable.module.scss';
import InhabitantsTableRow, {
  getPopulationSum,
  getAccomodationcapacitySum
} from './InhabitantsTableRow';
import {InhabitantsTable as labels} from './labels';

const propTypes = {
  className: PropTypes.string,
  inhabitants: PropTypes.array,
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onActionInhabitantsChange: PropTypes.func,
  onFetchNextPage: PropTypes.func
};

function InhabitantsTable({
  className,
  inhabitants,
  loading,
  loadingMore,
  onActionInhabitantsChange,
  onFetchNextPage
}) {
  function getPopulationTotalSum(inhabitants_view) {
    const sum = inhabitants_view?.reduce((a, b) => {
      const bSum = getPopulationSum(b.ghosts) || 0;
      return a + bSum;
    }, 0);
    return sum;
  }

  function getAccomodationcapacityTotalSum(inhabitants_view) {
    const sum = inhabitants_view?.reduce((a, b) => {
      const bSum = getAccomodationcapacitySum(b.cities) || 0;
      return a + bSum;
    }, 0);
    return sum;
  }

  function renderTableHead() {
    return (
      <TableHead component='div'>
        <TableRow component='div'>
          <TableCell align='left' component='div' />
          <TableCell align='left' component='div'>
            {labels.header.region}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.ghost}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.unsustainable}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.sustainable}
          </TableCell>
          <TableCell align='right' component='div'>
            {labels.header.population}
          </TableCell>
          <TableCell align='right' component='div' />
          <TableCell align='right' component='div'>
            {labels.header.accommodationcapacity}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  function renderTableFooter() {
    return (
      <TableHead component='div'>
        <TableRow component='div'>
          <TableCell component='div' />
          <TableCell component='div' />
          <TableCell component='div' />
          <TableCell component='div' />
          <TableCell component='div' />
          <TableCell align='right' component='div'>
            <Typography variant='subtitle1'>
              {Formater.numberFormat(getPopulationTotalSum(inhabitants))}
            </Typography>
          </TableCell>
          <TableCell component='div' />
          <TableCell align='right' component='div'>
            <Typography variant='subtitle1'>
              {Formater.numberFormat(
                getAccomodationcapacityTotalSum(inhabitants)
              )}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Paper className={cx(cs.root, className)}>
      <TableFixedInfiniteLoading
        loading={loading}
        loadingMore={loadingMore}
        onFetchNextPage={onFetchNextPage}
      >
        {() => (
          <>
            {renderTableHead()}
            <TableBody className={cs.table} component='div' size='small'>
              {inhabitants.map((region) => (
                <InhabitantsTableRow
                  key={'region__' + region.id}
                  onActionInhabitantsChange={onActionInhabitantsChange}
                  region={region}
                />
              ))}
            </TableBody>
            {renderTableFooter()}
          </>
        )}
      </TableFixedInfiniteLoading>
    </Paper>
  );
}

InhabitantsTable.propTypes = propTypes;
export default InhabitantsTable;
