import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React from 'react';
import Formater from 'utils/Formater';
import {
  QuotientStockRequired,
  QuotientCapacityRequired
} from './GlobalmarketsAttentionMarker';
import cs from './GlobalmarketsTable.module.scss';

const propTypes = {
  economicacc_transf_capacity_demand: PropTypes.array,
  isTableQuoteCapacityVisible: PropTypes.bool,
  isTableQuoteStockVisible: PropTypes.bool
};

function GlobalmarketsTableDetail({
  economicacc_transf_capacity_demand,
  isTableQuoteCapacityVisible,
  isTableQuoteStockVisible
}) {
  return (
    <>
      {economicacc_transf_capacity_demand?.map((etcd) => (
        <TableRow
          key={'entityrowde' + etcd?.economicaccid}
          className={cs.rowLink}
          component='div'
          hover
        >
          <TableCell align='right' className={cs.detailRow} component='div' />
          <TableCell align='right' className={cs.detailRow} component='div' />
          <TableCell align='left' className={cs.detailRow} component='div'>
            {etcd?.economicacc.player.title}
          </TableCell>
          <TableCell align='right' component='div'>
            {Formater.numberFormat(
              etcd?.economicacc?.economicaccpostings[0].balance
            )}
          </TableCell>
          <TableCell align='right' component='div'>
            {isTableQuoteStockVisible ? (
              <small>
                {Formater.numberFormat(
                  QuotientStockRequired(
                    etcd?.economicacc?.economicaccpostings[0].balance,
                    etcd?.numberrequired
                  )
                )}
              </small>
            ) : (
              ''
            )}
          </TableCell>
          <TableCell align='right' component='div'>
            {Formater.numberFormat(etcd?.capacity)}
          </TableCell>
          <TableCell align='right' component='div'>
            {isTableQuoteCapacityVisible ? (
              <small>
                {Formater.numberFormat(
                  QuotientCapacityRequired(etcd?.capacity, etcd?.numberrequired)
                )}
              </small>
            ) : (
              ''
            )}
          </TableCell>
          <TableCell align='right' component='div'>
            {Formater.numberFormat(etcd?.numberrequired)}
          </TableCell>
        </TableRow>
      ))}
    </>
  );
}

GlobalmarketsTableDetail.propTypes = propTypes;
export default GlobalmarketsTableDetail;
