import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import cs from './FormColumn.module.scss';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default function FormColumn({children, className}) {
  return <div className={cx(cs.root, className)}>{children}</div>;
}

FormColumn.propTypes = propTypes;
