import Typography from '@mui/material/Typography';
import gql from 'graphql-tag';
import React, {useState} from 'react';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import usePaginatedQuery from 'hooks/usePaginatedQuery';
import EnvUtils from 'utils/EnvUtils';
import ResearchCardsContent from './ResearchCardsContent';
import ResearchCardsTopBar from './ResearchCardsTopBar';
import labels from './labels';

const query = gql`
  query getResearchCards(
    $where: researchcard_player_bool_exp! = {}
    $limit: Int = 25
    $offset: Int
  ) {
    aggregate: researchcard_player_aggregate(where: $where) {
      researchcardsplayers: aggregate {
        totalCount: count
      }
    }
    researchcardsplayers: researchcard_player(
      order_by: {purchasetime: desc}
      where: $where
    ) {
      id
      purchasegameperiodid
      purchasetime
      player {
        title
      }
      gameperiod {
        name
      }
      entity {
        name
      }
      region {
        name
      }
    }
  }
`;

export default function ResearchCards() {
  const {game} = useGameSelectContext();

  const [filter, setFilter] = useState({
    player: undefined,
    region: undefined
  });

  function onChangeFilter(nextFilter) {
    setFilter((prev) => ({...prev, ...nextFilter}));
  }

  const {
    data: resultResearchCards,
    loading,
    loadingMore,
    onFetchNextPage
  } = usePaginatedQuery(query, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: {
      limit: 50,
      where: {
        player: {gameid: {_eq: game.id}},
        playerid: {_eq: filter.player?.id}
      }
    },
    listName: 'researchcardsplayers'
  });

  return (
    <>
      <Typography gutterBottom variant='h5'>
        {labels.title}
      </Typography>
      <ResearchCardsTopBar filter={filter} onChangeFilter={onChangeFilter} />

      <ResearchCardsContent
        loading={loading}
        loadingMore={loadingMore}
        onFetchNextPage={onFetchNextPage}
        resultResearchCards={resultResearchCards}
      />
    </>
  );
}
