// eslint-disable-next-line import/no-extraneous-dependencies
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React from 'react';
import Formater from 'utils/Formater';
import BuildingToInfraRatioQuality from './BuildingToInfraRatioQuality';
import cs from './BuildingToInfraRatioTable.module.scss';

const propTypes = {
  regions: PropTypes.array
};

export default function BuildingToInfraRatioTable({regions}) {
  function qualityToBe(buildingsTotal, buildingsInfrasructure) {
    return (buildingsTotal - buildingsInfrasructure) / 10;
  }

  return (
    <div className={cs.root}>
      <TableContainer component={Paper}>
        <Table aria-label='Table' size='small'>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Region</b>
              </TableCell>
              <TableCell align='right'>
                <b>Gebäude und Anlagen Total</b>
              </TableCell>
              <TableCell align='right'>
                <b>Verkehrsinfrastruktur</b>
              </TableCell>
              <TableCell align='right'>
                <b>Soll</b>
              </TableCell>
              <TableCell align='right'>
                <b>Zustand</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {regions.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.name}</TableCell>
                <TableCell align='right'>
                  {row.buildings.aggregate.count -
                    row.trafficNoMilitary.aggregate.count}
                </TableCell>
                <TableCell align='right'>
                  {row.trafficNoMilitary.aggregate.count}
                </TableCell>
                <TableCell align='right'>
                  {Formater.numberFormat(
                    qualityToBe(
                      row.buildings.aggregate.count,
                      row.trafficNoMilitary.aggregate.count
                    )
                  )}
                </TableCell>
                <TableCell align='right'>
                  <BuildingToInfraRatioQuality
                    buildingsInfrastructure={
                      row.trafficNoMilitary.aggregate.count
                    }
                    buildingsInfrastructureToBe={qualityToBe(
                      row.buildings.aggregate.count,
                      row.trafficNoMilitary.aggregate.count
                    )}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

BuildingToInfraRatioTable.propTypes = propTypes;
