import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {Link, useRouteMatch} from 'react-router-dom';
import SimpleTable from 'components/SimpleTable/SimpleTable';
import Formater from 'utils/Formater';
import cs from './EconomicaccTable.module.scss';

const propTypes = {
  economicaccs: PropTypes.array,
  selectedId: PropTypes.number
};

export default function EconomicaccTable({economicaccs, selectedId}) {
  const {url} = useRouteMatch();

  function ItemDecoration(id, value, object, field) {
    let nextValue = value;
    if (object) {
      nextValue = object[value];
    } else if (value === 'name' || value === 'balance') {
      nextValue = '';
    }

    if (field === 'balance') {
      object && object[value]
        ? (nextValue = Formater.numberFormat(object[value]))
        : '';
    }

    if (field === 'categoriename') {
      nextValue = object[value];
    }

    return (
      <Link className={cs.a} to={`${url}/${id}`}>
        {nextValue}
      </Link>
    );
  }

  return (
    <div className={cs.root}>
      <SimpleTable
        columns={[
          {
            title: 'SpielerIn',
            object: 'player',
            field: 'title',
            align: 'left'
          },
          {
            title: 'Rolle',
            object: 'player.role',
            field: 'categoriename',
            align: 'left'
          },
          {
            title: 'Entität',
            object: 'entity',
            field: 'name',
            align: 'left'
          },
          {
            title: 'Kontostand',
            object: 'economicaccpostings.0',
            field: 'balance',
            align: 'right'
          }
        ]}
        data={economicaccs}
        itemDecoration={ItemDecoration}
        keyfield='id'
        selectedId={selectedId}
      />
    </div>
  );
}

EconomicaccTable.propTypes = propTypes;
