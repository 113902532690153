import {useMutation} from '@apollo/react-hooks';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import gql from 'graphql-tag';
import React, {useState} from 'react';
import EconomicStockTakingForm from 'components/EconomicStockTakingForm/EconomicStockTakingForm';
import EconomictradeForm from 'components/EconomictradeForm/EconomictradeForm';
import EconomictransferForm from 'components/EconomictransferForm/EconomictransferForm';
import SimpleDialog from 'components/SimpleDialog/SimpleDialog';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import usePaginatedQuery from 'hooks/usePaginatedQuery';
import EnvUtils from 'utils/EnvUtils';
import m from '../../messages/de.json';
import EconomictranslogsContent from './EconomictranslogsContent';
import EconomictranslogsTopBar from './EconomictranslogsTopBar';
import labels from './labels';

export const query = gql`
  query getEcononomictranslog(
    $where: economictranslog_bool_exp! = {}
    $limit: Int = 250
    $offset: Int
  ) {
    aggregate: economictranslog_aggregate(where: $where) {
      economictranslogs: aggregate {
        totalCount: count
      }
    }
    economictranslogs: economictranslog(
      order_by: {gameperiodid: desc, id: desc}
      where: $where
      limit: $limit
      offset: $offset
    ) {
      id
      status
      toeconomicaccid
      transactiontype
      amountentity
      amountmoney
      fromeconomicaccid
      transactioncode
      txt
      gameperiod {
        name
      }
      toeconomicacc {
        player {
          title
        }
        entity {
          name
        }
      }
      fromeconomicacc {
        player {
          title
        }
        entity {
          name
        }
      }
    }
  }
`;

const actionEconomicMoneyTransferMutation = gql`
  mutation actionEconomicMoneyTransfer(
    $gameperiodid: Int!
    $fromeconomicaccid: Int
    $toeconomicaccid: Int!
    $amountmoney: Int!
    $txt: String!
  ) {
    actionEconomicMoneyTransfer(
      gameperiodid: $gameperiodid
      fromeconomicaccid: $fromeconomicaccid
      toeconomicaccid: $toeconomicaccid
      amountmoney: $amountmoney
      txt: $txt
    ) {
      code
      message
    }
  }
`;

const actionEconomictradeMuation = gql`
  mutation actionEconomicTradeCreation(
    $gameperiodid: Int!
    $fromeconomicaccid: Int!
    $toeconomicaccid: Int!
    $amountmoney: Int!
    $entityid: Int!
    $amountentity: Int!
    $txt: String!
  ) {
    actionEconomicTradeCreation(
      gameperiodid: $gameperiodid
      fromeconomicaccid: $fromeconomicaccid
      toeconomicaccid: $toeconomicaccid
      amountmoney: $amountmoney
      entityid: $entityid
      amountentity: $amountentity
      txt: $txt
    ) {
      code
      message
    }
  }
`;

const actionEconomicStockTakingMuation = gql`
  mutation actionEconomicStockTaking(
    $playerid: Int!
    $entityid: Int!
    $amountentity: Int!
    $txt: String!
  ) {
    actionEconomicStockTaking(
      playerid: $playerid
      entityid: $entityid
      amountentity: $amountentity
      txt: $txt
    ) {
      code
      message
    }
  }
`;

export default function Economictranslogs() {
  const {game} = useGameSelectContext();

  const [filter, setFilter] = useState({
    transactiontype: undefined,
    landscapecategorie: undefined,
    status: undefined
  });

  function onChangeFilter(nextFilter) {
    setFilter((prev) => ({...prev, ...nextFilter}));
  }

  const {
    data: resultEconomictranslogs,
    loading,
    loadingMore,
    onFetchNextPage,
    refetch
  } = usePaginatedQuery(query, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: {
      where: {
        gameperiod: {
          gameid: {_eq: game.id},
          id: {_eq: filter.gameperiod?.id}
        },
        transactiontype: {_eq: filter.transactiontype}
      }
    },
    listName: 'economictranslogs'
  });

  // MoneyTranfer
  const [
    isEconomicMoneyTransferDialogOpen,
    setIsEconomicMoneyTransferDialogOpen
  ] = useState(false);

  const [onEconomicMoneyTransferMutation] = useMutation(
    actionEconomicMoneyTransferMutation
  );

  async function onEconomicMoneyTransfer(dEconomictransfer) {
    const res = onEconomicMoneyTransferMutation({
      variables: dEconomictransfer
    });
    if (res) {
      refetch();
      return res;
    }
  }

  // Trade
  const [isEconomictradeDialogOpen, setIsEconomictradeDialogOpen] =
    useState(false);

  const [onEconomictradeMutation] = useMutation(actionEconomictradeMuation);

  async function onEconomictradeTransfer(dEconomictransfer) {
    const res = onEconomictradeMutation({
      variables: dEconomictransfer
    });
    if (res) {
      refetch();
      return res;
    }
  }

  // Stock-Tacking
  const [isEconomicStockTakingDialogOpen, setIsEconomicStockTakingDialogOpen] =
    useState(false);

  const [onEconomicStockTakingMutation] = useMutation(
    actionEconomicStockTakingMuation
  );

  async function onEconomicStockTaking(dEconomictransfer) {
    const res = onEconomicStockTakingMutation({
      variables: dEconomictransfer
    });
    if (res) {
      refetch();
      return res;
    }
  }
  return (
    <>
      <Typography gutterBottom variant='h5'>
        {labels.title}
      </Typography>
      <EconomictranslogsTopBar
        filter={filter}
        onChangeFilter={onChangeFilter}
      />
      <br />
      <Button
        color='primary'
        onClick={() => setIsEconomictradeDialogOpen(true)}
        startIcon={<ShoppingBasketIcon />}
        variant='contained'
      >
        Warenhandel
      </Button>
      &nbsp;
      <Button
        color='primary'
        onClick={() => setIsEconomicMoneyTransferDialogOpen(true)}
        startIcon={<AddIcon />}
        variant='contained'
      >
        Geld Überweisung
      </Button>
      &nbsp;
      <Button
        color='primary'
        onClick={() => setIsEconomicStockTakingDialogOpen(true)}
        startIcon={<AddIcon />}
        variant='contained'
      >
        Inventurbuchung
      </Button>
      &nbsp;
      <IconButton
        color='primary'
        onClick={() => refetch(true)}
        size='large'
        variant='contained'
      >
        <RefreshIcon />
      </IconButton>
      <EconomictranslogsContent
        loading={loading}
        loadingMore={loadingMore}
        onFetchNextPage={onFetchNextPage}
        resultEconomictranslogs={resultEconomictranslogs}
      />
      <SimpleDialog
        description={m.EconomicMoneyTransferDialog.description}
        isOpen={isEconomicMoneyTransferDialogOpen}
        onClose={() => setIsEconomicMoneyTransferDialogOpen(false)}
        title={m.EconomicMoneyTransferDialog.title}
      >
        <EconomictransferForm
          economictranslog={undefined}
          onSubmit={onEconomicMoneyTransfer}
        />
      </SimpleDialog>
      <SimpleDialog
        description={m.EconomictradeDialog.description}
        isOpen={isEconomictradeDialogOpen}
        onClose={() => setIsEconomictradeDialogOpen(false)}
        title={m.EconomictradeDialog.title}
      >
        <EconomictradeForm
          economictranslog={undefined}
          onSubmit={onEconomictradeTransfer}
        />
      </SimpleDialog>
      <SimpleDialog
        description={m.EconomicStockTakingDialog.description}
        isOpen={isEconomicStockTakingDialogOpen}
        onClose={() => setIsEconomicStockTakingDialogOpen(false)}
        title={m.EconomicStockTakingDialog.title}
      >
        <EconomicStockTakingForm
          economictranslog={undefined}
          onSubmit={onEconomicStockTaking}
        />
      </SimpleDialog>
    </>
  );
}
