import {FormControl, MenuItem, Select} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import LandscapecategoryChip from 'components/LandscapecategoryChip/LandscapecategoryChip';
import cs from './LandscapecategoriesSelectContent.module.scss';

const propTypes = {
  landscapecategories: PropTypes.array,
  onChangeLandscapecategorie: PropTypes.func,
  selectedLandscapecategorie: PropTypes.object
};

export default function LandscapecategoriesSelectContent({
  landscapecategories,
  onChangeLandscapecategorie,
  selectedLandscapecategorie
}) {
  function onChange(e) {
    onChangeLandscapecategorie(
      e.target.value === '' ? undefined : e.target.value
    );
  }

  return (
    <FormControl fullWidth>
      <Select
        autoWidth
        className={cs.select}
        displayEmpty
        onChange={onChange}
        value={selectedLandscapecategorie || ''}
      >
        <MenuItem value=''>alle</MenuItem>
        {landscapecategories.map((landscapecategory) => (
          <MenuItem key={landscapecategory.categorie} value={landscapecategory}>
            <LandscapecategoryChip value={landscapecategory.categorie} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

LandscapecategoriesSelectContent.propTypes = propTypes;
