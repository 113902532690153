import gql from 'graphql-tag';

const GET_GAMEPERIOD = gql`
  query getGameperiod($id: Int!) {
    gameperiod: gameperiod_by_pk(id: $id) {
      id
      gameid
      name
      closed
      taskstatus
      erda
    }
  }
`;

const UPDATE_GAMEPERIOD = gql`
  mutation updateGameperiod(
    $id: Int!
    $gameid: Int
    $name: String
    $closed: Boolean
  ) {
    update_gameperiod_by_pk(
      pk_columns: {id: $id}
      _set: {gameid: $gameid, name: $name, closed: $closed}
    ) {
      id
    }
  }
`;

export {GET_GAMEPERIOD, UPDATE_GAMEPERIOD};
