import gql from 'graphql-tag';

const GET_ECONOMICACC = gql`
  query getEconomicacc($id: Int!) {
    economicacc: economicacc_by_pk(id: $id) {
      id
      accounttype
      entity {
        name
      }
      player {
        title
      }
    }
  }
`;

export default GET_ECONOMICACC;
