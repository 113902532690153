import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import cs from './TextArea.module.scss';

const propTypes = {
  autoComplete: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  readonly: PropTypes.bool,
  value: PropTypes.string
};

export default function TextArea({
  autoComplete,
  className,
  name,
  onChange,
  readonly = false,
  value
}) {
  return (
    <textarea
      autoComplete={autoComplete ? true : 'none'}
      className={cx(className, cs.input)}
      cols='50'
      id={name}
      name={name}
      onChange={onChange}
      readOnly={readonly}
      rows='4'
      type='text'
      value={value}
    />
  );
}

TextArea.propTypes = propTypes;
