import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContentInline from 'components/AsyncContent/AsyncContentInline';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import GameperiodsSelectContent from './GameperiodsSelectContent';

const propTypes = {
  className: PropTypes.string,
  selectedGameperiod: PropTypes.object,
  onChangeGameperiod: PropTypes.func
};

const query = gql`
  query getGameperiods($gameid: Int!) {
    page: gameperiod_aggregate(limit: 10000) {
      aggregate {
        totalCount: count
      }
    }
    gameperiods: gameperiod(
      order_by: {id: desc}
      where: {gameid: {_eq: $gameid}}
    ) {
      id
      gameid
      period
      name
    }
  }
`;

export default function GameperiodsSelect({
  className,
  onChangeGameperiod,
  selectedGameperiod
}) {
  const {game} = useGameSelectContext();
  const {data, error, loading} = useQuery(query, {
    variables: {gameid: game.id}
  });

  return (
    <div className={className}>
      <AsyncContentInline error={error} loading={loading}>
        {() => (
          <GameperiodsSelectContent
            gameperiods={data.gameperiods}
            onChangeGameperiod={onChangeGameperiod}
            selectedGameperiod={selectedGameperiod}
          />
        )}
      </AsyncContentInline>
    </div>
  );
}

GameperiodsSelect.propTypes = propTypes;
