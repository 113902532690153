import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import cs from './SidebarDrawer.module.scss';

const propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

// sidebar drawer needs to be abouve everything even the Top-Bar. Therefor the z-index
// needs to be defined separatly (this Drawer property needs to be removed so that it works: style={{position: 'initial'}})
// 5.12.2023: onClose is optional: as for tabs in the sidebar it should not be definded because it is already defined by the Atab
export default function SidebarDrawer({children, onClose, open}) {
  return (
    <Drawer
      anchor='right'
      className={cs.root}
      disableScrollLock
      hideBackdrop={false}
      onClose={onClose}
      open={open}
      variant='persistent'
    >
      {onClose && (
        <div className={cs.close}>
          <IconButton
            aria-controls='menu-appbar'
            aria-haspopup='true'
            aria-label='close'
            color='inherit'
            onClick={onClose}
            size='large'
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}

      <div className={cs.content}>{children}</div>
    </Drawer>
  );
}

SidebarDrawer.propTypes = propTypes;
