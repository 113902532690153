export default {
  getApiUrl() {
    return process.env.REACT_APP_APIURL;
  },

  getPollInterval() {
    return parseInt(process.env.REACT_APP_POLLINTERVAL);
  },

  getEnvironmentName() {
    return process.env.REACT_APP_ENVIRONMENT_NAME;
  },

  getUiplayUrl() {
    return process.env.REACT_APP_UIPLAYURL;
  }
};
