import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useSnackbar} from 'notistack';
import * as React from 'react';

function Copyright(props) {
  return (
    <Typography
      align='center'
      color='text.secondary'
      variant='body2'
      {...props}
    >
      <Link color='inherit' href='https://weitblick-gmbh.org'>
        Weitblick GmbH
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
// this is based on the material login templates: https://github.com/mui/material-ui/blob/v5.13.2/docs/data/material/getting-started/templates/sign-in/SignIn.js
// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function LoginForm({onLogin, onLoginFinished}) {
  const {enqueueSnackbar} = useSnackbar();

  // eslint-disable-next-line func-style
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const credentials = {
      email: data.get('email'),
      pwd: data.get('password')
    };
    return onLogin(credentials)
      .then((res) => {
        if (res?.data?.actionAdminLogin?.isAuthenticated) {
          enqueueSnackbar(`Du hast dich erfolgreich angemeldet.`, {
            variant: 'success'
          });
          onLoginFinished(res);
        } else {
          enqueueSnackbar(
            `Anmeldung nicht erfolgreich, bitte überprüfe den Benutzernamen und das Passwort!`,
            {
              variant: 'warning'
            }
          );
        }
      })
      .catch(() => {
        enqueueSnackbar(`We could not login you - try later.`, {
          variant: 'error',
          persist: true
        });
      });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Anmelden zum Weltklimaspiel
          </Typography>
          <Box component='form' noValidate onSubmit={handleSubmit} sx={{mt: 1}}>
            <TextField
              autoComplete='email'
              autoFocus
              fullWidth
              id='email'
              label='Email Address'
              margin='normal'
              name='email'
              required
            />
            <TextField
              autoComplete='current-password'
              fullWidth
              id='password'
              label='Passwort'
              margin='normal'
              name='password'
              required
              type='password'
            />
            <Button
              fullWidth
              sx={{mt: 3, mb: 2}}
              type='submit'
              variant='contained'
            >
              Weiter
            </Button>
            <Grid container>
              <Grid item xs>
                <Link
                  href='#'
                  onClick={() => {
                    alert('wird bald umgesetzt');
                  }}
                  variant='body2'
                >
                  Passwort vergessen?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{mt: 8, mb: 4}} />
      </Container>
    </ThemeProvider>
  );
}
