import {useQuery, useMutation} from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import EntitylinkResearchContent from 'containers/EntitylinkResearch/EntitylinkResearchContent';
import {
  GET_ENTITYLINK,
  UPDATE_ENTITYLINK,
  INSERT_ENTITYLINK
} from 'containers/EntitylinkResearch/EntitylinkResearchSchemas';

const propTypes = {
  entityid: PropTypes.number,
  id: PropTypes.number,
  onClose: PropTypes.func,
  typeFilter: PropTypes.array
};

export default function EntitylinkResearch({
  entityid,
  id,
  onClose,
  typeFilter
}) {
  id = id === undefined ? 0 : id;

  const {
    data: resultEntitylinkResearch,
    error,
    loading
  } = useQuery(GET_ENTITYLINK, {
    variables: {id}
  });

  const [updateEntitylinkResearch] = useMutation(UPDATE_ENTITYLINK);
  const [insertEntitylinkResearch] = useMutation(INSERT_ENTITYLINK);

  async function onSubmit(dEntitylinkResearch) {
    let res;

    const dEntitylinkResearchNext = {...dEntitylinkResearch};
    delete dEntitylinkResearchNext.entity;
    dEntitylinkResearchNext.numbersustainable = 0;
    dEntitylinkResearchNext.numberunsustainable = 0;

    if (resultEntitylinkResearch.entitylink !== null) {
      res = updateEntitylinkResearch({variables: dEntitylinkResearchNext});
    } else {
      res = insertEntitylinkResearch({variables: dEntitylinkResearchNext});
    }

    return res;
  }

  return (
    <AsyncContent error={error} loading={loading}>
      <EntitylinkResearchContent
        entityid={entityid}
        id={id}
        onClose={onClose}
        onSubmit={onSubmit}
        resultEntitylinkResearch={resultEntitylinkResearch}
        typeFilter={typeFilter}
      />
    </AsyncContent>
  );
}
EntitylinkResearch.propTypes = propTypes;
