import PropTypes from 'prop-types';
import React from 'react';
import LoginForm from 'components/LoginForm';

const propTypes = {
  onLogin: PropTypes.object,
  onLoginFinished: PropTypes.object
};

export default function LoginContent({onLogin, onLoginFinished}) {
  return <LoginForm onLogin={onLogin} onLoginFinished={onLoginFinished} />;
}

LoginContent.propTypes = propTypes;
