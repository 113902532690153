// eslint-disable-next-line import/no-extraneous-dependencies
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React from 'react';
import cs from './TrashPerPlayerTable.module.scss';

const propTypes = {
  entitylinksByLinktoentityid: PropTypes.array
};

export default function TrashPerPlayerDemandTable({
  entitylinksByLinktoentityid
}) {
  return (
    <div className={cs.root}>
      <TableContainer component={Paper}>
        <Table aria-label='Table' size='small'>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Anlagen mit Bedarf</b>
              </TableCell>
              <TableCell align='right'>
                <b>Anzahl Vorhanden Entwicklungsstufe I</b>
              </TableCell>
              <TableCell align='right'>
                <b>Anzahl Vorhanden Entwicklungsstufe II</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entitylinksByLinktoentityid.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.entity.name}</TableCell>
                <TableCell align='right'>
                  {row.entity.demandSustainable.aggregate.count} (
                  {row.numbersustainable *
                    row.entity.demandSustainable.aggregate.count}
                  *)
                </TableCell>
                <TableCell align='right'>
                  {row.entity.demandUnSustainable.aggregate.count} (
                  {row.numberunsustainable *
                    row.entity.demandUnSustainable.aggregate.count}
                  *)
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <small>*) Bedarf pro Periode</small>
    </div>
  );
}

TrashPerPlayerDemandTable.propTypes = propTypes;
