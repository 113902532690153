import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {Link, useRouteMatch} from 'react-router-dom';
import cs from 'components/EntityTable/EntityTable.module.scss';
import SimpleTable from 'components/SimpleTable/SimpleTable';
import DateUtils from 'utils/DateUtils';
import m from '../../messages/de.json';
import GameTableMenu from './GameTableMenu';

const propTypes = {
  games: PropTypes.array,
  onDeleteGame: PropTypes.func.isRequired
};

export default function GameTable({games, onDeleteGame}) {
  const {url} = useRouteMatch();

  function ItemDecoration(id, value, object, field, row) {
    let nextValue = value;
    if (object) {
      nextValue = object[value];
    } else if (value === 'name') {
      nextValue = '';
    }

    if (field === 'gameperiods_aggregate') {
      nextValue = row?.gameperiods_aggregate?.aggregate?.count;
    }

    if (field === 'executionfrom') {
      nextValue = DateUtils.formatDate(value);
    }

    if (field === 'execution') {
      const tr = m.ExecutionDropDown.status.filter(
        (item) => item?.id === value
      );

      nextValue = tr[0]?.name;
    }

    return (
      <Link className={cs.a} to={`${url}/${id}`}>
        {nextValue}
      </Link>
    );
  }

  return (
    <div className={cs.root}>
      <SimpleTable
        columns={[
          {
            title: 'Spielstart',
            field: 'executionfrom',
            align: 'left'
          },
          {
            title: 'Titel',
            field: 'title',
            align: 'left'
          },
          {
            title: 'Organisation',
            object: 'organisation',
            field: 'name',
            align: 'left'
          },
          {
            title: 'SpielerInnen',
            field: 'numberofplayers',
            align: 'right'
          },
          {
            title: 'Perioden',
            object: 'gameperiod',
            field: 'gameperiods_aggregate',
            align: 'right'
          },
          {
            title: 'Spielstatus',
            field: 'execution',
            align: 'left'
          },
          {
            title: 'Task Status',
            field: 'taskstatus',
            align: 'left'
          }
        ]}
        data={games}
        functionMenu={({row}) => (
          <GameTableMenu game={row} onDeleteGame={onDeleteGame} />
        )}
        itemDecoration={ItemDecoration}
        keyfield='id'
      />
    </div>
  );
}

GameTable.propTypes = propTypes;
