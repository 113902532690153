import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Button from 'components/Button/Button';
import Form from 'components/Form/Form';
import FormColumn from 'components/Form/FormColumn';
import FormItem from 'components/Form/FormItem';
import EntityDropDown from 'containers/Entitylink/EntityDropDown';
import TypeDropDown from 'containers/Entitylink/TypeDropDown';
import Authentication from 'utils/Authentication';
import m from '../../messages/de.json';
import cs from './EntitylinkResearchForm.module.scss';

const validate = (values) => {
  const errors = {};

  if (!values.type) {
    errors.type = 'Die Aktivierung der Forschung erlaubt - ist ein Pflichtfeld';
  }

  if (!values.linktoentityid) {
    errors.linktoentityid = 'für die Entität - ist ein Pflichtfeld';
  }

  return errors;
};

const initialValues = {
  entityid: 0,
  numbersustainable: ''
};

const propTypes = {
  entitylink: PropTypes.object,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  typeFilter: PropTypes.array
};
export default function EntitylinkResearchForm({
  entityid,
  entitylink,
  isNew = false,
  onClose,
  onSubmit,
  typeFilter
}) {
  // if (isNew) {
  initialValues.entityid = entityid;
  // }

  const formik = useFormik({
    initialValues: entitylink || initialValues,
    validate,
    onSubmit: onSubmitForm,
    enableReinitialize: true
  });

  const {enqueueSnackbar} = useSnackbar();
  async function onSubmitForm(values) {
    return onSubmit(values)
      .then(() => {
        enqueueSnackbar(m.dataHandlingPromise.fulfilled, {variant: 'success'});
        onClose();
      })
      .catch((e) =>
        enqueueSnackbar(`${m.dataHandlingPromise.rejected} ${e?.message}`, {
          variant: 'error',
          persist: true
        })
      );
  }

  return (
    <div className={cs.root}>
      <Form
        actions={
          <>
            <Button onClick={onClose} variant='outlined'>
              Abbrechen
            </Button>
            <Button
              color='primary'
              disabled={
                formik.isSubmitting ||
                !Authentication.hasUserRole('uiadmin_admin')
              }
              type='submit'
              variant='contained'
            >
              {m.dataHandlingForm.save}
            </Button>
          </>
        }
        onSubmit={formik.handleSubmit}
      >
        <FormColumn>
          <FormItem
            error={formik.errors.type}
            label='Effek der Entwicklungskarte'
            name='type'
            onChange={formik.handleChange}
            value={formik.values.type}
          >
            <TypeDropDown typeFilter={typeFilter} />
          </FormItem>
          <FormItem
            error={formik.errors.linktoentityid}
            label='für die Entität'
            name='linktoentityid'
            onChange={formik.handleChange}
            value={formik.values.linktoentityid}
          >
            <EntityDropDown
              filterEntitytype={[
                'energy',
                'company',
                'miningarea',
                'landuse',
                'traffic',
                'ghost',
                'city'
              ]}
            />
          </FormItem>
        </FormColumn>
      </Form>
    </div>
  );
}
EntitylinkResearchForm.propTypes = propTypes;
