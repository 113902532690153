import {FormControl, MenuItem, Select} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import cs from './GameperiodsSelectContent.module.scss';

const propTypes = {
  gameperiods: PropTypes.array,
  onChangeGameperiod: PropTypes.func,
  selectedGameperiod: PropTypes.object
};

export default function GameperiodsSelectContent({
  gameperiods,
  onChangeGameperiod,
  selectedGameperiod
}) {
  function onChange(e) {
    onChangeGameperiod(e.target.value === '' ? undefined : e.target.value);
  }

  return (
    <FormControl fullWidth>
      <Select
        autoWidth
        className={cs.select}
        displayEmpty
        onChange={onChange}
        value={selectedGameperiod || ''}
      >
        <MenuItem value=''>alle</MenuItem>
        {gameperiods.map((gameperiod) => (
          <MenuItem key={gameperiod.id} value={gameperiod}>
            {gameperiod.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

GameperiodsSelectContent.propTypes = propTypes;
