import {useQuery} from '@apollo/react-hooks';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import GET_PLAYERS from 'containers/Players/PlayersSchemas';
import EnvUtils from 'utils/EnvUtils';
import PlayersContent from './PlayersContent';

export default function Players() {
  const {game} = useGameSelectContext();

  const {
    data: resultPlayers,
    error,
    loading
  } = useQuery(GET_PLAYERS, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: {gameid: game.id}
  });

  return (
    <AsyncContent error={error} loading={loading}>
      <PlayersContent resultPlayers={resultPlayers} />
    </AsyncContent>
  );
}
