import CloseIcon from '@mui/icons-material/Close';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import {Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import TableNumberOfResults from 'components/TableNumberOfResult/TableNumberOfResult';
import cs from './GlobalmarketsContent.module.scss';
import GlobalmarketsTable from './GlobalmarketsTable';
import {GlobalmarketsTable as labels} from './labels';

const propTypes = {
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onFetchNextPage: PropTypes.func,
  resultGlobalmarkets: PropTypes.object
};

export default function GlobalmarketsContent({
  loading,
  loadingMore,
  onFetchNextPage,
  resultGlobalmarkets
}) {
  return (
    <div className={cs.root}>
      <GlobalmarketsTable
        globalmarkets={resultGlobalmarkets?.globalmarkets}
        loading={loading}
        loadingMore={loadingMore}
        onFetchNextPage={onFetchNextPage}
      />
      <TableNumberOfResults
        count={resultGlobalmarkets?.aggregate?.globalmarkets?.totalCount}
      />
      <br />
      <br />
      <Typography gutterBottom variant='h6'>
        {labels.footer.title}
      </Typography>
      <Typography gutterBottom variant='normal'>
        {labels.footer.quotientStock}
      </Typography>
      <br />
      <br />
      <Typography gutterBottom variant='normal'>
        {labels.footer.quotientCapacity}
      </Typography>
      <br />
      <br />
      <Typography gutterBottom variant='normal'>
        <CloseIcon /> {labels.footer.attentionmarkerLow}
      </Typography>
      <br />
      <br />
      <Typography gutterBottom variant='normal'>
        <PriorityHighIcon /> {labels.footer.attentionmarkerHigh}
      </Typography>
    </div>
  );
}

GlobalmarketsContent.propTypes = propTypes;
