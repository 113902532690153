import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import {useRouteMatch, useHistory, Link} from 'react-router-dom';
import SidebarDrawer from 'components/SidebarDrawer/SidebarDrawer';
import TableNumberOfResults from 'components/TableNumberOfResult/TableNumberOfResult';
import Landscaperegion from 'containers/Landscaperegion/Landscaperegion';
import cs from './LandscaperegionsContent.module.scss';
import LandscaperegionsTable from './LandscaperegionsTable';
import {LandscaperegionsContent as labels} from './labels';

const propTypes = {
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onFetchNextPage: PropTypes.func,
  resultLandscaperegions: PropTypes.object
};

export default function LandscaperegionsContent({
  loading,
  loadingMore,
  onFetchNextPage,
  resultLandscaperegions
}) {
  const history = useHistory();
  const {params} = useRouteMatch('/landscaperegions/:id?');

  function onClose() {
    history.push('/landscaperegions');
  }
  return (
    <div className={cs.root}>
      <Button
        className={cs.protocols}
        color='primary'
        component={Link}
        startIcon={<LibraryBooksIcon />}
        to='/protocols/landscaperegion'
        variant='contained'
      >
        {labels.protocol}
      </Button>

      <LandscaperegionsTable
        className={cs.table}
        landscaperegions={resultLandscaperegions?.landscaperegions}
        loading={loading}
        loadingMore={loadingMore}
        onFetchNextPage={onFetchNextPage}
        selectedId={params.id}
      />

      <TableNumberOfResults
        count={resultLandscaperegions?.aggregate?.landscaperegions?.totalCount}
      />

      <SidebarDrawer onClose={onClose} open={params.id != null}>
        <Landscaperegion id={params.id} onClose={onClose} />
      </SidebarDrawer>
    </div>
  );
}

LandscaperegionsContent.propTypes = propTypes;
