import {useQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import UserProfileContent from './UserProfileContent';

const actionAdminPwdChangeMutation = gql`
  mutation actionAdminPwdChange($oldPwd: String!, $newPwd: String!) {
    actionAdminPwdChange(oldPwd: $oldPwd, newPwd: $newPwd) {
      code
      message
    }
  }
`;

const query = gql`
  query userlist_by_pk($id: Int!) {
    userlist: userlist_by_pk(id: $id) {
      longname
      email
    }
  }
`;

export default function UserProfile() {
  const userid = localStorage.getItem('userid');

  const {
    data: resultUserlist,
    error,
    loading
  } = useQuery(query, {
    variables: {id: userid}
  });

  const [onAdminPwdChangeMutation, {errorPwdChange, loadingPwdChange}] =
    useMutation(actionAdminPwdChangeMutation);

  async function onAdminPwdChange(dUserProfile) {
    return onAdminPwdChangeMutation({variables: dUserProfile});
  }

  console.log('resultUserlist', resultUserlist);

  return (
    <>
      <AsyncContent
        error={error || errorPwdChange}
        loading={loading || loadingPwdChange}
      >
        <UserProfileContent
          onAdminPwdChange={onAdminPwdChange}
          resultUserlist={resultUserlist}
        />
      </AsyncContent>
    </>
  );
}
