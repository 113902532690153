import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import TextInput from 'components/TextInput/TextInput';
import usePromiseState, {PromiseState} from 'hooks/usePromiseState';
import cs from './UserlistPwdSetDialog.module.scss';
import labels from './labels';

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.node
};

export default function UserlistPwdSetDialog({
  isOpen,
  onConfirm,
  onClose,
  title = labels.title
}) {
  const [confirmPromise, setConfirmPromise] = usePromiseState();
  const [newPwd, setNewPwd] = useState('');

  function onClickConfirm() {
    setConfirmPromise(onConfirm(newPwd));
  }

  useEffect(() => {
    if (confirmPromise.state === PromiseState.FULFILLED) {
      onClose();
    }
  }, [onClose, confirmPromise]);

  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <DialogContentText>{labels.description}</DialogContentText>
        {confirmPromise.state === PromiseState.REJECTED && (
          <Typography className={cs.error} variant='body2'>
            {labels.error}
          </Typography>
        )}
        <TextInput
          name='newPwd'
          onChange={(e) => {
            setNewPwd(e.target.value);
          }}
          value={newPwd}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{labels.cancel}</Button>
        <Button
          key='confirm'
          className={cs.delete}
          color='primary'
          disabled={
            newPwd.length < 7 || confirmPromise.state === PromiseState.PENDING
          }
          onClick={onClickConfirm}
        >
          {labels.delete}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UserlistPwdSetDialog.propTypes = propTypes;
