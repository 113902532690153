import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import AdminPwdChangeForm from 'components/AdminPwdChangeForm';

const propTypes = {
  onAdminPwdChange: PropTypes.func,
  resultUserlist: PropTypes.object
};

export default function UserProfileContent({onAdminPwdChange, resultUserlist}) {
  return (
    <>
      <Typography gutterBottom variant='h5'>
        Benutzer Profil
      </Typography>
      {resultUserlist?.userlist?.longname}
      <br />
      {resultUserlist?.userlist?.email}
      <br />
      <br />
      <AdminPwdChangeForm onAdminPwdChange={onAdminPwdChange} />
    </>
  );
}

UserProfileContent.propTypes = propTypes;
