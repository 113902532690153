import gql from 'graphql-tag';

const GET_TAXLAWS = gql`
  query getTaxlaw($gameperiodid: Int!) {
    page: taxlaw_aggregate(
      where: {gameperiodid: {_eq: $gameperiodid}}
      limit: 10000
    ) {
      aggregate {
        totalCount: count
      }
    }
    taxlaws: taxlaw(
      order_by: {id: desc}
      where: {gameperiodid: {_eq: $gameperiodid}}
    ) {
      id
      player {
        title
      }
      transformtax_government_quota
      transformtax_labour_quota
      headquarter_salestax_ratio
    }
  }
`;

export default GET_TAXLAWS;
