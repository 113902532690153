import Typography from '@mui/material/Typography';
import gql from 'graphql-tag';
import React, {useState} from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import landscaperegionFragment from 'containers/Landscaperegion/landscaperegionFragment';
import usePaginatedQuery from 'hooks/usePaginatedQuery';
import EnvUtils from 'utils/EnvUtils';
import LandscaperegionsContent from './LandscaperegionsContent';
import LandscaperegionsTopBar from './LandscaperegionsTopBar';
import labels from './labels';

const query = gql`
  query landscape_region(
    $where: landscape_region_bool_exp! = {}
    $limit: Int = 25
    $offset: Int
  ) {
    aggregate: landscape_region_aggregate(where: $where) {
      landscaperegions: aggregate {
        totalCount: count
      }
    }
    landscaperegions: landscape_region(
      order_by: [{gameperiod: {id: desc}}, {landscape: {id: asc}}]
      where: $where
      limit: $limit
      offset: $offset
    ) {
      ...Landscaperegion_landscape_region
    }
  }
  ${landscaperegionFragment}
`;

export default function Landscaperegions() {
  const {game} = useGameSelectContext();

  const [filter, setFilter] = useState({
    region: undefined,
    landscapecategorie: undefined,
    status: undefined
  });

  function onChangeFilter(nextFilter) {
    setFilter((prev) => ({...prev, ...nextFilter}));
  }

  const {
    data: resultLandscaperegions,
    error,
    loading,
    loadingMore,
    onFetchNextPage
  } = usePaginatedQuery(query, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: {
      where: {
        _and: [
          {gameperiod: {gameid: {_eq: game.id}}},
          {gameperiod: {closed: {_eq: false}}}
        ],
        region: {
          id: {_eq: filter.region?.id}
        },
        landscape: {
          landscapetype: {
            categorie: {_eq: filter.landscapecategorie?.categorie}
          }
        },
        status: {_eq: filter.status}
      }
    },
    listName: 'landscaperegions'
  });

  return (
    <>
      <Typography gutterBottom variant='h5'>
        {labels.title}
      </Typography>
      <LandscaperegionsTopBar filter={filter} onChangeFilter={onChangeFilter} />

      <AsyncContent error={error} loading={loading}>
        <LandscaperegionsContent
          loading={loading}
          loadingMore={loadingMore}
          onFetchNextPage={onFetchNextPage}
          resultLandscaperegions={resultLandscaperegions}
        />
      </AsyncContent>
    </>
  );
}
