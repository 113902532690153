import {useQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import {GET_ENTITYLINKS} from 'containers/Entitylinks/EntitylinksSchemas';
import EnvUtils from 'utils/EnvUtils';
import EntitylinksContent from './EntitylinksContent';

const propTypes = {
  entity: PropTypes.object,
  id: PropTypes.number
};

export default function Entitylinks({entity, id}) {
  const {
    data: resultEntitylinks,
    error,
    loading,
    refetch
  } = useQuery(GET_ENTITYLINKS, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: {id}
  });

  const UPDATE_ENTITYLINK = gql`
    mutation updateEntitylink($id: Int!, $deleted: Boolean) {
      update_entitylink_by_pk(
        pk_columns: {id: $id}
        _set: {deleted: $deleted}
      ) {
        id
      }
    }
  `;
  const [updateEntitylink, {errorDel, loadingDel}] = useMutation(
    UPDATE_ENTITYLINK,
    {
      ignoreResults: true
    }
  );

  async function onDeleteEntitylink(entitylink) {
    const dEntitylink = {id: entitylink.id};
    updateEntitylink({variables: dEntitylink})
      .then(() => {
        console.log('record deleted');
        refetch();
      })
      .catch((res) => {
        const errors = res.graphQLErrors.map((error) => error.message);
        console.log(errors);
      });
  }

  return (
    <AsyncContent error={error} loading={loading}>
      <EntitylinksContent
        entity={entity}
        id={id}
        onDeleteEntitylink={onDeleteEntitylink}
        refetch={refetch}
        resultEntitylinks={resultEntitylinks}
      />
    </AsyncContent>
  );
}

Entitylinks.propTypes = propTypes;
