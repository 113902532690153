import {useQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import SurveyContent from 'containers/Survey/SurveyContent';

const propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func
};

const query = gql`
  query feedbacktool_survey_by_pk($id: Int!) {
    survey: feedbacktool_survey_by_pk(id: $id) {
      id
      questionnaire {
        name
      }
      tsstart
      status
      tsend
      gameid
      game {
        title
        organisation {
          name
        }
      }
    }
  }
`;

const querySurveyGetAnswers = gql`
  mutation actionSurveyGetAnswers($surveyid: Int!) {
    actionSurveyGetAnswers(surveyid: $surveyid) {
      code
      data
    }
  }
`;

export default function Survey({id, onClose}) {
  const {
    data: resultSurvey,
    error,
    loading
  } = useQuery(query, {
    variables: {id}
  });

  const [onSurveyGetAnswersMutation] = useMutation(querySurveyGetAnswers);

  async function onSurveyAnswers(surveyid) {
    return onSurveyGetAnswersMutation({
      variables: {surveyid}
    });
  }

  return (
    <AsyncContent error={error} loading={loading}>
      <SurveyContent
        onCancel={onClose}
        onSurveyAnswers={onSurveyAnswers}
        resultSurvey={resultSurvey}
      />
    </AsyncContent>
  );
}
Survey.propTypes = propTypes;
