import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import TaxlawTable from 'components/TaxlawTable/TaxlawTable';
import Taxlaw from 'containers/Taxlaw/Taxlaw';

const propTypes = {
  id: PropTypes.number,
  refetch: PropTypes.object,
  resultTaxlaws: PropTypes.object
};

export default function TaxlawsContent({id, refetch, resultTaxlaws}) {
  const history = useHistory();

  function onRequestCloseMainContent() {
    refetch();
    history.push(`/gameperiods/${id}/taxlaws`);
  }

  const [isTaxlawDialogOpen, setIsTaxlawDialogOpen] = useState(false);
  const [taxlawId, setTaxlawId] = useState(0);

  function onClickShowTaxlaw(entitylinkid) {
    setTaxlawId(entitylinkid);
    setIsTaxlawDialogOpen(true);
  }

  return (
    <div>
      <Dialog onClose={onRequestCloseMainContent} open={isTaxlawDialogOpen}>
        <DialogContent>
          <Taxlaw id={taxlawId} onClose={onRequestCloseMainContent} />
        </DialogContent>
      </Dialog>

      <TaxlawTable
        onClickShowTaxlaw={onClickShowTaxlaw}
        taxlaws={resultTaxlaws.taxlaws}
      />
    </div>
  );
}

TaxlawsContent.propTypes = propTypes;
