import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import EnvUtils from 'utils/EnvUtils';
import BuildingToInfraRatioContent from './BuildingToInfraRatioContent';

export const query = gql`
  query buildingsToInfrastructureRatio($gameid: Int!) {
    regions: region(
      where: {boardtype: {_eq: "normal"}}
      order_by: {name: asc}
    ) {
      id
      name

      buildings: entity_role_aggregate(
        where: {
          entity: {
            entitytype: {
              _in: [
                "energy"
                "industrialproducts"
                "company"
                "agriculturalproducts"
                "miningare"
                "landuse"
                "city"
              ]
            }
          }
          gameperiod: {game: {id: {_eq: $gameid}}}
        }
      ) {
        aggregate {
          count
        }
      }

      trafficNoMilitary: entity_role_aggregate(
        where: {
          entity: {entitytype: {_eq: "traffic"}}
          _and: {entityid: {_neq: 97}}
          gameperiod: {game: {id: {_eq: $gameid}}}
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export default function BuildingToInfraRatio() {
  const {game} = useGameSelectContext();

  const {
    data: resultBuildingToInfraRatio,
    error,
    loading
  } = useQuery(query, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: {gameid: game.id}
  });

  return (
    <AsyncContent error={error} loading={loading}>
      <BuildingToInfraRatioContent
        resultBuildingToInfraRatio={resultBuildingToInfraRatio}
      />
    </AsyncContent>
  );
}
