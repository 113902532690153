export default {
  cancel: 'abbrechen',

  start: 'Start',
  end: 'End',
  questionnaireName: 'Fragebogen',
  gameTitle: 'Spiel',
  title: 'Umfrage',
  status: 'Status',
  startSurvey: 'Umfrage Starten',
  endSurvey: 'Umfrage Beenden',
  orgName: 'Organisation',
  csvdwlError:
    'Probleme beim CSV erstellen! Eventuell gibt es noch keine Daten.',
  csvdwlSuccess:
    'CSV-Datei erfolgreich erstellt und kann heruntergeladen werden.'
};
