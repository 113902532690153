import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import EnvUtils from 'utils/EnvUtils';

export default function useEconomicaccModel(gameid) {
  const GET_ECONOMICACCS = gql`
    query getEconomicaccs($gameid: Int!) {
      economicaccs: economicacc(
        where: {accounttype: {_eq: "money"}, gameid: {_eq: $gameid}}
        order_by: {player: {title: asc}}
      ) {
        id
        player {
          title
        }
      }
    }
  `;

  const {
    data: resultEconomicaccs,
    error,
    loading
  } = useQuery(GET_ECONOMICACCS, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: gameid
  });

  return {
    resultEconomicaccs: {...resultEconomicaccs},
    loading,
    error
  };
}
