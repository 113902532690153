import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import ResearchCardContent from 'containers/ResearchCard/ResearchCardContent';

const propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func
};

const query = gql`
  query researchcard_player_by_pk($id: Int!) {
    researchcardplayer: researchcard_player_by_pk(id: $id) {
      id
      entity {
        name
        cardfile
        salesprice
      }
    }
  }
`;

export default function ResearchCard({id, onClose}) {
  const {
    data: resultResearchCard,
    error,
    loading
  } = useQuery(query, {
    variables: {id}
  });

  return (
    <AsyncContent error={error} loading={loading}>
      <ResearchCardContent
        onCancel={onClose}
        resultResearchCard={resultResearchCard}
      />
    </AsyncContent>
  );
}
ResearchCard.propTypes = propTypes;
