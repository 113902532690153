import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import gql from 'graphql-tag';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import usePaginatedQuery from 'hooks/usePaginatedQuery';
import EnvUtils from 'utils/EnvUtils';
import ProdaccpostingsContent from './ProdaccpostingsContent';
import ProdaccpostingsTopBar from './ProdaccpostingsTopBar';
import labels from './labels';

const query = gql`
  query prodaccpostings(
    $where: prodaccemissiontype_sum_bool_exp! = {}
    $limit: Int = 25
    $offset: Int
  ) {
    aggregate: prodaccemissiontype_sum_aggregate(where: $where) {
      prodaccpostings: aggregate {
        totalCount: count
      }
    }
    prodaccpostings: prodaccemissiontype_sum(
      where: $where
      order_by: [{gameperiodid: desc}, {regionid: asc}, {playerid: asc}]
      limit: $limit
      offset: $offset
    ) {
      gameperiodid
      player {
        title
      }
      region {
        name
      }
      gameperiod {
        name
      }
      etype
      sum
    }
  }
`;

export default function Prodaccpostings() {
  const {game} = useGameSelectContext();
  const history = useHistory();

  const [filter, setFilter] = useState({
    gameperiod: undefined,
    etype: undefined
  });

  function onChangeFilter(nextFilter) {
    setFilter((prev) => ({...prev, ...nextFilter}));
  }

  const {
    data: resultProdaccpostings,
    loading,
    loadingMore,
    onFetchNextPage
  } = usePaginatedQuery(query, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: {
      limit: 50,
      where: {
        gameperiod: {
          gameid: {_eq: game.id},
          id: {_eq: filter.gameperiod?.id}
        },
        etype: {_eq: filter.etype}
      }
    },
    listName: 'prodaccpostings'
  });

  function onClickBack() {
    history.goBack();
  }

  return (
    <>
      <Button
        color='primary'
        component={Button}
        onClick={() => onClickBack()}
        startIcon={<ArrowBackIcon />}
        variant='contained'
      >
        {labels.protocol}
      </Button>
      <Typography gutterBottom variant='h5'>
        {labels.title}
      </Typography>
      <ProdaccpostingsTopBar filter={filter} onChangeFilter={onChangeFilter} />

      <ProdaccpostingsContent
        loading={loading}
        loadingMore={loadingMore}
        onFetchNextPage={onFetchNextPage}
        resultProdaccpostings={resultProdaccpostings}
      />
    </>
  );
}
