import PropTypes from 'prop-types';
import React from 'react';
import cs from './InhabitantsContent.module.scss';
import InhabitantsTable from './InhabitantsTable';

const propTypes = {
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onActionInhabitantsChange: PropTypes.func,
  onFetchNextPage: PropTypes.func,
  resultInhabitants: PropTypes.object
};

export default function InhabitantsContent({
  loading,
  loadingMore,
  onActionInhabitantsChange,
  onFetchNextPage,
  resultInhabitants
}) {
  return (
    <div className={cs.root}>
      <InhabitantsTable
        inhabitants={resultInhabitants?.region}
        loading={loading}
        loadingMore={loadingMore}
        onActionInhabitantsChange={onActionInhabitantsChange}
        onFetchNextPage={onFetchNextPage}
      />
    </div>
  );
}

InhabitantsContent.propTypes = propTypes;
