import gql from 'graphql-tag';

const LOGIN = gql`
  mutation actionAdminLogin($email: String!, $pwd: String!) {
    actionAdminLogin(email: $email, pwd: $pwd) {
      isAuthenticated
      userid
      jsonwebtoken
      userroles
      organisationid
    }
  }
`;

export {LOGIN};
