import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import DropDown from 'components/DropDown/DropDown';
import cs from 'containers/Entitylink/EntityDropDown.module.scss';
import useGameperiodModel from 'containers/Entityrole/useGameperiodModel';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';

export default function GameperiodDropDown({
  autoComplete,
  id,
  name,
  onChange,
  value
}) {
  const {game} = useGameSelectContext();
  const {error, loading, resultGameperiods} = useGameperiodModel({
    gameid: game.id
  });

  return (
    <div className={cs.root}>
      <AsyncContent error={error} loading={loading}>
        <DropDown
          autoComplete={autoComplete}
          data={resultGameperiods.gameperiods}
          emptyrow='-'
          id={id}
          keyfield='id'
          name={name}
          onChange={onChange}
          showfield='name'
          value={value}
        />
      </AsyncContent>
    </div>
  );
}
