import {useState, useEffect} from 'react';

export const PromiseState = {
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
  PENDING: 'pending'
};

export default function usePromiseState() {
  const [promise, setPromise] = useState();
  const [result, setResult] = useState();
  const [error, setError] = useState();
  const [state, setState] = useState();

  useEffect(() => {
    let isCanceled = false;

    setResult(undefined);
    setError(undefined);

    if (promise) {
      setState(PromiseState.PENDING);

      promise
        .then((receivedResult) => {
          if (isCanceled) return;

          setState(PromiseState.FULFILLED);
          setResult(receivedResult);
        })
        .catch((receivedError) => {
          if (isCanceled) return;
          setError(receivedError);
          setState(PromiseState.REJECTED);
        });
    } else {
      setState(undefined);
    }

    return () => {
      isCanceled = true;
    };
  }, [promise]);

  return [{result, error, state}, setPromise];
}
