import {Typography} from '@mui/material';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'components/Button/Button';
import cs from 'components/EntityForm/EntityForm.module.scss';
import Form from 'components/Form/Form';
import FormColumn from 'components/Form/FormColumn';
import FormItem from 'components/Form/FormItem';
import TextInput from 'components/TextInput/TextInput';
import PlayerDropDown from 'containers/Entityrole/PlayerDropDown';
import m from '../../messages/de.json';

const validate = (values) => {
  const errors = {};

  if (!values.playeridtaxreceiver) {
    errors.playeridtaxreceiver = 'zahlt Steuern an - Pflichtfeld';
  }

  return errors;
};

const initialValues = {
  transformtax_government_quota: '1'
};

const propTypes = {
  taxlawheadquarter: PropTypes.object,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};
export default function TaxlawheadquarterForm({
  onClose,
  onSubmit,
  taxlawheadquarter
}) {
  const formik = useFormik({
    initialValues: taxlawheadquarter || initialValues,
    validate,
    onSubmit: onSubmitForm,
    enableReinitialize: true
  });

  const {enqueueSnackbar} = useSnackbar();
  async function onSubmitForm(values) {
    return onSubmit(values)
      .then(() => {
        enqueueSnackbar(m.dataHandlingPromise.fulfilled, {variant: 'success'});
        onClose();
      })
      .catch((e) =>
        enqueueSnackbar(`${m.dataHandlingPromise.rejected} ${e?.message}`, {
          variant: 'error',
          persist: true
        })
      );
  }

  return (
    <div className={cs.root}>
      <Typography gutterBottom variant='h6'>
        Bearbeitung - Umsatzsteuer Einst.
      </Typography>
      <Form
        actions={
          <>
            <Button onClick={onClose} variant='outlined'>
              Abbrechen
            </Button>
            <Button
              color='primary'
              disabled={formik.isSubmitting}
              type='submit'
              variant='contained'
            >
              {m.dataHandlingForm.save}
            </Button>
          </>
        }
        onSubmit={formik.handleSubmit}
      >
        <FormColumn>
          <FormItem
            label='Unternehmen'
            name='player'
            value={formik.values.player ? formik.values.player.title : null}
          >
            <TextInput readonly />
          </FormItem>
          <FormItem
            error={formik.errors.playeridtaxreceiver}
            label='zahlt Steuern an'
            name='playeridtaxreceiver'
            onChange={formik.handleChange}
            value={formik.values.playeridtaxreceiver}
          >
            <PlayerDropDown code='GOVERNMENTROLE' />
          </FormItem>
        </FormColumn>
      </Form>
    </div>
  );
}
TaxlawheadquarterForm.propTypes = propTypes;
