import PropTypes from 'prop-types';
import React from 'react';
import TableNumberOfResults from 'components/TableNumberOfResult/TableNumberOfResult';
import cs from './ProdaccpostingsContent.module.scss';
import ProdaccpostingsTable from './ProdaccpostingsTable';

const propTypes = {
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onFetchNextPage: PropTypes.func,
  resultProdaccpostings: PropTypes.object
};

export default function ProdaccpostingsContent({
  loading,
  loadingMore,
  onFetchNextPage,
  resultProdaccpostings
}) {
  return (
    <div className={cs.root}>
      <ProdaccpostingsTable
        loading={loading}
        loadingMore={loadingMore}
        onFetchNextPage={onFetchNextPage}
        prodaccpostings={resultProdaccpostings?.prodaccpostings}
      />
      <TableNumberOfResults
        count={resultProdaccpostings?.aggregate?.prodaccpostings?.totalCount}
      />
    </div>
  );
}

ProdaccpostingsContent.propTypes = propTypes;
