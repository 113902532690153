import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import cs from './DropDown.module.scss';

const propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  autoComplete: PropTypes.bool,
  data: PropTypes.array,
  keyfield: PropTypes.string,
  showfield: PropTypes.string,
  showfielddecorator: PropTypes.func,
  emptyrow: PropTypes.string
};

export default function DropDown({
  autoComplete,
  className,
  data,
  emptyrow,
  keyfield,
  name,
  onChange,
  showfield,
  showfielddecorator,
  value
}) {
  // react select: https://reactjs.org/docs/forms.html#the-select-tag
  return (
    <select
      autoComplete={autoComplete ? true : 'none'}
      className={cx(className, cs.input)}
      id={name}
      name={name}
      onChange={onChange}
      type='text'
      value={value}
    >
      {emptyrow !== undefined ? <option value='0'>{emptyrow}</option> : null}
      {Array.isArray(data)
        ? data.map((row) => (
            <option key={row[keyfield]} value={row[keyfield]}>
              {showfielddecorator ? showfielddecorator(row) : row[showfield]}
            </option>
          ))
        : null}
    </select>
  );
}

DropDown.propTypes = propTypes;
