import gql from 'graphql-tag';
import landscaperegionMutableFragment from './landscaperegionMutableFragment';

export default gql`
  fragment Landscaperegion_landscape_region on landscape_region {
    landscape {
      code
      landscapetype {
        categorie
      }
    }
    region {
      name
    }
    gameperiod {
      name
    }
    ...Landscaperegion_landscape_region_mutable
  }
  ${landscaperegionMutableFragment}
`;
