import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import EconomictranslogContent from 'containers/Economictranslog/EconomictranslogContent';
import AsyncContent from '../../components/AsyncContent/AsyncContent';

const propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func
};

const query = gql`
  query getEconomictranslog($id: Int!) {
    economictranslog: economictranslog_by_pk(id: $id) {
      id
      gameperiod {
        name
      }
      transactiontype
      fromeconomicacc {
        player {
          title
        }
      }
      toeconomicacc {
        player {
          title
        }
        entity {
          name
        }
      }
      amountmoney
      amountentity
      txt
      status
      erda
    }
  }
`;

export default function Economictranslog({id, onClose}) {
  id === undefined ? (id = 0) : (id = id);

  const {
    data: resultEconomictranslog,
    error,
    loading
  } = useQuery(query, {
    variables: {id}
  });

  return (
    <AsyncContent error={error} loading={loading}>
      <EconomictranslogContent
        onClose={onClose}
        resultEconomictranslog={resultEconomictranslog}
      />
    </AsyncContent>
  );
}
Economictranslog.propTypes = propTypes;
