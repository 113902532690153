import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import {useLocation} from 'react-router-dom';
import ATab from './ATab';

function TabPanel(props) {
  const {children, index, value, ...other} = props;

  return (
    <Typography
      aria-labelledby={`scrollable-auto-tab-${index}`}
      component='div'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      role='tabpanel'
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  tabBar: {
    flexGrow: 1
  }
}));

const propTypes = {
  onClose: PropTypes.func,
  tabs: PropTypes.array
};

export default function ATabs({onClose, tabs}) {
  const location = useLocation();
  const classes = useStyles();

  // direct call of url - mark the correct tab as the selected on:
  let selectedTab = tabs.findIndex((tab) => location.pathname === tab.to);
  selectedTab < 0 ? (selectedTab = 0) : (selectedTab = selectedTab);

  const [value, setValue] = React.useState(selectedTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar color='default' position='static'>
        <Toolbar>
          <Tabs
            aria-label='tab menu'
            className={classes.tabBar}
            indicatorColor='secondary'
            onChange={handleChange}
            scrollButtons='auto'
            textColor='primary'
            value={value}
            variant='fullWidth'
          >
            {tabs.map((tab, index) => (
              <ATab key={index} index={index} title={tab.title} to={tab.to} />
            ))}
          </Tabs>
          <IconButton
            aria-controls='menu-appbar'
            aria-haspopup='true'
            aria-label='account of current user'
            color='inherit'
            onClick={onClose}
            size='large'
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
}

ATabs.propTypes = propTypes;
