import gql from 'graphql-tag';

const GET_PRODACCGRAPH = gql`
  query ProdaccGraph($boardtype: String!, $gameid: Int!) {
    regions: region(where: {boardtype: {_eq: $boardtype}}) {
      prodaccregions: prodaccregion(
        order_by: {gameperiodid: asc}
        where: {
          gameperiod: {gameid: {_eq: $gameid}}
          outtype: {_eq: "ghgemissionacc"}
        }
      ) {
        sumvalue
        sumabsorb
        sumincrease
      }
      name
      id
      color
    }

    prodaccall: prodaccall(
      order_by: {gameperiodid: asc}
      where: {
        gameperiod: {gameid: {_eq: $gameid}}
        outtype: {_eq: "ghgemissionacc"}
      }
    ) {
      sumvalue
      sumabsorb
      sumincrease
    }

    gameperiods: gameperiod(
      order_by: {id: asc}
      where: {gameid: {_eq: $gameid}, closed: {_eq: true}}
    ) {
      id
      gameid
      period
      name
      closed
    }
  }
`;

export default GET_PRODACCGRAPH;
