import {useMutation} from '@apollo/react-hooks';
import Typography from '@mui/material/Typography';
import gql from 'graphql-tag';
import React from 'react';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import usePaginatedQuery from 'hooks/usePaginatedQuery';
import EnvUtils from 'utils/EnvUtils';
import SurveysContent from './SurveysContent';
import labels from './labels';

const query = gql`
  query getSurveys($where: feedbacktool_survey_bool_exp! = {}) {
    aggregate: feedbacktool_survey_aggregate(
      order_by: {tsstart: desc}
      where: $where
    ) {
      surveys: aggregate {
        totalCount: count
      }
    }
    surveys: feedbacktool_survey(order_by: {tsstart: desc}, where: $where) {
      id
      questionnaire {
        name
      }
      tsstart
      status
      tsend
      gameid
      game {
        title
        organisation {
          name
        }
      }
      participantsTotal: survey_participants_aggregate {
        aggregate {
          count
        }
      }
      participantsInProgress: survey_participants_aggregate(
        where: {status: {_eq: "inprogress"}}
      ) {
        aggregate {
          count
        }
      }
      participantsClosed: survey_participants_aggregate(
        where: {status: {_eq: "closed"}}
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

const actionSurveyStatusChangeMutation = gql`
  mutation actionSurveyStatusChange($surveyid: Int!, $statusNext: String!) {
    actionSurveyStatusChange(surveyid: $surveyid, statusNext: $statusNext) {
      code
      message
    }
  }
`;

export default function Surveys() {
  /*
  // in case of the needs to be organisation based
  const $orgPermissionsFilter =
    Authentication.getOrganisationId() > 0
      ? {
          game: {
            organisationid: {_eq: Authentication.getOrganisationId()}
          }
        }
      : {};
*/
  const {game} = useGameSelectContext();

  const {
    data: resultSurveys,
    loading,
    loadingMore,
    onFetchNextPage,
    refetch
  } = usePaginatedQuery(query, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: {
      where: {gameid: {_eq: game.id}}
    },
    listName: 'surveys'
  });

  const [onActionSurveyStatusChangeMutation] = useMutation(
    actionSurveyStatusChangeMutation,
    {
      ignoreResults: true
    }
  );

  async function onActionSurveyStatusChange(surveyid, statusNext) {
    await onActionSurveyStatusChangeMutation({
      variables: {surveyid, statusNext}
    });

    refetch();
  }

  return (
    <>
      <Typography gutterBottom variant='h5'>
        {labels.title}
      </Typography>

      <SurveysContent
        loading={loading}
        loadingMore={loadingMore}
        onActionSurveyStatusChange={onActionSurveyStatusChange}
        onFetchNextPage={onFetchNextPage}
        resultSurveys={resultSurveys}
      />
    </>
  );
}
