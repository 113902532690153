import gql from 'graphql-tag';

const GET_PRODACCPLAYERGRAPH = gql`
  query ProdaccPlayerGraph($gameid: Int!) {
    players: player(where: {gameid: {_eq: $gameid}}) {
      prodaccplayers: prodaccplayer(
        order_by: {gameperiodid: asc}
        where: {
          gameperiod: {gameid: {_eq: $gameid}}
          outtype: {_eq: "ghgemissionacc"}
        }
      ) {
        sumvalue
        sumabsorb
        sumincrease
      }
      title
      id
      role {
        color
        code
      }
      region {
        color
      }
    }

    gameperiods: gameperiod(
      order_by: {id: asc}
      where: {gameid: {_eq: $gameid}, closed: {_eq: true}}
    ) {
      id
      gameid
      period
      name
      closed
    }
  }
`;

export default GET_PRODACCPLAYERGRAPH;
