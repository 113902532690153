import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  value: PropTypes.node
};

const categoryMapping = {
  wood: '🪵',
  iceonland: '❄️',
  iceonwater: '🧊',
  meadows: '🌿',
  cropland: '🌾',
  desert: '🏜',
  mountains: '🏔',
  megacity: '🏘',
  coast: '🏝',
  coralreef: '🐠',
  opensea: '🌊'
};

export default function LandscapecategoryChip({value}) {
  return (
    <Chip
      avatar={
        <Avatar>
          <span aria-label='emoji' role='img'>
            {categoryMapping[value] || 'N'}
          </span>
        </Avatar>
      }
      label={value}
      size='small'
    />
  );
}

LandscapecategoryChip.propTypes = propTypes;
