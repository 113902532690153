import DeleteIcon from '@mui/icons-material/Delete';
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import DeletionDialog from 'components/DeletionDialog/DeletionDialog';
import StatusToggleDialog from 'components/StatusToggleDialog/StatusToggleDialog';
import cs from './EntityrolesTableMenu.module.scss';
import {EntityrolesTableMenu as labels} from './labels';

const propTypes = {
  entityrole: PropTypes.object.isRequired,
  onDesctructionEntityrole: PropTypes.func.isRequired,
  onToggleEntityroleStatus: PropTypes.func.isRequired
};

export default function EntityroleTableMenu({
  entityrole,
  onDesctructionEntityrole,
  onToggleEntityroleStatus
}) {
  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState(false);

  function onClickDelete() {
    setIsDeletionDialogOpen(true);
  }

  function onConfirmDelete() {
    return onDesctructionEntityrole(entityrole);
  }

  const [isStatusToggleDialogOpen, setIsStatusToggleDialogOpen] =
    useState(false);

  function onClickStatusToggle() {
    setIsStatusToggleDialogOpen(true);
  }

  function onConfirmStatusToggle() {
    return onToggleEntityroleStatus(entityrole);
  }

  return (
    <div className={cs.root}>
      <IconButton color='primary' onClick={onClickStatusToggle} size='small'>
        <SystemUpdateIcon />
      </IconButton>

      <IconButton color='primary' onClick={onClickDelete} size='small'>
        <DeleteIcon />
      </IconButton>

      <DeletionDialog
        isOpen={isDeletionDialogOpen}
        onClose={() => setIsDeletionDialogOpen(false)}
        onConfirm={onConfirmDelete}
        title={labels.confirmDelete(entityrole)}
      />
      <StatusToggleDialog
        isOpen={isStatusToggleDialogOpen}
        onClose={() => setIsStatusToggleDialogOpen(false)}
        onConfirm={onConfirmStatusToggle}
        title={labels.confirmStatusToggle(entityrole)}
      />
    </div>
  );
}

EntityroleTableMenu.propTypes = propTypes;
