import React from 'react';
import DropDown from 'components/DropDown/DropDown';
import cs from 'containers/Entitylink/EntityDropDown.module.scss';

export default function EntityroleStatusDropDown({
  autoComplete,
  id,
  name,
  onChange,
  value
}) {
  const resultEntityroleStatus = {
    status: [
      {id: 'sustainable', name: 'sustainable'},
      {id: 'unsustainable', name: 'unsustainable'}
    ]
  };

  return (
    <div className={cs.root}>
      <div>
        <DropDown
          autoComplete={autoComplete}
          data={resultEntityroleStatus.status}
          emptyrow='-'
          id={id}
          keyfield='id'
          name={name}
          onChange={onChange}
          showfield='name'
          value={value}
        />
      </div>
    </div>
  );
}
