import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Switch, Route, useHistory} from 'react-router-dom';
import EntitylinkTable from 'components/EntitylinkTable/EntitylinkTable';
import Entitylink from 'containers/Entitylink/Entitylink';
import Authentication from 'utils/Authentication';

const propTypes = {
  entity: PropTypes.object,
  id: PropTypes.number,
  onDeleteEntitylink: PropTypes.func.isRequired,
  refetch: PropTypes.func,
  resultEntitylinks: PropTypes.object
};

export default function EntitylinksContent({
  entity,
  id,
  onDeleteEntitylink,
  refetch,
  resultEntitylinks
}) {
  const history = useHistory();
  function onRequestCloseMainContent() {
    refetch();
    history.push(`/entities/${id}/entitylinks`);
  }

  const [isEntityLinkDialogOpen, setIsEntityLinkDialogOpen] = useState(false);
  const [entityLinkId, setEntityLinkId] = useState(0);

  function onClickShowEntityLink(entitylinkid) {
    setEntityLinkId(entitylinkid);
    setIsEntityLinkDialogOpen(true);
  }

  return (
    <div>
      <Dialog onClose={onRequestCloseMainContent} open={isEntityLinkDialogOpen}>
        <DialogTitle>Transformations-Einstellungen</DialogTitle>
        <DialogContent>
          <Entitylink
            entityid={id}
            id={entityLinkId}
            onClose={onRequestCloseMainContent}
            typeFilter={
              entity.entitytype === 'ghost' ? ['input'] : ['input', 'output']
            }
          />
        </DialogContent>
      </Dialog>
      <Switch>
        <Route>
          {Authentication.hasUserRole('uiadmin_admin') && (
            <div>
              <br />
              <Button
                color='primary'
                onClick={() => onClickShowEntityLink(0)}
                startIcon={<AddIcon />}
                variant='contained'
              >
                Einstellung Hinzufügen
              </Button>
            </div>
          )}
          <div>
            <EntitylinkTable
              entitylinks={resultEntitylinks.entitylinks}
              onClickShowEntityLink={onClickShowEntityLink}
              onDeleteEntitylink={onDeleteEntitylink}
            />
          </div>
        </Route>
      </Switch>
    </div>
  );
}

EntitylinksContent.propTypes = propTypes;
