import CloseIcon from '@mui/icons-material/Close';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import {Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import Formater from 'utils/Formater';

const propTypes = {
  capacity: PropTypes.number,
  required: PropTypes.number,
  stock: PropTypes.number
};

export function QuotientStockRequired(stock, required) {
  if (!(stock / required)) {
    return 0;
  }
  return stock / required;
}

export function QuotientCapacityRequired(capacity, required) {
  if (!(capacity / required)) {
    return 0;
  }
  return capacity / required;
}

function isCriticalProduct(stock, capacity, required) {
  if (
    QuotientStockRequired(stock, required) < 1 &&
    QuotientCapacityRequired(capacity, required) < 1
  ) {
    return true;
  }
  return false;
}

function isCriticalProductOverdue(stock, capacity, required) {
  if (
    QuotientStockRequired(stock, required) >= 2 &&
    QuotientCapacityRequired(capacity, required) >= 2
  ) {
    return true;
  }
  return false;
}

function GlobalmarketsAttentionMarker({capacity, required, stock}) {
  if (isCriticalProduct(stock, capacity, required)) {
    return (
      <Typography style={{color: 'red'}} variant='bold'>
        <PriorityHighIcon /> {Formater.numberFormat(required)}
      </Typography>
    );
  }
  if (isCriticalProductOverdue(stock, capacity, required)) {
    return (
      <Typography style={{color: 'red'}} variant='bold'>
        <CloseIcon /> {Formater.numberFormat(required)}
      </Typography>
    );
  }

  return <>{Formater.numberFormat(required)}</>;
}

GlobalmarketsAttentionMarker.propTypes = propTypes;
export default GlobalmarketsAttentionMarker;
