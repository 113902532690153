import {TableContainer, Table, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContentSpinner from 'components/AsyncContent/AsyncContentSpinner';
import VisibilitySensor from 'components/VisibilitySensor/VisibilitySensor';
import cs from './TableFixedInfiniteLoading.module.scss';
import labels from './labels';

const propTypes = {
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  children: PropTypes.func,
  onFetchNextPage: PropTypes.func
};

export default function TableFixedInfiniteLoading({
  children,
  loading,
  loadingMore,
  onFetchNextPage
}) {
  const hasNextPage = onFetchNextPage != null;

  return (
    <TableContainer className={cs.tableContainer}>
      {loading && <AsyncContentSpinner loadingMessage={labels.loadingMore} />}
      <Table className={cs.table} component='div' size='small' stickyHeader>
        {!loading && children()}
      </Table>
      {loadingMore && (
        <AsyncContentSpinner loadingMessage={labels.loadingMore} />
      )}
      <VisibilitySensor isActive={hasNextPage} onVisibile={onFetchNextPage} />

      {!hasNextPage && (
        <Typography className={cs.noEntries} component='div'>
          {labels.end}
        </Typography>
      )}
    </TableContainer>
  );
}

TableFixedInfiniteLoading.propTypes = propTypes;
