import gql from 'graphql-tag';
// test arguments: {"where": {"gameperiod": {"game": {"id": {"_eq": 94}}}}}
export const query = gql`
  query getProtocol(
    $where: logging_protocol_view_bool_exp! = {}
    $limit: Int = 25
    $offset: Int
  ) {
    aggregate: logging_protocol_view_aggregate(where: $where) {
      protocols: aggregate {
        totalCount: count
      }
    }
    protocols: logging_protocol_view(
      where: $where
      order_by: {id: desc}
      limit: $limit
      offset: $offset
    ) {
      id
      tstamp
      gameperiodid
      delta
      operation
      tabname
      entityroleid
      landscaperegion {
        landscape {
          code
          landscapetype {
            categorie
          }
        }
      }
      region {
        id
        name
      }
      new_region {
        id
        name
      }
      player {
        title
      }
      new_player {
        title
      }
      entity {
        name
      }
      gameperiod {
        id
        name
      }
    }
  }
`;
