import PropTypes from 'prop-types';
import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import ATabs from 'components/ATabs/ATabs';
import GameForm from 'components/GameForm/GameForm';

const propTypes = {
  resultGame: PropTypes.object,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

export default function GameContent({onClose, onSubmit, resultGame}) {
  const {path} = useRouteMatch();

  const tabs = [
    {
      title: `${
        resultGame.game !== null
          ? resultGame.game.title.substring(0, 15)
          : 'Neu'
      }`,
      to: `/games/${resultGame.game !== null ? resultGame.game.id : null}`
    }
  ];

  return (
    <>
      <ATabs onClose={onClose} tabs={tabs} />
      <Switch>
        <Route>
          <GameForm
            game={resultGame ? resultGame.game : undefined}
            onClose={onClose}
            onSubmit={onSubmit}
          />
        </Route>
      </Switch>
    </>
  );
}

GameContent.propTypes = propTypes;
