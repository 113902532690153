import gql from 'graphql-tag';

const GET_TAXLAWHEADQUARTER = gql`
  query getTaxlawheadquarter($id: Int!) {
    taxlawheadquarter: taxlaw_headquarter_by_pk(id: $id) {
      id
      player {
        title
      }
      playeridtaxreceiver
    }
  }
`;

const UPDATE_TAXLAWHEADQUARTER = gql`
  mutation updateTaxlawheadquarter($id: Int!, $playeridtaxreceiver: Int) {
    update_taxlaw_headquarter_by_pk(
      pk_columns: {id: $id}
      _set: {playeridtaxreceiver: $playeridtaxreceiver}
    ) {
      id
    }
  }
`;

export {GET_TAXLAWHEADQUARTER, UPDATE_TAXLAWHEADQUARTER};
