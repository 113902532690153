export default {
  title: 'TeilnehmerInnen Umfragen'
};

export const SurveysTable = {
  header: {
    start: 'Start am',
    end: 'Ende am',
    status: 'Status',
    questionnaireName: 'Fragebogen',
    gameName: 'Spiel',
    participantsTotal: 'Teilnehmer',
    participantsClosed: 'am beantworten',
    organisationName: 'Organisation'
  },
  actionStatusChange: {
    error: 'Fehler bei der Statusänderung der Umfrage',
    success: 'Status erfolgreich geändert'
  },
  status: {
    open: 'gesperrt',
    inprogress: 'offen',
    closed: 'abgeschlossen'
  }
};

export const SurveysTopBar = {
  player: 'SpielerIn:'
};
