import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import usePaginatedQuery from 'hooks/usePaginatedQuery';
import EnvUtils from 'utils/EnvUtils';
import {query} from './Protocols.js';
import ProtocolsContentLandscaperegion from './ProtocolsContentLandscaperegion';
import ProtocolsTopBarLandscaperegion from './ProtocolsTopBarLandscaperegion';
import labels from './labels';

export default function ProtocolsLandscaperegion() {
  const tabNameFilter = 'landscape_region';
  const {game} = useGameSelectContext();

  const [filter, setFilter] = useState({
    role: undefined,
    region: undefined,
    numberFilter: undefined
  });

  function onChangeFilter(nextFilter) {
    setFilter((prev) => ({...prev, ...nextFilter}));
  }

  function getVariables() {
    return {
      limit: 50,
      where: {
        gameperiod: {
          gameid: {_eq: game.id}
        },
        region: {
          id: {_eq: filter.region?.id}
        },
        tabname: {_eq: tabNameFilter}
      }
    };
  }

  const {
    data: resultProtocols,
    loading,
    loadingMore,
    onFetchNextPage
  } = usePaginatedQuery(query, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: getVariables(),
    listName: 'protocols'
  });

  return (
    <>
      <Button
        color='primary'
        component={Link}
        startIcon={<ArrowBackIcon />}
        to='/landscaperegions'
        variant='contained'
      >
        {labels.protocol}
      </Button>
      <Typography gutterBottom variant='h5'>
        {labels.title}
      </Typography>
      <ProtocolsTopBarLandscaperegion
        filter={filter}
        onChangeFilter={onChangeFilter}
      />

      <ProtocolsContentLandscaperegion
        loading={loading}
        loadingMore={loadingMore}
        onFetchNextPage={onFetchNextPage}
        resultProtocols={resultProtocols}
      />
      <br />
      <br />
      <Typography gutterBottom variant='normal'>
        {labels.explanation}
      </Typography>
    </>
  );
}
