export default {
  save: 'speichern',
  cancel: 'abbrechen',
  error: 'Error while saving - please try again.',

  gameperiodid: 'Spielperiode',
  landscapeid: 'Hexagon',
  regionid: 'Region',
  status: 'Status',
  category: 'Kategorie',

  title: 'Hexagon'
};
