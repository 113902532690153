import gql from 'graphql-tag';

const GET_INHABITANTGRAPH = gql`
  query InhabitantGraph($boardtype: String!, $gameid: Int!) {
    regions: region(where: {boardtype: {_eq: $boardtype}}) {
      inhabitants: inhabitantacc_view(
        order_by: {gameperiodid: asc}
        where: {gameperiod: {gameid: {_eq: $gameid}}}
      ) {
        population_sum
        accomodationcapacity_sum
      }
      name
      id
      color
    }

    inhabitantsall: gameperiod(
      order_by: {id: asc}
      where: {gameid: {_eq: $gameid}}
    ) {
      id
      inhabitantacc_view_aggregate {
        aggregate {
          sum {
            population_sum
          }
        }
      }
    }

    gameperiods: gameperiod(
      order_by: {id: asc}
      where: {gameid: {_eq: $gameid}, closed: {_eq: true}}
    ) {
      id
      gameid
      period
      name
      closed
    }
  }
`;

export default GET_INHABITANTGRAPH;
