import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import cs from 'components/TextInput/TextInput.module.scss';

const propTypes = {
  autoComplete: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
};

export default function DatePicker({
  autoComplete,
  className,
  name,
  onChange,
  value
}) {
  return (
    <input
      autoComplete={autoComplete ? true : 'none'}
      className={cx(className, cs.input)}
      id={name}
      name={name}
      onChange={onChange}
      type='date'
      value={value || undefined}
    />
  );
}

DatePicker.propTypes = propTypes;
