import {useQuery} from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import React from 'react';
import EconomicaccContent from 'containers/Economicacc/EconomicaccContent';
import GET_ECONOMICACC from 'containers/Economicacc/EconomicaccSchemas';
import AsyncContent from '../../components/AsyncContent/AsyncContent';

const propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func
};

export default function Economicacc({id, onClose}) {
  id === undefined ? (id = 0) : (id = id);

  const {
    data: resultEconomicacc,
    error,
    loading
  } = useQuery(GET_ECONOMICACC, {
    variables: {id}
  });

  return (
    <AsyncContent error={error} loading={loading}>
      <EconomicaccContent
        onClose={onClose}
        resultEconomicacc={resultEconomicacc}
      />
    </AsyncContent>
  );
}
Economicacc.propTypes = propTypes;
