import gql from 'graphql-tag';
import entityroleMutableFragment from './entityroleMutableFragment';

export default gql`
  fragment Entityrole_entity_role on entity_role {
    player {
      id
      title
    }
    ...Entityrole_entity_role_mutable
  }
  ${entityroleMutableFragment}
`;
