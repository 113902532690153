import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import SimpleTable from 'components/SimpleTable/SimpleTable';
import transactioncodeTypes from 'containers/Economictranslogs/transactioncodeTypes';
import transactiontypeTypes from 'containers/Economictranslogs/transactiontypeTypes';
import DateUtils from 'utils/DateUtils';
import Formater from 'utils/Formater';
import cs from './EconomicaccpostingTable.module.scss';

const propTypes = {
  economicaccpostings: PropTypes.array
};
export default function EconomicaccpostingTable({economicaccpostings}) {
  function ItemDecoration(id, value, object, field, row) {
    let nextValue = value;
    if (object) {
      nextValue = object[value];
    } else if (value === 'name' || value === 'title') {
      nextValue = '';
    } else if (field === 'balance') {
      nextValue = Formater.numberFormat(value);
    } else if (field === 'amount') {
      nextValue = Formater.numberFormat(value);
    } else if (field === 'erda') {
      if (value) {
        nextValue = DateUtils.formatDateTime(value);
      }
    }

    if (field === 'transactiontype') {
      nextValue = transactiontypeTypes[object[value]];
    }

    if (field === 'transactioncode') {
      nextValue = transactioncodeTypes[object[value]];
    }

    if (field === 'fromOrto') {
      const isOutgoingPosting =
        row.economicaccid === row.economictranslog.fromeconomicaccid;
      nextValue = isOutgoingPosting
        ? object?.toeconomicacc?.player.title
        : object?.fromeconomicacc?.player.title;
    }

    if (field === 'amount') {
      const isOutgoingPosting =
        row.economicaccid === row.economictranslog.fromeconomicaccid;
      nextValue = (
        <Typography color={isOutgoingPosting ? 'red' : null} variant='small'>
          {Formater.numberFormat(
            isOutgoingPosting ? -1 * row.amount : row.amount
          )}
        </Typography>
      );
    }

    return nextValue;
  }

  if (economicaccpostings === undefined) {
    return null;
  }

  return (
    <div className={cs.root}>
      <SimpleTable
        columns={[
          {
            title: 'Zeitpunkt',
            field: 'erda',
            align: 'left'
          },
          {
            title: 'Spielperiode',
            object: 'economictranslog.gameperiod',
            field: 'name',
            align: 'left'
          },
          {
            title: 'Buchungstyp',
            object: 'economictranslog',
            field: 'transactiontype',
            align: 'left'
          },
          {
            title: 'von/nach',
            field: 'fromOrto',
            object: 'economictranslog',
            align: 'left'
          },
          {
            title: 'Transaktionscode',
            object: 'economictranslog',
            field: 'transactioncode',
            align: 'left'
          },
          {
            title: 'Beschreibung',
            object: 'economictranslog',
            field: 'txt',
            align: 'left'
          },
          {
            title: 'Menge Buchung',
            field: 'amount',
            object: 'economictranslog',
            align: 'right'
          },
          {
            title: 'Neuer Kontostand',
            field: 'balance',
            align: 'right'
          }
        ]}
        data={economicaccpostings}
        itemDecoration={ItemDecoration}
        keyfield='id'
      />
    </div>
  );
}

EconomicaccpostingTable.propTypes = propTypes;
