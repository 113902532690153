import {useQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import {GET_ENTITYLINKS} from 'containers/EntitylinkResearches/EntitylinkResearchesSchemas';
import EnvUtils from 'utils/EnvUtils';
import EntitylinkResearchesContent from './EntitylinkResearchesContent';

const propTypes = {
  id: PropTypes.number
};

export default function EntitylinkResearches({id}) {
  const {
    data: resultEntitylinkResearches,
    error,
    loading,
    refetch
  } = useQuery(GET_ENTITYLINKS, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: {id}
  });

  const UPDATE_ENTITYLINK = gql`
    mutation updateEntitylink($id: Int!, $deleted: Boolean) {
      update_entitylink_by_pk(
        pk_columns: {id: $id}
        _set: {deleted: $deleted}
      ) {
        id
      }
    }
  `;
  const [updateEntitylink, {errorDel, loadingDel}] = useMutation(
    UPDATE_ENTITYLINK,
    {
      ignoreResults: true
    }
  );

  async function onDeleteEntitylink(entitylink) {
    const dEntitylink = {id: entitylink.id};
    updateEntitylink({variables: dEntitylink})
      .then(() => {
        refetch();
      })
      .catch((res) => {
        const errors = res.graphQLErrors.map((error) => error.message);
      });
  }

  return (
    <AsyncContent error={error || errorDel} loading={loading || loadingDel}>
      <EntitylinkResearchesContent
        id={id}
        onDeleteEntitylink={onDeleteEntitylink}
        refetch={refetch}
        resultEntitylinkResearches={resultEntitylinkResearches}
      />
    </AsyncContent>
  );
}

EntitylinkResearches.propTypes = propTypes;
