import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  children: PropTypes.object,
  description: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string
};

export default function SimpleDialog({
  children,
  description,
  isOpen,
  onClose,
  title
}) {
  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        {children &&
          React.cloneElement(children, {
            onClose
          })}
      </DialogContent>
    </Dialog>
  );
}

SimpleDialog.propTypes = propTypes;
