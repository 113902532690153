import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import TableFixedInfiniteLoading from 'components/TableFixedInfiniteLoading/TableFixedInfiniteLoading';
import Formater from 'utils/Formater';
import cs from './ProdaccpostingsTable.module.scss';
import {ProdaccpostingsTable as labels, etype} from './labels';

const propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onFetchNextPage: PropTypes.func,
  prodaccpostings: PropTypes.array
};

function ProdaccpostingsTable({
  className,
  loading,
  loadingMore,
  onFetchNextPage,
  prodaccpostings
}) {
  const {url} = useRouteMatch();

  function round(value, decimals) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
  }

  function renderTableHead() {
    return (
      <TableHead component='div'>
        <TableRow component='div'>
          <TableCell align='left' component='div'>
            {labels.header.gameperiod}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.region}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.player}
          </TableCell>
          <TableCell align='right' component='div'>
            {labels.header.etype}
          </TableCell>
          <TableCell align='right' component='div'>
            {labels.header.sum}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Paper className={cx(cs.root, className)}>
      <TableFixedInfiniteLoading
        loading={loading}
        loadingMore={loadingMore}
        onFetchNextPage={onFetchNextPage}
      >
        {() => (
          <>
            {renderTableHead()}
            <TableBody component='div'>
              {prodaccpostings.map((prodaccposting) => (
                <TableRow
                  key={prodaccposting.id}
                  className={cs.rowLink}
                  component={(props) => (
                    <Link to={`${url}/${prodaccposting.id}`} {...props} />
                  )}
                  hover
                >
                  <TableCell
                    align='left'
                    className={cs.gameperiodColumn}
                    component='div'
                  >
                    {prodaccposting.gameperiod.name}
                  </TableCell>
                  <TableCell align='left' component='div'>
                    {prodaccposting.region?.name}
                  </TableCell>
                  <TableCell
                    align='left'
                    className={cs.roleColumn}
                    component='div'
                  >
                    {prodaccposting.player?.title}
                  </TableCell>
                  <TableCell align='right' component='div'>
                    {etype[prodaccposting.etype]}
                  </TableCell>
                  <TableCell align='right' component='div'>
                    {Formater.numberFormat(round(prodaccposting.sum, 2))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </>
        )}
      </TableFixedInfiniteLoading>
    </Paper>
  );
}

ProdaccpostingsTable.propTypes = propTypes;
export default ProdaccpostingsTable;
