import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';

const propTypes = {
  children: PropTypes.node
};

export default function GameSelectedRoute({children, ...rest}) {
  const {game} = useGameSelectContext();
  const {enqueueSnackbar} = useSnackbar();

  const hasGameSelected = game != null;

  return (
    <Route
      {...rest}
      render={({location}) =>
        hasGameSelected ? (
          children
        ) : (
          <>
            {enqueueSnackbar(`Bitte wähle rechts oben ein Spiel`, {
              variant: 'warning'
            })}
            <Redirect
              to={{
                pathname: '/',
                state: {from: location}
              }}
            />
          </>
        )
      }
    />
  );
}

GameSelectedRoute.propTypes = propTypes;
