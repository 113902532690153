import {SnackbarProvider} from 'notistack';
import React from 'react';
import {Switch, Route, BrowserRouter} from 'react-router-dom';
import Economicaccs from 'containers/Economicaccs/Economicaccs';
import Economictranslogs from 'containers/Economictranslogs/Economictranslogs';
import Entities from 'containers/Entities/Entities';
import Entityroles from 'containers/Entityroles/Entityroles';
import Gameperiods from 'containers/Gameperiods/Gameperiods';
import Games from 'containers/Games/Games';
import Globalmarkets from 'containers/Globalmarkets/Globalmarkets';
import BuildingToInfraRatio from 'containers/Graphs/BuildingToInfraRatio';
import InhabitantGraph from 'containers/Graphs/InhabitantGraph';
import ProdaccAllGraph from 'containers/Graphs/ProdaccAllGraph';
import ProdaccComparedGraph from 'containers/Graphs/ProdaccComparedGraph';
import ProdaccGraph from 'containers/Graphs/ProdaccGraph';
import ProdaccPlayerGraph from 'containers/Graphs/ProdaccPlayerGraph';
import SupplyRatioGraph from 'containers/Graphs/SupplyRatioGrap';
import TrashPerPlayer from 'containers/Graphs/TrashPerPlayer';
import Home from 'containers/Home/Home';
import Inhabitants from 'containers/Inhabitants/Inhabitants';
import Landscaperegions from 'containers/Landscaperegions/Landscaperegions';
import Login from 'containers/Login/Login';
import Organisations from 'containers/Organisations/Organisations';
import Players from 'containers/Players/Players';
import Prodaccpostings from 'containers/Prodaccpostings/Prodaccpostings';
import ProtocolsEntityrole from 'containers/Protocols/ProtocolsEntityrole';
import ProtocolsLandscaperegion from 'containers/Protocols/ProtocolsLandscaperegion';
import ResearchCards from 'containers/ResearchCards/ResearchCards';
import Surveys from 'containers/Surveys/Surveys';
import UserProfile from 'containers/UserProfile';
import Userlists from 'containers/Userlists/Userlists';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';
import GameSelectedRoute from 'routes/GameSelectedRoute';

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <BrowserRouter>
        <Switch>
          <Route exact={false} path='/login'>
            <Login />
          </Route>

          <AuthenticatedRoute exact path='/'>
            <Home />
          </AuthenticatedRoute>

          <AuthenticatedRoute exact={false} path='/organisations'>
            <Organisations />
          </AuthenticatedRoute>

          <AuthenticatedRoute exact={false} path='/userlists'>
            <Userlists />
          </AuthenticatedRoute>

          <AuthenticatedRoute exact={false} path='/games'>
            <Games />
          </AuthenticatedRoute>

          <AuthenticatedRoute exact={false} path='/entities'>
            <Entities />
          </AuthenticatedRoute>

          <AuthenticatedRoute exact={false} path='/userprofile'>
            <UserProfile />
          </AuthenticatedRoute>

          <AuthenticatedRoute exact={false} path='/entityroles'>
            <GameSelectedRoute>
              <Entityroles />
            </GameSelectedRoute>
          </AuthenticatedRoute>

          <AuthenticatedRoute exact={false} path='/landscaperegions'>
            <GameSelectedRoute>
              <Landscaperegions />
            </GameSelectedRoute>
          </AuthenticatedRoute>

          <AuthenticatedRoute exact={false} path='/surveys'>
            <GameSelectedRoute>
              <Surveys />
            </GameSelectedRoute>
          </AuthenticatedRoute>

          <AuthenticatedRoute exact={false} path='/protocols/landscaperegion'>
            <GameSelectedRoute>
              <ProtocolsLandscaperegion />
            </GameSelectedRoute>
          </AuthenticatedRoute>

          <AuthenticatedRoute exact={false} path='/protocols/entityrole'>
            <GameSelectedRoute>
              <ProtocolsEntityrole />
            </GameSelectedRoute>
          </AuthenticatedRoute>

          <AuthenticatedRoute exact={false} path='/economictranslogs'>
            <GameSelectedRoute>
              <Economictranslogs />
            </GameSelectedRoute>
          </AuthenticatedRoute>

          <AuthenticatedRoute exact={false} path='/economicaccs'>
            <GameSelectedRoute>
              <Economicaccs />
            </GameSelectedRoute>
          </AuthenticatedRoute>

          <AuthenticatedRoute exact={false} path='/globalmarkets'>
            <GameSelectedRoute>
              <Globalmarkets />
            </GameSelectedRoute>
          </AuthenticatedRoute>

          <AuthenticatedRoute exact={false} path='/gameperiods'>
            <GameSelectedRoute>
              <Gameperiods />
            </GameSelectedRoute>
          </AuthenticatedRoute>

          <AuthenticatedRoute exact={false} path='/prodaccpostings'>
            <GameSelectedRoute>
              <Prodaccpostings />
            </GameSelectedRoute>
          </AuthenticatedRoute>

          <AuthenticatedRoute exact={false} path='/players'>
            <GameSelectedRoute>
              <Players />
            </GameSelectedRoute>
          </AuthenticatedRoute>

          <AuthenticatedRoute exact={false} path='/inhabitants'>
            <GameSelectedRoute>
              <Inhabitants />
              <br />
              <br />
              <InhabitantGraph />
              <br />
              <br />
              <SupplyRatioGraph />
              <br />
              <br />
              <BuildingToInfraRatio />
              <br />
              <br />
              <TrashPerPlayer entityCode='RADIOACTIVEWASTE' />
              <br />
              <br />
              <TrashPerPlayer entityCode='TRASH' />
            </GameSelectedRoute>
          </AuthenticatedRoute>

          <AuthenticatedRoute exact={false} path='/researchcards'>
            <GameSelectedRoute>
              <ResearchCards />
            </GameSelectedRoute>
          </AuthenticatedRoute>

          <AuthenticatedRoute exact={false} path='/graphs'>
            <GameSelectedRoute>
              <ProdaccAllGraph />
              <br />
              <br />
              <ProdaccGraph />
              <br />
              <br />
              <ProdaccComparedGraph />
              <br />
              <br />
              <ProdaccPlayerGraph />
            </GameSelectedRoute>
          </AuthenticatedRoute>
        </Switch>
      </BrowserRouter>
    </SnackbarProvider>
  );
}

export default App;
