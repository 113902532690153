import PropTypes from 'prop-types';
import React from 'react';
import SurveyForm from 'components/SurveyForm/SurveyForm';

const propTypes = {
  onCancel: PropTypes.func,
  onSurveyAnswers: PropTypes.func,
  resultSurvey: PropTypes.object
};

export default function SurveyContent({
  onCancel,
  onSurveyAnswers,
  resultSurvey
}) {
  return (
    <SurveyForm
      onCancel={onCancel}
      onSurveyAnswers={onSurveyAnswers}
      survey={resultSurvey?.survey}
    />
  );
}

SurveyContent.propTypes = propTypes;
