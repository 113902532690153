import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'components/Button/Button';
import cs from 'components/EntityForm/EntityForm.module.scss';
import Form from 'components/Form/Form';
import FormColumn from 'components/Form/FormColumn';
import FormItem from 'components/Form/FormItem';
import TextInput from 'components/TextInput/TextInput';
import entitytypeCategory from 'config/entitytypeCategory';
import EconomicaccDropDown from 'containers/EconomicaccDropDown/EconomicaccDropDown';
import EntityDropDown from 'containers/Entitylink/EntityDropDown';
import GameperiodDropDown from 'containers/Entityrole/GameperiodDropDown';
import m from '../../messages/de.json';
import labels from './labels';

// eslint-disable-next-line func-style
const validate = (values) => {
  const errors = {};

  if (!values.gameperiodid || values.gameperiodid === '0') {
    errors.gameperiodid = 'Spielperiode ist ein Pflichtfeld';
  }

  if (!values.amountentity || !parseInt(values.amountentity)) {
    errors.amountentity = 'Menge ist ein Pflichtfeld';
  }

  if (!values.entityid) {
    errors.entityid = 'Entität ist ein Pflichtfeld';
  }

  if (!values.amountmoney || !parseInt(values.amountmoney)) {
    errors.amountmoney = 'Preis ist ein Pflichtfeld';
  }

  if (!values.txt) {
    errors.txt = 'Beschreibung ist ein Pflichtfeld';
  }

  if (!values.toeconomicaccid || parseInt(values.toeconomicaccid) <= 0) {
    errors.toeconomicaccid = 'Verkäufer ist ein Pflichtfeld';
  }

  if (!values.fromeconomicaccid || parseInt(values.fromeconomicaccid) <= 0) {
    errors.fromeconomicaccid = 'Käufer ist ein Pflichtfeld';
  }

  return errors;
};

const initialValues = {
  txt: ''
};

const propTypes = {
  economictranslog: PropTypes.object,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};
export default function EconomictradeForm({
  economictranslog,
  onClose,
  onSubmit
}) {
  const formik = useFormik({
    initialValues: economictranslog || initialValues,
    validate,
    onSubmit: onSubmitForm,
    enableReinitialize: true
  });

  const {enqueueSnackbar} = useSnackbar();
  async function onSubmitForm(values) {
    return onSubmit(values)
      .then(() => {
        enqueueSnackbar('Daten erfolgreich gespeichert', {variant: 'success'});
        onClose();
      })
      .catch((e) =>
        enqueueSnackbar(`${labels.error} ${e?.message}`, {
          variant: 'error',
          persist: true
        })
      );
  }

  return (
    <div className={cs.root}>
      <Form
        actions={
          <>
            <Button onClick={onClose} variant='outlined'>
              Abbrechen
            </Button>
            <Button
              color='primary'
              disabled={formik.isSubmitting}
              type='submit'
              variant='contained'
            >
              {m.dataHandlingForm.save}
            </Button>
          </>
        }
        onSubmit={formik.handleSubmit}
      >
        <FormColumn>
          <FormItem
            error={formik.errors.gameperiodid}
            label='Spielperiode'
            name='gameperiodid'
            onChange={formik.handleChange}
            value={
              formik.values.gameperiodid
                ? formik.values.gameperiodid.toString()
                : formik.values.gameperiodid
            }
          >
            <GameperiodDropDown />
          </FormItem>
          <FormItem
            error={formik.errors.toeconomicaccid}
            label='Vom SpielerIn (Verkäufer)'
            name='toeconomicaccid'
            onChange={formik.handleChange}
            value={
              formik.values.toeconomicaccid
                ? formik.values.toeconomicaccid.toString()
                : formik.values.toeconomicaccid
            }
          >
            {formik.values.id === undefined ? (
              <EconomicaccDropDown />
            ) : (
              <TextInput readonly />
            )}
          </FormItem>
          <FormItem
            error={formik.errors.entityid}
            label='wird das Produkt'
            name='entityid'
            onChange={formik.handleChange}
            value={
              formik.values.entityid
                ? formik.values.entityid.toString()
                : formik.values.entityid
            }
          >
            {formik.values.id === undefined ? (
              <EntityDropDown filterEntitytype={entitytypeCategory.product} />
            ) : (
              <TextInput readonly />
            )}
          </FormItem>
          <FormItem
            error={formik.errors.amountentity}
            label='in der Menge'
            name='amountentity'
            onChange={formik.handleChange}
            value={
              formik.values.amountentity
                ? formik.values.amountentity.toString()
                : formik.values.amountentity
            }
          >
            {formik.values.id === undefined ? (
              <TextInput />
            ) : (
              <TextInput readonly />
            )}
          </FormItem>{' '}
          <FormItem
            error={formik.errors.fromeconomicaccid}
            label='an den SpielerIn (Käufer)'
            name='fromeconomicaccid'
            onChange={formik.handleChange}
            value={
              formik.values.fromeconomicaccid
                ? formik.values.fromeconomicaccid.toString()
                : formik.values.fromeconomicaccid
            }
          >
            {formik.values.id === undefined ? (
              <EconomicaccDropDown />
            ) : (
              <TextInput readonly />
            )}
          </FormItem>
          <FormItem
            error={formik.errors.amountmoney}
            label='zum Preis [$] von, verkauft'
            name='amountmoney'
            onChange={formik.handleChange}
            value={
              formik.values.amountmoney
                ? formik.values.amountmoney.toString()
                : formik.values.amountmoney
            }
          >
            {formik.values.id === undefined ? (
              <TextInput />
            ) : (
              <TextInput readonly />
            )}
          </FormItem>
          <FormItem
            error={formik.errors.txt}
            label='Bemerkung'
            name='txt'
            onChange={formik.handleChange}
            value={
              formik.values.txt
                ? formik.values.txt.toString()
                : formik.values.txt
            }
          >
            {formik.values.id === undefined ? (
              <TextInput />
            ) : (
              <TextInput readonly />
            )}
          </FormItem>
        </FormColumn>
      </Form>
    </div>
  );
}
EconomictradeForm.propTypes = propTypes;
