import AddIcon from '@mui/icons-material/Add';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import {useRouteMatch, Link, useHistory} from 'react-router-dom';
import SidebarDrawer from 'components/SidebarDrawer/SidebarDrawer';
import TableNumberOfResults from 'components/TableNumberOfResult/TableNumberOfResult';
import Entityrole from 'containers/Entityrole/Entityrole';
import cs from './EntityrolesContent.module.scss';
import EntityrolesTable from './EntityrolesTable';
import {EntityrolesContent as labels} from './labels';

const propTypes = {
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onDesctructionEntityrole: PropTypes.func.isRequired,
  onFetchNextPage: PropTypes.func,
  onToggleEntityroleStatus: PropTypes.func.isRequired,
  refetch: PropTypes.func,
  resultEntityroles: PropTypes.object
};

export default function EntityrolesContent({
  loading,
  loadingMore,
  onDesctructionEntityrole,
  onFetchNextPage,
  onToggleEntityroleStatus,
  refetch,
  resultEntityroles
}) {
  const history = useHistory();
  const {params, path} = useRouteMatch([
    '/entityroles/new',
    '/entityroles/:id?'
  ]);

  function onClose() {
    refetch();
    history.push('/entityroles');
  }

  const openSidebarDrawer = params.id != null || path === '/entityroles/new';

  return (
    <div className={cs.root}>
      <Button
        className={cs.newEntity}
        color='primary'
        component={Link}
        startIcon={<AddIcon />}
        to='/entityroles/new'
        variant='contained'
      >
        {labels.new}
      </Button>
      <Button
        className={cs.newEntity}
        color='primary'
        component={Link}
        startIcon={<LibraryBooksIcon />}
        to='/protocols/entityrole'
        variant='contained'
      >
        {labels.protocol}
      </Button>

      <EntityrolesTable
        className={cs.table}
        entityroles={resultEntityroles?.entityroles}
        loading={loading}
        loadingMore={loadingMore}
        onDesctructionEntityrole={onDesctructionEntityrole}
        onFetchNextPage={onFetchNextPage}
        onToggleEntityroleStatus={onToggleEntityroleStatus}
        selectedId={params.id}
      />

      <SidebarDrawer onClose={onClose} open={openSidebarDrawer}>
        <Entityrole id={params.id} onClose={onClose} />
      </SidebarDrawer>

      <TableNumberOfResults
        count={resultEntityroles?.aggregate?.entityroles?.totalCount}
      />
    </div>
  );
}

EntityrolesContent.propTypes = propTypes;
