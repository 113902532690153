import React from 'react';
import DropDown from 'components/DropDown/DropDown';
import cs from 'containers/Entitylink/EntityDropDown.module.scss';

export default function EntityroleTransfpauseDropDown({
  autoComplete,
  id,
  name,
  onChange,
  value
}) {
  const resultEntityroleTransfpause = {
    transformationpause: [
      {id: 'false', name: 'nein'},
      {id: 'true', name: 'ja'}
    ]
  };

  return (
    <div className={cs.root}>
      <div>
        <DropDown
          autoComplete={autoComplete}
          data={resultEntityroleTransfpause.transformationpause}
          id={id}
          keyfield='id'
          name={name}
          onChange={onChange}
          showfield='name'
          value={value}
        />
      </div>
    </div>
  );
}
