import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import Formater from 'utils/Formater';
import GlobalmarketsAttentionMarker, {
  QuotientStockRequired,
  QuotientCapacityRequired
} from './GlobalmarketsAttentionMarker';
import cs from './GlobalmarketsTable.module.scss';
import GlobalmarketsTableRowDetail from './GlobalmarketsTableRowDetail';

const propTypes = {
  entity: PropTypes.object,
  isTableQuoteCapacityVisible: PropTypes.bool,
  isTableQuoteStockVisible: PropTypes.bool
};

function GlobalmarketsTableRow({
  entity,
  isTableQuoteCapacityVisible,
  isTableQuoteStockVisible
}) {
  const [open, setOpen] = useState(false);

  function getStock(economicacc_transf_capacity_demand) {
    const sum = economicacc_transf_capacity_demand?.reduce((a, b) => {
      const bSum = b.economicacc?.economicaccpostings[0].balance || 0;
      return a + bSum;
    }, 0);
    return sum;
  }

  const numberrequired =
    entity?.economicacc_transf_capacity_demand_aggregate?.aggregate.sum
      .numberrequired;

  return (
    <>
      <TableRow
        key={'entityrow' + entity?.id}
        className={cs.rowLink}
        component='div'
        hover
      >
        <TableCell align='left' component='div' onClick={() => setOpen(!open)}>
          <IconButton aria-label='expand row' size='small'>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align='left' component='div'>
          {entity?.name}
        </TableCell>
        <TableCell align='left' component='div' />
        <TableCell align='right' component='div'>
          {Formater.numberFormat(
            getStock(entity?.economicacc_transf_capacity_demand)
          )}
        </TableCell>
        <TableCell align='right' component='div'>
          {isTableQuoteStockVisible ? (
            <small>
              {Formater.numberFormat(
                QuotientStockRequired(
                  getStock(entity?.economicacc_transf_capacity_demand),
                  numberrequired
                )
              )}
            </small>
          ) : (
            ''
          )}
        </TableCell>
        <TableCell align='right' component='div'>
          {Formater.numberFormat(
            entity?.economicacc_transf_capacity_demand_aggregate?.aggregate.sum
              .capacity
          )}
        </TableCell>
        <TableCell align='right' component='div'>
          {isTableQuoteCapacityVisible ? (
            <small>
              {Formater.numberFormat(
                QuotientCapacityRequired(
                  entity?.economicacc_transf_capacity_demand_aggregate
                    ?.aggregate.sum.capacity,
                  numberrequired
                )
              )}
            </small>
          ) : (
            ''
          )}
        </TableCell>
        <TableCell align='right' component='div'>
          <GlobalmarketsAttentionMarker
            capacity={
              entity?.economicacc_transf_capacity_demand_aggregate?.aggregate
                .sum.capacity
            }
            required={
              entity?.economicacc_transf_capacity_demand_aggregate?.aggregate
                .sum.numberrequired
            }
            stock={getStock(entity?.economicacc_transf_capacity_demand)}
          />
        </TableCell>
      </TableRow>
      {open ? (
        <GlobalmarketsTableRowDetail
          key={'entityrowd' + entity?.id}
          economicacc_transf_capacity_demand={
            entity?.economicacc_transf_capacity_demand
          }
          isTableQuoteCapacityVisible={isTableQuoteCapacityVisible}
          isTableQuoteStockVisible={isTableQuoteStockVisible}
        />
      ) : (
        ''
      )}
    </>
  );
}

GlobalmarketsTableRow.propTypes = propTypes;
export default GlobalmarketsTableRow;
