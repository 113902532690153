import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import TrashPerPlayerDemandTable from 'components/TrashPerPlayerTable/TrashPerPlayerDemandTable';
import TrashPerPlayerTable from 'components/TrashPerPlayerTable/TrashPerPlayerTable';
import cs from 'containers/Economicacc/Economicacc.module.scss';

const propTypes = {
  resultTrashPerPlayer: PropTypes.object
};

// <TrashPerPlayerTable regions={resultTrashPerPlayer.regions} />

export default function TrashPerPlayerContent({resultTrashPerPlayer}) {
  return (
    <div className={cs.root}>
      {resultTrashPerPlayer.entity.map((entity, index) => (
        <>
          <Typography key={index} variant='h5'>
            {entity.name}
          </Typography>
          <TrashPerPlayerTable
            economicaccs={entity.economicaccs}
            style={{marginTop: '10px'}}
          />
          <TrashPerPlayerDemandTable
            entitylinksByLinktoentityid={entity.entitylinksByLinktoentityid}
          />
          <br />
        </>
      ))}
    </div>
  );
}

TrashPerPlayerContent.propTypes = propTypes;
