import {useMutation} from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import React from 'react';
import {useHistory} from 'react-router-dom';
import LoginContent from 'containers/Login/LoginContent';
import {LOGIN} from 'containers/Login/LoginSchemas';
import AsyncContent from '../../components/AsyncContent/AsyncContent';

const propTypes = {};

export default function Login() {
  const history = useHistory();

  const [onLoginMutation, {error, loading}] = useMutation(LOGIN);

  async function onLogin(credentials) {
    return onLoginMutation({
      variables: credentials
    });
  }

  function onLoginFinished(res) {
    const isAuthenticated =
      res?.data?.actionAdminLogin?.isAuthenticated === true;

    const token = res?.data?.actionAdminLogin?.jsonwebtoken;

    if (isAuthenticated && token) {
      localStorage.setItem('jsonwebtoken', token);
      localStorage.setItem('userid', res?.data?.actionAdminLogin?.userid);
      localStorage.setItem('userroles', res?.data?.actionAdminLogin?.userroles);
      localStorage.setItem(
        'organisationid',
        res?.data?.actionAdminLogin?.organisationid
      );
      history.push('/');
    } else {
      history.push('/login');
    }
  }

  function onClose() {
    history.push('/');
  }

  return (
    <AsyncContent error={error} loading={loading}>
      <LoginContent
        onClose={onClose}
        onLogin={onLogin}
        onLoginFinished={onLoginFinished}
      />
    </AsyncContent>
  );
}
Login.propTypes = propTypes;
