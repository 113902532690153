import PropTypes from 'prop-types';
import React from 'react';
import {Switch, Route} from 'react-router-dom';
import EconomicaccpostingTable from 'components/EconomicaccpostingTable/EconomicaccpostingTable';

const propTypes = {
  resultEconomicaccpostings: PropTypes.object
};

export default function EconomicaccpostingsContent({
  resultEconomicaccpostings
}) {
  console.log('dd', resultEconomicaccpostings.economicaccpostings);

  return (
    <div>
      <Switch>
        <Route>
          <EconomicaccpostingTable
            economicaccpostings={resultEconomicaccpostings.economicaccpostings}
          />
        </Route>
      </Switch>
    </div>
  );
}

EconomicaccpostingsContent.propTypes = propTypes;
