import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import {useRouteMatch, Link, useHistory} from 'react-router-dom';
import SidebarDrawer from 'components/SidebarDrawer/SidebarDrawer';
import UserlistsTable from 'components/UserlistsTable/UserlistsTable';
import cs from 'containers/Entity/Entity.module.scss';
import Userlist from '../Userlist/Userlist';

const propTypes = {
  onDeleteUserlist: PropTypes.func,
  onPwdSetUserlist: PropTypes.func,
  refetch: PropTypes.func,
  resultUserlists: PropTypes.object
};

export default function UserlistsContent({
  onDeleteUserlist,
  onPwdSetUserlist,
  refetch,
  resultUserlists
}) {
  const history = useHistory();
  const {params, path} = useRouteMatch(['/userlists/new', '/userlists/:id?']);

  function onClose() {
    refetch();
    history.push('/userlists');
  }

  const openSidebarDrawer = params.id != null || path === '/userlists/new';

  return (
    <div className={cs.root}>
      <Button
        color='primary'
        component={Link}
        startIcon={<AddIcon />}
        to='/userlists/new'
        variant='contained'
      >
        Neuer Benutzer
      </Button>
      <UserlistsTable
        onDeleteUserlist={onDeleteUserlist}
        onPwdSetUserlist={onPwdSetUserlist}
        selectedId={parseInt(params.id)}
        userlists={resultUserlists.userlists}
      />

      <SidebarDrawer onClose={onClose} open={openSidebarDrawer}>
        <Userlist id={params.id} onClose={onClose} />
      </SidebarDrawer>
    </div>
  );
}

UserlistsContent.propTypes = propTypes;
