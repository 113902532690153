import PropTypes from 'prop-types';
import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import ATabs from 'components/ATabs/ATabs';
import GameperiodForm from 'components/GameperiodForm/GameperiodForm';
import Taxlawheadquarters from 'containers/Taxlawheadquarters/Taxlawheadquarters';
import Taxlaws from 'containers/Taxlaws/Taxlaws';

const propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  resultGameperiod: PropTypes.object
};

export default function GameperiodContent({
  onClose,
  onSubmit,
  resultGameperiod
}) {
  const {params, path} = useRouteMatch([
    '/gameperiods/new',
    '/gameperiods/:id?'
  ]);

  const tabs = [
    {
      title: 'Stammdaten',
      to: `/gameperiods/${
        resultGameperiod.gameperiod !== null
          ? resultGameperiod.gameperiod.id
          : null
      }`
    }
  ];

  if (resultGameperiod.gameperiod !== null) {
    tabs.push({
      title: 'Steuern',
      to: `/gameperiods/${resultGameperiod.gameperiod.id}/taxlaws`
    });
    tabs.push({
      title: 'Umsatzsteuer',
      to: `/gameperiods/${resultGameperiod.gameperiod.id}/taxlawheadquarters`
    });
  }

  return (
    <>
      <ATabs onClose={onClose} tabs={tabs} />
      <Switch>
        <Route path={`${path}/${params.id}/taxlaws`}>
          <Taxlaws
            id={
              resultGameperiod.gameperiod !== null
                ? resultGameperiod.gameperiod.id
                : undefined
            }
          />
        </Route>
        <Route path={`${path}/${params.id}/taxlawheadquarters`}>
          <Taxlawheadquarters
            id={
              resultGameperiod.gameperiod !== null
                ? resultGameperiod.gameperiod.id
                : undefined
            }
          />
        </Route>
        <Route>
          <GameperiodForm
            gameperiod={
              resultGameperiod ? resultGameperiod.gameperiod : undefined
            }
            onClose={onClose}
            onSubmit={onSubmit}
          />
        </Route>
      </Switch>
    </>
  );
}

GameperiodContent.propTypes = propTypes;
