import PropTypes from 'prop-types';
import React from 'react';
import {Line} from 'react-chartjs-2';
import cs from 'containers/Entity/Entity.module.scss';

const propTypes = {
  graphData: PropTypes.object
};

export default function InhabitantGraphContent({graphData}) {
  return (
    <div className={cs.root}>
      <Line
        data={graphData}
        options={{
          fill: false,
          responsive: true,
          plugins: {
            title: {
              display: false
            }
          }
        }}
      />
    </div>
  );
}

InhabitantGraphContent.propTypes = propTypes;
