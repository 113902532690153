import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Modal from 'react-modal';

// this is the solution to position the popup next to the drawer on larger screens
const drawerWidth = 240;

const useStylesPopup = makeStyles((theme) => ({
  // to disable scrolling in the popup:     position: 'fixed',
  popup: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`
    },
    zIndex: theme.zIndex.appBar + 1
  }
}));

const propTypes = {
  onRequestClose: PropTypes.func, // for the esc key
  children: PropTypes.node
};

function Popup({children, onRequestClose}) {
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const classes = useStylesPopup();

  function onRequestClosePopup() {
    setModalIsOpen(false);
    onRequestClose();
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={onRequestClosePopup}
      shouldCloseOnEsc
    >
      <Paper className={classes.popup} elevation={3}>
        {children}
      </Paper>
    </Modal>
  );
}

Popup.propTypes = propTypes;
export default Popup;
