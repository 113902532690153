import PropTypes from 'prop-types';
import React from 'react';
import UserlistForm from 'components/UserlistForm/UserlistForm';

const propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitFinish: PropTypes.func,
  resultUserlist: PropTypes.object
};

export default function UserlistContent({
  onCancel,
  onSubmit,
  onSubmitFinish,
  resultUserlist
}) {
  return (
    <UserlistForm
      onCancel={onCancel}
      onSubmit={onSubmit}
      onSubmitFinish={onSubmitFinish}
      userlist={resultUserlist?.userlist}
    />
  );
}

UserlistContent.propTypes = propTypes;
