import {useQuery} from '@apollo/react-hooks';
import Typography from '@mui/material/Typography';
import gql from 'graphql-tag';
import React, {useState} from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import EnvUtils from 'utils/EnvUtils';
import EconomicaccsContent from './EconomicaccsContent';
import EconomicaccsTopBar from './EconomicaccsTopBar';

const query = gql`
  query getEconomicacc(
    $where: economicacc_bool_exp! = {}
    $limit: Int = 25
    $offset: Int
  ) {
    economicaccs: economicacc(
      where: $where
      order_by: [
        {player: {role: {code: asc}}}
        {player: {title: asc}}
        {entity: {name: asc}}
      ]
    ) {
      id
      accounttype
      player {
        title
        role {
          categoriename
        }
      }
      entity {
        name
      }
      economicaccpostings(limit: 1, order_by: {id: desc}) {
        balance
      }
    }
  }
`;

export default function Economicaccs() {
  const {game} = useGameSelectContext();

  const [filter, setFilter] = useState({
    accounttype: 'money',
    player: undefined,
    entity: undefined
  });

  function onChangeFilter(nextFilter) {
    setFilter((prev) => ({...prev, ...nextFilter}));
  }

  const {
    data: resultEconomicaccs,
    error,
    loading,
    refetch
  } = useQuery(query, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: {
      limit: 15,
      where: {
        gameid: {_eq: game.id},
        accounttype: {_eq: filter.accounttype},
        playerid: {_eq: filter.player?.id},
        entityid: {_eq: filter.entity?.id}
      }
    }
  });

  return (
    <>
      <Typography gutterBottom variant='h5'>
        Kontostand und Buchungsdetails
      </Typography>
      <EconomicaccsTopBar filter={filter} onChangeFilter={onChangeFilter} />

      <AsyncContent error={error} loading={loading}>
        <EconomicaccsContent
          refetch={refetch}
          resultEconomicaccs={resultEconomicaccs}
        />
      </AsyncContent>
    </>
  );
}
