import PropTypes from 'prop-types';
import React from 'react';
import {useRouteMatch, useHistory} from 'react-router-dom';
import SidebarDrawer from 'components/SidebarDrawer/SidebarDrawer';
import TableNumberOfResults from 'components/TableNumberOfResult/TableNumberOfResult';
import Economictranslog from 'containers/Economictranslog/Economictranslog';
import cs from './EconomictranslogsContent.module.scss';
import EconomictranslogsTable from './EconomictranslogsTable';

const propTypes = {
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onFetchNextPage: PropTypes.func,
  resultEconomictranslogs: PropTypes.object
};

export default function EconomictranslogsContent({
  loading,
  loadingMore,
  onFetchNextPage,
  resultEconomictranslogs
}) {
  const history = useHistory();
  const {params} = useRouteMatch('/economictranslogs/:id?');

  function onRequestCloseSidebar() {
    history.push('/economictranslogs');
  }

  const openSidebarDrawer = params.id != null;

  return (
    <div className={cs.root}>
      <EconomictranslogsTable
        className={cs.table}
        economictranslogs={resultEconomictranslogs?.economictranslogs}
        loading={loading}
        loadingMore={loadingMore}
        onFetchNextPage={onFetchNextPage}
        selectedId={params.id}
      />

      <SidebarDrawer onClose={onRequestCloseSidebar} open={openSidebarDrawer}>
        <Economictranslog id={params.id} onClose={onRequestCloseSidebar} />
      </SidebarDrawer>

      <TableNumberOfResults
        count={
          resultEconomictranslogs?.aggregate?.economictranslogs?.totalCount
        }
      />
    </div>
  );
}

EconomictranslogsContent.propTypes = propTypes;
