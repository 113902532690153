import {useQuery, useMutation} from '@apollo/react-hooks';
import Typography from '@mui/material/Typography';
import gql from 'graphql-tag';
import React from 'react';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import EnvUtils from 'utils/EnvUtils';
import InhabitantsContent from './InhabitantsContent';
import labels from './labels';

const query = gql`
  query inhabitants($gameid: Int!) {
    aggregate: region_aggregate(where: {boardtype: {_eq: "normal"}}) {
      regions: aggregate {
        totalCount: count
      }
    }
    region(where: {boardtype: {_eq: "normal"}}, order_by: {name: asc}) {
      id
      name
      ghosts: inhabitants_view(
        where: {
          entity: {entitytype: {_eq: "ghost"}}
          gameperiod: {game: {id: {_eq: $gameid}}}
        }
      ) {
        entityid
        sustainable_num
        unsustainable_num
        population_sum
        entity {
          name
        }
      }
      cities: inhabitants_view(
        where: {
          entity: {entitytype: {_eq: "city"}}
          gameperiod: {game: {id: {_eq: $gameid}}}
        }
      ) {
        entityid
        sustainable_num
        unsustainable_num
        accomodationcapacity_sum
        entity {
          name
        }
      }
    }
  }
`;

const actionInhabitantsChange = gql`
  mutation actionInhabitantsChange(
    $gameid: Int!
    $regionid: Int!
    $decrease: Boolean
  ) {
    actionInhabitantsChange(
      gameid: $gameid
      regionid: $regionid
      decrease: $decrease
    ) {
      code
      message
    }
  }
`;

export default function Inhabitants() {
  const {game} = useGameSelectContext();

  const {
    data: resultInhabitants,
    loading,
    loadingMore,
    onFetchNextPage,
    refetch
  } = useQuery(query, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: {gameid: game.id}
  });

  const [onActionInhabitantsChangeMutation] = useMutation(
    actionInhabitantsChange,
    {
      ignoreResults: true
    }
  );

  async function onActionInhabitantsChange(regionid, decrease) {
    await onActionInhabitantsChangeMutation({
      variables: {gameid: game.id, regionid, decrease}
    });

    refetch();
  }

  return (
    <>
      <Typography gutterBottom variant='h5'>
        {labels.title}
      </Typography>
      <InhabitantsContent
        loading={loading}
        loadingMore={loadingMore}
        onActionInhabitantsChange={onActionInhabitantsChange}
        onFetchNextPage={onFetchNextPage}
        resultInhabitants={resultInhabitants}
      />
    </>
  );
}
