import {useQuery} from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import GET_TAXLAWHEADQUARTERS from 'containers/Taxlawheadquarters/TaxlawheadquartersSchemas';
import EnvUtils from 'utils/EnvUtils';
import TaxlawheadquartersContent from './TaxlawheadquartersContent';

const propTypes = {
  id: PropTypes.number
};

export default function Taxlawheadquarters({id}) {
  const {
    data: resultTaxlawheadquarters,
    error,
    loading,
    refetch
  } = useQuery(GET_TAXLAWHEADQUARTERS, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: {gameperiodid: id}
  });

  return (
    <AsyncContent error={error} loading={loading}>
      <TaxlawheadquartersContent
        id={id}
        refetch={refetch}
        resultTaxlawheadquarters={resultTaxlawheadquarters}
      />
    </AsyncContent>
  );
}

Taxlawheadquarters.propTypes = propTypes;
