import AddIcon from '@mui/icons-material/Add';
import GetAppIcon from '@mui/icons-material/GetApp';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {triggerBase64Download} from 'common-base64-downloader-react';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  Link,
  useHistory,
  Prompt
} from 'react-router-dom';
import GameTable from 'components/GameTable/GameTable';
import Popup from 'components/PopUp/Popup';
import cs from 'containers/Entity/Entity.module.scss';
import Game from 'containers/Game/Game';
import Authentication from 'utils/Authentication';
import labels from '../../components/SurveyForm/labels';
import m from '../../messages/de.json';

const propTypes = {
  onDeleteGame: PropTypes.func.isRequired,
  onGamesExport: PropTypes.func,
  refetch: PropTypes.func,
  resultGames: PropTypes.object
};

export default function GamesContent({
  onDeleteGame,
  onGamesExport,
  refetch,
  resultGames
}) {
  const history = useHistory();
  const {path, url} = useRouteMatch();

  function onRequestCloseMainContent() {
    refetch();
    history.push('/games');
  }

  const {enqueueSnackbar} = useSnackbar();

  async function getCsvData() {
    return onGamesExport(Authentication.getOrganisationId())
      .then((res) => {
        triggerBase64Download(res.data.actionGamesExport.data, 'spiele');
        enqueueSnackbar(labels.csvdwlSuccess, {
          variant: 'success'
        });
      })
      .catch((e) => {
        enqueueSnackbar(labels.csvdwlError + ` (${e})`, {
          variant: 'error'
        });
      });
  }

  return (
    <div className={cs.root}>
      <Stack direction='row' spacing={1}>
        <Button
          color='primary'
          component={Link}
          startIcon={<AddIcon />}
          to='/games/new'
          variant='contained'
        >
          {m.GamesContent.new}
        </Button>
        <Button
          color='primary'
          onClick={() => getCsvData()}
          startIcon={<GetAppIcon />}
          variant='contained'
        >
          Export
        </Button>
      </Stack>
      <Prompt
        message={(location) =>
          !location.pathname.includes('/close/')
            ? true
            : 'Do you like to change the state of the game?'
        }
      />
      <Switch>
        <Route
          path={[`${url}/new`, `${path}/:id`]}
          render={({match}) => (
            <Popup onRequestClose={onRequestCloseMainContent}>
              <Game id={match.params.id} onClose={onRequestCloseMainContent} />
            </Popup>
          )}
        />
        <Route>
          <GameTable games={resultGames.games} onDeleteGame={onDeleteGame} />
        </Route>
      </Switch>
    </div>
  );
}

GamesContent.propTypes = propTypes;
