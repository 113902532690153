import PropTypes from 'prop-types';
import React from 'react';
import {Route, Switch} from 'react-router-dom';
import TaxlawForm from 'components/TaxlawForm/TaxlawForm';

const propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  resultTaxlaw: PropTypes.object
};

export default function TaxlawContent({onClose, onSubmit, resultTaxlaw}) {
  return (
    <>
      <Switch>
        <Route>
          <TaxlawForm
            onClose={onClose}
            onSubmit={onSubmit}
            taxlaw={resultTaxlaw ? resultTaxlaw.taxlaw : undefined}
          />
        </Route>
      </Switch>
    </>
  );
}

TaxlawContent.propTypes = propTypes;
