import React from 'react';
import DropDown from 'components/DropDown/DropDown';
import landscapeRegionStatus from 'config/landscapeRegionStatus';
import cs from 'containers/Entitylink/EntityDropDown.module.scss';

export default function LandscaperegionStatusDropDown({
  autoComplete,
  id,
  name,
  onChange,
  value
}) {
  const data = Object.keys(landscapeRegionStatus).map((key) => ({
    id: landscapeRegionStatus[key],
    name: landscapeRegionStatus[key]
  }));

  return (
    <div className={cs.root}>
      <DropDown
        autoComplete={autoComplete}
        data={data}
        emptyrow='-'
        id={id}
        keyfield='id'
        name={name}
        onChange={onChange}
        showfield='name'
        value={value}
      />
    </div>
  );
}
