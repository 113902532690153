import {MenuItem, Select, Typography} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';
import React from 'react';
import TopBar from 'components/TopBar/TopBar';
import landscapeRegionStatus from 'config/landscapeRegionStatus';
import LandscapecategoriesSelect from 'containers/LandscapecategoriesSelect/LandscapecategoriesSelect';
import RegionsSelect from 'containers/RegionsSelect/RegionsSelect';
import cs from './LandscaperegionsTopBar.module.scss';
import {LandscaperegionsTopBar as labels} from './labels';

const propTypes = {
  filter: PropTypes.object.isRequired,
  onChangeFilter: PropTypes.func.isRequired
};

function LandscaperegionsTopBar({filter, onChangeFilter}) {
  function onChangeRegion(region) {
    onChangeFilter({region});
  }

  function onChangeLandscapecategorie(landscapecategorie) {
    onChangeFilter({landscapecategorie});
  }

  function onChangeStatus(e) {
    const status = e.target.value === '' ? undefined : e.target.value;
    onChangeFilter({status});
  }

  return (
    <TopBar className={cs.root}>
      <Toolbar className={cs.toolbar} variant='dense'>
        <Typography className={cs.filter}>{labels.region}</Typography>
        <RegionsSelect
          onChangeRegion={onChangeRegion}
          selectedRegion={filter.region}
        />
        <Typography className={cs.filter}>
          {labels.landscapecategorie}
        </Typography>
        <LandscapecategoriesSelect
          onChangeLandscapecategorie={onChangeLandscapecategorie}
          selectedLandscapecategorie={filter.landscapecategorie}
        />
        <Typography className={cs.filter}>{labels.status}</Typography>
        <Select
          displayEmpty
          onChange={onChangeStatus}
          value={filter.status || ''}
        >
          <MenuItem value=''>alle</MenuItem>
          {Object.keys(landscapeRegionStatus).map((key) => (
            <MenuItem key={key} value={landscapeRegionStatus[key]}>
              {landscapeRegionStatus[key]}
            </MenuItem>
          ))}
        </Select>
      </Toolbar>
    </TopBar>
  );
}

LandscaperegionsTopBar.propTypes = propTypes;
export default LandscaperegionsTopBar;
