import {useQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import EnvUtils from 'utils/EnvUtils';

export default function useTemplateGameperiodModel() {
  const GET_TEMPLATEGAMEPERIODS = gql`
    {
      templategameperiods: gameperiod(
        order_by: {game: {title: asc}}
        where: {
          game: {deleted: {_eq: false}, templategameperiodid: {_is_null: true}}
        }
      ) {
        id
        name
        gameid
        game {
          title
        }
      }
    }
  `;

  const {
    data: resultTemplategameperiods,
    error,
    loading,
    refetch
  } = useQuery(GET_TEMPLATEGAMEPERIODS, {
    pollInterval: EnvUtils.getPollInterval()
  });

  return {
    resultTemplategameperiods: {...resultTemplategameperiods},
    loading,
    error
  };
}
