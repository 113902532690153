import PropTypes from 'prop-types';
import React from 'react';
import AsyncContentError from './AsyncContentError';
import AsyncContentSpinner from './AsyncContentSpinner';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  error: PropTypes.object,
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  renderLoading: PropTypes.func
};

export default function AsyncContent({
  children,
  error,
  loading,
  loadingMore,
  renderLoading
}) {
  function renderChildren() {
    return typeof children === 'function' ? children() : children;
  }

  function renderLoadingSpinner() {
    return renderLoading ? renderLoading() : <AsyncContentSpinner />;
  }

  if (error) {
    return <AsyncContentError error={error} />;
  }

  if (loading) {
    return renderLoadingSpinner();
  }

  if (loadingMore) {
    return (
      <>
        {renderChildren()}
        {renderLoadingSpinner()}
      </>
    );
  }

  return renderChildren();
}

AsyncContent.propTypes = propTypes;
