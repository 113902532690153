import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RemoveIcon from '@mui/icons-material/Remove';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import Formater from 'utils/Formater';
import cs from './InhabitantsTable.module.scss';
import InhabitantsTableRowCity from './InhabitantsTableRowCity';
import InhabitantsTableRowGhost from './InhabitantsTableRowGhost';

export function getPopulationSum(inhabitants_view) {
  const sum = inhabitants_view?.reduce((a, b) => {
    const bSum = b.population_sum || 0;
    return a + bSum;
  }, 0);
  return sum;
}

export function getAccomodationcapacitySum(inhabitants_view) {
  const sum = inhabitants_view?.reduce((a, b) => {
    const bSum = b.accomodationcapacity_sum || 0;
    return a + bSum;
  }, 0);
  return sum;
}

const propTypes = {
  onActionInhabitantsChange: PropTypes.func,
  region: PropTypes.object
};

function InhabitantsTableRow({onActionInhabitantsChange, region}) {
  const [open, setOpen] = useState(false);

  function onClickActionInhabitantsChange(regionid, decrease) {
    return onActionInhabitantsChange(regionid, decrease);
  }

  return (
    <>
      <TableRow
        key={'regionrow_' + region?.entityid}
        className={cs.rowLink}
        component='div'
        hover
      >
        <TableCell align='left' component='div' onClick={() => setOpen(!open)}>
          <IconButton aria-label='expand row' size='small'>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align='left' component='div'>
          {region?.name}
        </TableCell>
        <TableCell align='left' component='div' />
        <TableCell align='left' component='div' />
        <TableCell align='left' component='div' />
        <TableCell align='right' component='div'>
          {Formater.numberFormat(getPopulationSum(region?.ghosts))}
        </TableCell>
        <TableCell align='left' component='div'>
          <ButtonGroup
            aria-label='outlined primary button group'
            variant='contained'
          >
            <Tooltip placement='top' title='Bevölkerung reduzieren'>
              <IconButton
                aria-label='decrease population'
                color='primary'
                onClick={() => onClickActionInhabitantsChange(region?.id, true)}
                size='small'
              >
                <RemoveIcon />
              </IconButton>
            </Tooltip>
            <Tooltip placement='top' title='Bevölkerung vergrössern'>
              <IconButton
                aria-label='increase population'
                color='primary'
                onClick={() =>
                  onClickActionInhabitantsChange(region?.id, false)
                }
                size='small'
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        </TableCell>
        <TableCell align='right' component='div'>
          {Formater.numberFormat(getAccomodationcapacitySum(region?.cities))}
        </TableCell>
      </TableRow>
      {open ? (
        <>
          <InhabitantsTableRowGhost
            key={'regionrowghost' + region?.entityid}
            ghosts={region?.ghosts}
          />
          <InhabitantsTableRowCity
            key={'regionrowcity' + region?.entityid}
            cities={region?.cities}
          />
        </>
      ) : (
        ''
      )}
    </>
  );
}

InhabitantsTableRow.propTypes = propTypes;
export default InhabitantsTableRow;
