import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import BuildingToInfraRatioTable from 'components/BuildingToInfraRatioTable/BuildingToInfraRatioTable';
import cs from 'containers/Economicacc/Economicacc.module.scss';

const propTypes = {
  resultBuildingToInfraRatio: PropTypes.object
};

export default function BuildingToInfraRatioContent({
  resultBuildingToInfraRatio
}) {
  return (
    <div className={cs.root}>
      <Typography gutterBottom variant='h5'>
        Qualität der Verkehrsinfrastruktur
      </Typography>

      <BuildingToInfraRatioTable regions={resultBuildingToInfraRatio.regions} />
    </div>
  );
}

BuildingToInfraRatioContent.propTypes = propTypes;
