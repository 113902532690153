import {Typography, Select, MenuItem} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';
import React from 'react';
import TopBar from 'components/TopBar/TopBar';
import GameperiodsSelect from 'containers/GameperiodsSelect/GameperiodsSelect';
import cs from './EconomictranslogsTopBar.module.scss';
import {EconomictranslogsTopBar as labels} from './labels';
import transactiontypeTypes from './transactiontypeTypes';

const propTypes = {
  filter: PropTypes.object.isRequired,
  onChangeFilter: PropTypes.func.isRequired
};

function EconomictranslogsTopBar({filter, onChangeFilter}) {
  function onChangeGameperiod(gameperiod) {
    onChangeFilter({gameperiod});
  }

  function onChangeTransactiontype(e) {
    const transactiontype = e.target.value === '' ? undefined : e.target.value;

    onChangeFilter({transactiontype});
  }

  return (
    <TopBar className={cs.root}>
      <Toolbar className={cs.toolbar} variant='dense'>
        <Typography className={cs.filter}>{labels.gameperiod}</Typography>
        <GameperiodsSelect
          onChangeGameperiod={onChangeGameperiod}
          selectedGameperiod={filter.gameperiod}
        />
        <Typography className={cs.filter}>{labels.transactiontype}</Typography>
        <Select
          displayEmpty
          onChange={onChangeTransactiontype}
          value={filter.transactiontype || ''}
        >
          <MenuItem value=''>alle</MenuItem>
          {Object.entries(transactiontypeTypes).map((transactiontype) => (
            <MenuItem key={transactiontype[0]} value={transactiontype[0]}>
              {transactiontype[1]}
            </MenuItem>
          ))}
        </Select>
      </Toolbar>
    </TopBar>
  );
}

EconomictranslogsTopBar.propTypes = propTypes;
export default EconomictranslogsTopBar;
