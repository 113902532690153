import FileCopyIcon from '@mui/icons-material/FileCopy';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import React from 'react';
import cs from './PlayerTable.module.scss';

const propTypes = {
  players: PropTypes.array
};

export default function PlayerTable({players}) {
  return (
    <div className={cs.root}>
      <TableContainer component={Paper}>
        <Table aria-label='Table' size='small'>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Name</b>
              </TableCell>
              <TableCell align='right'>
                <b>Passwort</b>
              </TableCell>
              <TableCell align='right' />
            </TableRow>
          </TableHead>
          <TableBody>
            {players.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.pwd}</TableCell>
                <TableCell>
                  <Tooltip placement='top' title='copy to clipboard'>
                    <IconButton
                      color='primary'
                      onClick={() => copy(row.pwd)}
                      size='small'
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

PlayerTable.propTypes = propTypes;
