import {useQuery, useMutation} from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import EntitylinkContent from 'containers/Entitylink/EntitylinkContent';
import {
  GET_ENTITYLINK,
  UPDATE_ENTITYLINK,
  INSERT_ENTITYLINK
} from 'containers/Entitylink/EntitylinkSchemas';

const propTypes = {
  entityid: PropTypes.number,
  id: PropTypes.number,
  onClose: PropTypes.func,
  typeFilter: PropTypes.array
};

export default function Entitylink({entityid, id, onClose, typeFilter}) {
  id = id === undefined ? 0 : id;

  const {
    data: resultEntitylink,
    error,
    loading
  } = useQuery(GET_ENTITYLINK, {
    variables: {id}
  });

  const [updateEntitylink] = useMutation(UPDATE_ENTITYLINK);
  const [insertEntitylink] = useMutation(INSERT_ENTITYLINK);

  async function onSubmit(dEntitylink) {
    let res;

    const dEntitylinkNext = {...dEntitylink};
    delete dEntitylinkNext.entity;

    if (resultEntitylink.entitylink !== null) {
      res = updateEntitylink({variables: dEntitylinkNext});
    } else {
      res = insertEntitylink({variables: dEntitylinkNext});
    }

    return res;
  }

  return (
    <AsyncContent error={error} loading={loading}>
      <EntitylinkContent
        entityid={entityid}
        id={id}
        onClose={onClose}
        onSubmit={onSubmit}
        resultEntitylink={resultEntitylink}
        typeFilter={typeFilter}
      />
    </AsyncContent>
  );
}
Entitylink.propTypes = propTypes;
