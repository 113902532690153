import PropTypes from 'prop-types';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import DropDown from 'components/DropDown/DropDown';
import cs from 'containers/Entitylink/EntityDropDown.module.scss';
import useOrganisationModelOrgPermission from 'containers/Game/useOrganisationModelOrgPermission';

const propTypes = {
  autoComplete: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.function,
  value: PropTypes.string
};

export default function OrganisationDropDownOrgPermission({
  autoComplete,
  id,
  name,
  onChange,
  value
}) {
  const {error, loading, resultOrganisations} =
    useOrganisationModelOrgPermission();

  return (
    <div className={cs.root}>
      <AsyncContent error={error} loading={loading}>
        <div>
          <DropDown
            autoComplete={autoComplete}
            data={resultOrganisations.organisations}
            emptyrow='-'
            id={id}
            keyfield='id'
            name={name}
            onChange={onChange}
            showfield='name'
            value={value}
          />
        </div>
      </AsyncContent>
    </div>
  );
}

OrganisationDropDownOrgPermission.propTypes = propTypes;
