import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {useRouteMatch} from 'react-router-dom';
import SimpleTable from 'components/SimpleTable/SimpleTable';
import Formater from 'utils/Formater';
import cs from './TaxlawTable.module.scss';

const propTypes = {
  onClickShowTaxlaw: PropTypes.func,
  selectedId: PropTypes.number,
  taxlaws: PropTypes.array
};

export default function TaxlawTable({onClickShowTaxlaw, selectedId, taxlaws}) {
  const {url} = useRouteMatch();

  function ItemDecoration(id, value, object, field) {
    let nextValue = value;
    if (object) {
      nextValue = object[value];
    } else if (value === 'name') {
      nextValue = '';
    }

    if (field === 'transformtax_government_quota') {
      nextValue = Formater.moneyFormat(value);
    }

    if (field === 'transformtax_labour_quota') {
      nextValue = Formater.moneyFormat(value);
    }

    return (
      <Link
        color='inherit'
        component='button'
        onClick={() => onClickShowTaxlaw(id)}
        style={{textDecoration: 'none'}}
      >
        {nextValue}
      </Link>
    );
  }

  if (taxlaws === undefined) {
    return null;
  }

  return (
    <div className={cs.root}>
      <SimpleTable
        columns={[
          {
            title: 'Regierung',
            object: 'player',
            field: 'title',
            align: 'left'
          },
          {
            title: 'Lohnsteuer [₷]',
            field: 'transformtax_government_quota',
            align: 'right'
          },
          {
            title: 'Lohnkosten [₷]',
            field: 'transformtax_labour_quota',
            align: 'right'
          },
          {
            title: 'Umsatzsteuer [%]',
            field: 'headquarter_salestax_ratio',
            align: 'right'
          }
        ]}
        data={taxlaws}
        itemDecoration={ItemDecoration}
        keyfield='id'
        selectedId={selectedId}
      />
    </div>
  );
}

TaxlawTable.propTypes = propTypes;
