import PropTypes from 'prop-types';
import React from 'react';
import {useRouteMatch, useHistory} from 'react-router-dom';
import SidebarDrawer from 'components/SidebarDrawer/SidebarDrawer';
import TableNumberOfResults from 'components/TableNumberOfResult/TableNumberOfResult';
import cs from 'containers/Landscaperegions/LandscaperegionsContent.module.scss';
import Survey from 'containers/Survey/Survey';
import SurveysTable from './SurveysTable';

const propTypes = {
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onActionSurveyStatusChange: PropTypes.func,
  onFetchNextPage: PropTypes.func,
  resultSurveys: PropTypes.object
};

export default function SurveysContent({
  loading,
  loadingMore,
  onActionSurveyStatusChange,
  onFetchNextPage,
  resultSurveys
}) {
  const history = useHistory();
  const {params} = useRouteMatch('/surveys/:id?');

  function onClose() {
    history.push('/surveys');
  }

  return (
    <div className={cs.root}>
      <SurveysTable
        className={cs.table}
        loading={loading}
        loadingMore={loadingMore}
        onActionSurveyStatusChange={onActionSurveyStatusChange}
        onFetchNextPage={onFetchNextPage}
        selectedId={parseInt(params.id)}
        surveys={resultSurveys?.surveys}
      />

      <SidebarDrawer onClose={onClose} open={params.id != null}>
        {params.id != null ? <Survey id={params.id} onClose={onClose} /> : ''}
      </SidebarDrawer>

      <TableNumberOfResults
        count={resultSurveys?.aggregate?.surveys?.totalCount}
      />
    </div>
  );
}

SurveysContent.propTypes = propTypes;
