import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import TableFixedInfiniteLoading from 'components/TableFixedInfiniteLoading/TableFixedInfiniteLoading';
import cs from 'containers/Landscaperegions/LandscaperegionsTable.module.scss';
import Formater from 'utils/Formater';
import {ResearchCardsTable as labels} from './labels';

const propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onFetchNextPage: PropTypes.func,
  researchcardsplayers: PropTypes.array,
  selectedId: PropTypes.string
};

function ResearchCardsTable({
  className,
  loading,
  loadingMore,
  onFetchNextPage,
  researchcardsplayers,
  selectedId
}) {
  function renderTableHead() {
    return (
      <TableHead component='div'>
        <TableRow component='div'>
          <TableCell align='left' component='div'>
            {labels.header.purchasetime}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.purchasegameperiod}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.player}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.entity}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.effectregion}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Paper className={cx(cs.root, className)}>
      <TableFixedInfiniteLoading
        loading={loading}
        loadingMore={loadingMore}
        onFetchNextPage={onFetchNextPage}
      >
        {() => (
          <>
            {renderTableHead()}
            <TableBody component='div'>
              {researchcardsplayers.map((item) => (
                <TableRow
                  key={item.id}
                  className={cx(cs.rowLink, {
                    [cs.rowLink_selected]: item.id === parseFloat(selectedId)
                  })}
                  component={(props) => (
                    <Link to={`/researchcards/${item.id}`} {...props} />
                  )}
                  hover
                >
                  <TableCell
                    align='left'
                    className={cs.gameperiodColumn}
                    component='div'
                  >
                    {Formater.postgresTsFormat(item.purchasetime)}
                  </TableCell>
                  <TableCell align='left' component='div'>
                    {item.gameperiod.name}
                  </TableCell>
                  <TableCell
                    align='left'
                    className={cs.roleColumn}
                    component='div'
                  >
                    {item.player.title}
                  </TableCell>
                  <TableCell
                    align='left'
                    className={cs.entityColumn}
                    component='div'
                  >
                    {item.entity.name}
                  </TableCell>
                  <TableCell
                    align='left'
                    className={cs.entityColumn}
                    component='div'
                  >
                    {item?.region?.name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </>
        )}
      </TableFixedInfiniteLoading>
    </Paper>
  );
}

ResearchCardsTable.propTypes = propTypes;
export default ResearchCardsTable;
