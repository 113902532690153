import {NetworkStatus} from '@apollo/client';
import {useQuery} from '@apollo/react-hooks';

export default function usePaginatedQuery(query, {listName, ...queryOptions}) {
  const result = useQuery(query, {
    ...queryOptions,
    notifyOnNetworkStatusChange: true
  });
  const {data, fetchMore, networkStatus} = result;
  const loadingMore = networkStatus === NetworkStatus.fetchMore;
  const loading =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    networkStatus === NetworkStatus.refetch;

  let nextOffset;
  let isLastPage = false;

  if (!loading) {
    if (data && listName in data) {
      nextOffset = data[listName].length;
      isLastPage = nextOffset >= data.aggregate[listName].totalCount;
    }
  }

  function onFetchNextPage() {
    if (loading || loadingMore) return () => null;

    if (!data.aggregate || data.aggregate[listName]?.totalCount == null) {
      throw new Error(
        `Please provide an aggregate result for ${listName} and provide a 'totalCount' property `
      );
    }

    return fetchMore({
      variables: {
        offset: nextOffset
      },
      updateQuery(prev, {fetchMoreResult}) {
        if (!fetchMoreResult) return prev;

        const fetchedPageList = fetchMoreResult[listName];
        const updatedList = [...prev[listName]];

        const offset = updatedList.length;

        fetchedPageList.forEach((listItem, index) => {
          updatedList[offset + index] = listItem;
        });

        return {
          ...prev,
          ...fetchMoreResult,
          [listName]: updatedList
        };
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  return {
    ...result,
    loading,
    idle: queryOptions.skip === true,
    loadingMore,
    onFetchNextPage: isLastPage ? null : onFetchNextPage
  };
}
