import Paper from '@mui/material/Paper';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import cs from './TopBar.module.scss';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

const TopBar = ({children, className}) => (
  <Paper className={cx(className, cs.root)} elevation={1}>
    {children}
  </Paper>
);

TopBar.propTypes = propTypes;
export default TopBar;
