import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContentInline from 'components/AsyncContent/AsyncContentInline';
import LandscapecategoriesSelectContent from './LandscapecategoriesSelectContent';

const propTypes = {
  className: PropTypes.string,
  onChangeLandscapecategorie: PropTypes.func,
  selectedLandscapecategorie: PropTypes.object
};

const query = gql`
  query landscapetype {
    page: region_aggregate(limit: 10000) {
      aggregate {
        totalCount: count
      }
    }
    landscapecategories: landscapetype(order_by: {categorie: asc}) {
      categorie
    }
  }
`;

export default function LandscapecategoriesSelect({
  className,
  onChangeLandscapecategorie,
  selectedLandscapecategorie
}) {
  const {data, error, loading} = useQuery(query, {});

  return (
    <div className={className}>
      <AsyncContentInline error={error} loading={loading}>
        {() => (
          <LandscapecategoriesSelectContent
            landscapecategories={data.landscapecategories}
            onChangeLandscapecategorie={onChangeLandscapecategorie}
            selectedLandscapecategorie={selectedLandscapecategorie}
          />
        )}
      </AsyncContentInline>
    </div>
  );
}

LandscapecategoriesSelect.propTypes = propTypes;
