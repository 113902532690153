import {Typography} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';
import React from 'react';
import TopBar from 'components/TopBar/TopBar';
import OrganisationDropDownOrgPermission from 'containers/Game/OrganisationDropDownOrgPermission';
import m from '../../messages/de.json';
import cs from '../Entityroles/EntityrolesTopBar.module.scss';

const propTypes = {
  filter: PropTypes.object.isRequired,
  onChangeFilter: PropTypes.func.isRequired
};

function UserlistsTopBar({filter, onChangeFilter}) {
  function onChangeEntityType(e) {
    let organisationid = e.target.value === '' ? undefined : e.target.value;

    if (parseInt(organisationid) === 0) {
      organisationid = undefined;
    }
    onChangeFilter({organisationid});
  }

  return (
    <TopBar className={cs.root}>
      <Toolbar className={cs.toolbar} variant='dense'>
        <Typography className={cs.filter}>
          {m.UserlistsTopBar.organisation}
        </Typography>
        <OrganisationDropDownOrgPermission
          onChange={onChangeEntityType}
          value={filter.organisationid}
        />
      </Toolbar>
    </TopBar>
  );
}

UserlistsTopBar.propTypes = propTypes;
export default UserlistsTopBar;
