import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import Dashboard from 'containers/Dashboard/Dashboard';
import Authentication from 'utils/Authentication';

const propTypes = {
  children: PropTypes.node
};

export default function AuthenticatedRoute({children, ...rest}) {
  const isAuthenticated = Authentication.isAuthenticated();
  return (
    <Route
      {...rest}
      render={({location}) =>
        isAuthenticated ? (
          <Dashboard>{children}</Dashboard>
        ) : (
          <>
            <Redirect
              to={{
                pathname: '/login',
                state: {from: location}
              }}
            />
          </>
        )
      }
    />
  );
}

AuthenticatedRoute.propTypes = propTypes;
