import LaunchIcon from '@mui/icons-material/Launch';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import {Link, useRouteMatch} from 'react-router-dom';

const propTypes = {
  gameperiod: PropTypes.object.isRequired
};

export default function GameperiodTableMenu({gameperiod}) {
  const {url} = useRouteMatch();
  return (
    <>
      <Link to={`${url}/${gameperiod.id}/taxlaws`}>
        <Tooltip title='zu den Steuereinstellungen'>
          <LaunchIcon />
        </Tooltip>
      </Link>
    </>
  );
}

GameperiodTableMenu.propTypes = propTypes;
