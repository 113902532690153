import PropTypes from 'prop-types';
import React from 'react';
import {Route, Switch} from 'react-router-dom';
import EntitylinkResearchForm from 'components/EntitylinkResearchForm/EntitylinkResearchForm';

const propTypes = {
  entityid: PropTypes.number,
  id: PropTypes.number,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  resultEntitylinkResearch: PropTypes.object,
  typeFilter: PropTypes.array
};

export default function EntitylinkResearchContent({
  entityid,
  onClose,
  onSubmit,
  resultEntitylinkResearch,
  typeFilter
}) {
  const isNew = resultEntitylinkResearch
    ? !('entitylink' in resultEntitylinkResearch)
    : true;

  return (
    <>
      <Switch>
        <Route>
          <EntitylinkResearchForm
            key={
              resultEntitylinkResearch.entitylink !== null
                ? resultEntitylinkResearch.entitylink.id
                : undefined
            }
            entityid={entityid}
            entitylink={
              resultEntitylinkResearch
                ? resultEntitylinkResearch.entitylink
                : null
            }
            isNew={isNew}
            onClose={onClose}
            onSubmit={onSubmit}
            typeFilter={typeFilter}
          />
        </Route>
      </Switch>
    </>
  );
}

EntitylinkResearchContent.propTypes = propTypes;
