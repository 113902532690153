import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useRouteMatch, NavLink} from 'react-router-dom';
import DeletionDialog from 'components/DeletionDialog/DeletionDialog';
import labels from './labels';

const propTypes = {
  game: PropTypes.object.isRequired,
  onDeleteGame: PropTypes.func.isRequired
};

export default function GameTableMenu({game, onDeleteGame}) {
  const {url} = useRouteMatch();

  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState(false);

  function onClickDelete() {
    setIsDeletionDialogOpen(true);
  }

  function onConfirmDelete() {
    return onDeleteGame(game);
  }

  return (
    <>
      <IconButton color='primary' onClick={onClickDelete} size='small'>
        <DeleteIcon />
      </IconButton>

      <DeletionDialog
        isOpen={isDeletionDialogOpen}
        onClose={() => setIsDeletionDialogOpen(false)}
        onConfirm={onConfirmDelete}
        title={labels.confirmDelete(game)}
      />
    </>
  );
}

GameTableMenu.propTypes = propTypes;
