import {Tab} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

const propTypes = {
  index: PropTypes.number,
  onChange: PropTypes.func,
  title: PropTypes.string,
  to: PropTypes.string
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

export default function ATab({index, onChange, title, to}) {
  function onChangeClick(event) {
    onChange(event, index);
  }

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (
        <Link ref={ref} to={to} {...linkProps} />
      )),
    [to]
  );
  return (
    <Tab
      label={title}
      {...a11yProps(index)}
      component={renderLink}
      onChange={onChangeClick}
    />
  );
}

ATab.propTypes = propTypes;
