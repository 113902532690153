import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React from 'react';
import Formater from 'utils/Formater';
import cs from './InhabitantsTable.module.scss';

const propTypes = {
  ghosts: PropTypes.array
};

function InhabitantsTableRowGhost({ghosts}) {
  return (
    <>
      {ghosts?.map((ghost) => (
        <TableRow
          key={'ghosts' + ghost?.entityid}
          className={cs.rowLink}
          component='div'
          hover
        >
          <TableCell align='right' className={cs.detailRow} component='div' />
          <TableCell align='right' className={cs.detailRow} component='div' />
          <TableCell align='left' className={cs.detailRow} component='div'>
            {ghost?.entity.name}
          </TableCell>
          <TableCell align='right' className={cs.detailRow} component='div'>
            {Formater.numberFormat(ghost?.unsustainable_num)}
          </TableCell>
          <TableCell align='right' className={cs.detailRow} component='div'>
            {Formater.numberFormat(ghost?.sustainable_num)}
          </TableCell>
          <TableCell align='right' component='div'>
            {Formater.numberFormat(ghost?.population_sum)}
          </TableCell>
          <TableCell align='right' component='div' />
          <TableCell align='right' component='div' />
        </TableRow>
      ))}
    </>
  );
}

InhabitantsTableRowGhost.propTypes = propTypes;
export default InhabitantsTableRowGhost;
