import {useQuery, useMutation} from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import React from 'react';
import GameperiodContent from 'containers/Gameperiod/GameperiodContent';
import {
  GET_GAMEPERIOD,
  UPDATE_GAMEPERIOD
} from 'containers/Gameperiod/GameperiodSchemas';
import AsyncContent from '../../components/AsyncContent/AsyncContent';

const propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func
};

export default function Gameperiod({id, onClose}) {
  id === undefined ? (id = 0) : (id = id);

  const {
    data: resultGameperiod,
    error,
    loading
  } = useQuery(GET_GAMEPERIOD, {
    variables: {id}
  });
  const [updateGameperiod] = useMutation(UPDATE_GAMEPERIOD);

  async function onSubmit(dGameperiod) {
    let res;

    if (resultGameperiod.gameperiod !== null) {
      // delete dGameperiod.taskstatus;
      const dGameperiodNext = {...dGameperiod}; // clone the obj. without reverence to later delete attribute
      delete dGameperiodNext.taskstatus;
      res = updateGameperiod({variables: dGameperiodNext});
    }

    return res;
  }

  return (
    <AsyncContent error={error} loading={loading}>
      <GameperiodContent
        onClose={onClose}
        onSubmit={onSubmit}
        resultGameperiod={resultGameperiod}
      />
    </AsyncContent>
  );
}
Gameperiod.propTypes = propTypes;
