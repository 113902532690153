import GetAppIcon from '@mui/icons-material/GetApp';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import {triggerBase64Download} from 'common-base64-downloader-react';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import Form from 'components/Form/Form';
import FormColumn from 'components/Form/FormColumn';
import FormItem from 'components/Form/FormItem';
import TextInput from 'components/TextInput/TextInput';
import EnvUtils from 'utils/EnvUtils';
import Formater from 'utils/Formater';
import cs from './SurveyForm.module.scss';
import labels from './labels';

const initialValues = {
  gameperiodid: ''
};

const propTypes = {
  className: PropTypes.string,
  onCancel: PropTypes.func,
  onSurveyAnswers: PropTypes.func,
  survey: PropTypes.object
};

export default function SurveyForm({
  className,
  onCancel,
  onSurveyAnswers,
  survey
}) {
  const formik = useFormik({
    initialValues: survey || initialValues,
    enableReinitialize: true
  });

  const {enqueueSnackbar} = useSnackbar();

  async function getCsvData(surveyid) {
    return onSurveyAnswers(surveyid)
      .then((res) => {
        triggerBase64Download(res.data.actionSurveyGetAnswers.data, 'umfrage');
        enqueueSnackbar(labels.csvdwlSuccess, {
          variant: 'success'
        });
      })
      .catch((e) => {
        enqueueSnackbar(labels.csvdwlError + ` (${e})`, {
          variant: 'error'
        });
      });
  }

  const urlSurvey = EnvUtils.getUiplayUrl() + '/feedback';

  return (
    <div className={cx(className, cs.root)}>
      <Typography gutterBottom variant='h6'>
        {labels.title}
      </Typography>
      <Form
        actions={
          <>
            <Button
              onClick={() => getCsvData(formik.values.id)}
              startIcon={<GetAppIcon />}
              variant='outlined'
            >
              Ergebnisse
            </Button>
            <Button onClick={onCancel} variant='outlined'>
              {labels.cancel}
            </Button>
          </>
        }
      >
        <FormColumn>
          <FormItem
            error={formik.errors.start}
            label={labels.start}
            name='tsstart'
            value={Formater.postgresTsFormat(formik.values.tsstart)}
          >
            <TextInput readonly />
          </FormItem>
          <FormItem
            error={formik.errors.end}
            label={labels.end}
            name='tsend'
            value={Formater.postgresTsFormat(formik.values.tsend)}
          >
            <TextInput readonly />
          </FormItem>
          <FormItem
            error={formik.errors.questionnaireName}
            label={labels.questionnaireName}
            name='questionnaireName'
            value={formik.values.questionnaire.name}
          >
            <TextInput readonly />
          </FormItem>
          <FormItem
            error={formik.errors.gameTitle}
            label={labels.gameTitle}
            name='gameTitle'
            value={formik.values.game.title}
          >
            <TextInput readonly />
          </FormItem>
          <FormItem
            error={formik.errors.orgName}
            label={labels.orgName}
            name='orgName'
            value={formik.values.game.organisation.name}
          >
            <TextInput readonly />
          </FormItem>
          <br />
          <Typography>
            <b>Zugriff</b> auf die Umfrage erfolgt für die SpielerInnen unter:
            <br />
            <a href={urlSurvey} rel='noreferrer' target='_blank'>
              {urlSurvey}
            </a>
            <br />
            <br />
            Die SpielerInnen müssen an der UI-Play App angemeldet sein (mit
            ihrem Pin).
            <br />
            Pro Rolle sind mehrer Umfragen möglich.
          </Typography>
        </FormColumn>
      </Form>
    </div>
  );
}
SurveyForm.propTypes = propTypes;
