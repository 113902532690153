import {FormControl, MenuItem, Select} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import cs from './RegionsSelectContent.module.scss';

const propTypes = {
  onChangeRegion: PropTypes.func,
  regions: PropTypes.array,
  selectedRegion: PropTypes.object
};

export default function RegionsSelectContent({
  onChangeRegion,
  regions,
  selectedRegion
}) {
  function onChange(e) {
    onChangeRegion(e.target.value === '' ? undefined : e.target.value);
  }

  return (
    <FormControl fullWidth>
      <Select
        autoWidth
        className={cs.select}
        displayEmpty
        onChange={onChange}
        value={selectedRegion || ''}
      >
        <MenuItem value=''>alle</MenuItem>
        {regions.map((region) => (
          <MenuItem key={region.id} value={region}>
            {region.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

RegionsSelectContent.propTypes = propTypes;
