import {useQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import OrganisationContent from 'containers/Organisation/OrganisationContent';

const query = gql`
  query getOrganisation($id: Int!) {
    organisation: organisation_by_pk(id: $id) {
      id
      name
      note
    }
  }
`;

const createOrganisationMutation = gql`
  mutation insertOrganisation($name: String, $note: String) {
    insert_organisation(objects: {name: $name, note: $note}) {
      returning {
        id
      }
    }
  }
`;

const updateOrganisationMutation = gql`
  mutation updateOrganisation($id: Int!, $name: String, $note: String) {
    update_organisation_by_pk(
      pk_columns: {id: $id}
      _set: {name: $name, note: $note}
    ) {
      id
      name
    }
  }
`;

const propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func
};

export default function Organisation({id, onClose}) {
  const nextId = !parseInt(id) ? 0 : id;

  const {
    data: resultOrganisation,
    error,
    loading
  } = useQuery(query, {
    variables: {id: nextId}
  });
  const [onUpdateOrganisationMutation] = useMutation(
    updateOrganisationMutation
  );
  const [onCreateOrganisationMutation] = useMutation(
    createOrganisationMutation
  );

  async function onSubmit(dOrganisation) {
    if (dOrganisation.id > 0) {
      return onUpdateOrganisationMutation({
        variables: dOrganisation
      });
    } else {
      return onCreateOrganisationMutation({
        variables: dOrganisation
      });
    }
  }

  return (
    <AsyncContent error={error} loading={loading}>
      <OrganisationContent
        onCancel={onClose}
        onSubmit={onSubmit}
        onSubmitFinish={onClose}
        resultOrganisation={resultOrganisation}
      />
    </AsyncContent>
  );
}
Organisation.propTypes = propTypes;
