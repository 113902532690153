import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import TableFixedInfiniteLoading from 'components/TableFixedInfiniteLoading/TableFixedInfiniteLoading';
import Formater from 'utils/Formater';
import cs from './EconomictranslogsTable.module.scss';
import {EconomictranslogsTable as labels} from './labels';
import transactioncodeTypes from './transactioncodeTypes';
import transactiontypeTypes from './transactiontypeTypes';

const propTypes = {
  className: PropTypes.string,
  onFetchNextPage: PropTypes.func,
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  economictranslogs: PropTypes.array,
  selectedId: PropTypes.string
};

function EconomictranslogsTable({
  className,
  economictranslogs,
  loading,
  loadingMore,
  onFetchNextPage,
  selectedId
}) {
  function renderTableHead() {
    return (
      <TableHead component='div'>
        <TableRow component='div'>
          <TableCell align='left' component='div'>
            {labels.header.gameperiod}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.transactiontype}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.fromeconomicacc}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.toeconomicacc}
          </TableCell>
          <TableCell align='right' component='div'>
            {labels.header.amountmoney}
          </TableCell>
          <TableCell align='right' component='div'>
            {labels.header.amountentity}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.entity}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.transactioncode}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.description}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Paper className={cx(cs.root, className)}>
      <TableFixedInfiniteLoading
        loading={loading}
        loadingMore={loadingMore}
        onFetchNextPage={onFetchNextPage}
      >
        {() => (
          <>
            {renderTableHead()}
            <TableBody component='div'>
              {economictranslogs.map((economictranslog) => (
                <TableRow
                  key={economictranslog.id}
                  className={cx(cs.rowLink, {
                    [cs.rowLink_selected]:
                      economictranslog.id === parseFloat(selectedId)
                  })}
                  component={(props) => (
                    <Link
                      to={`/economictranslogs/${economictranslog.id}`}
                      {...props}
                    />
                  )}
                  hover
                >
                  <TableCell align='left' component='div'>
                    {economictranslog.gameperiod.name}
                  </TableCell>
                  <TableCell align='left' component='div'>
                    <Chip
                      label={
                        transactiontypeTypes[economictranslog.transactiontype]
                      }
                      size='small'
                    />
                  </TableCell>
                  <TableCell align='left' component='div'>
                    {economictranslog.fromeconomicacc?.player?.title}
                  </TableCell>
                  <TableCell align='left' component='div'>
                    {economictranslog.toeconomicacc?.player?.title}
                  </TableCell>
                  <TableCell align='right' component='div'>
                    {Formater.numberFormat(economictranslog.amountmoney)}
                  </TableCell>
                  <TableCell align='right' component='div'>
                    {Formater.numberFormat(economictranslog.amountentity)}
                  </TableCell>
                  <TableCell align='left' component='div'>
                    {economictranslog.toeconomicacc
                      ? economictranslog.toeconomicacc?.entity?.name
                      : economictranslog.fromeconomicacc?.entity?.name}
                  </TableCell>
                  <TableCell align='left' component='div'>
                    {economictranslog.transactioncode in transactioncodeTypes
                      ? transactioncodeTypes[economictranslog.transactioncode]
                      : economictranslog.transactioncode}
                  </TableCell>
                  <TableCell align='left' component='div'>
                    {economictranslog.txt}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </>
        )}
      </TableFixedInfiniteLoading>
    </Paper>
  );
}

EconomictranslogsTable.propTypes = propTypes;
export default EconomictranslogsTable;
