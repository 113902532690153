import {useQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import EnvUtils from 'utils/EnvUtils';

export default function useGameperiodModel(gameid) {
  const GET_GAMEPERIODS = gql`
    query getGameperiods($gameid: Int!) {
      gameperiods: gameperiod(
        order_by: {id: desc}
        where: {_and: [{gameid: {_eq: $gameid}}, {closed: {_eq: false}}]}
      ) {
        id
        name
      }
    }
  `;
  const {
    data: resultGameperiods,
    error,
    loading
  } = useQuery(GET_GAMEPERIODS, {
    pollInterval: EnvUtils.getPollInterval(),
    variables: gameid
  });

  return {
    resultGameperiods: {...resultGameperiods},
    loading,
    error
  };
}
