import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import cs from './Button.module.scss';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string
};

export default function Button({
  children,
  className,
  disabled,
  onClick,
  type = 'button'
}) {
  return (
    <button
      className={cx(className, cs.root, {[cs.root_disabled]: disabled})}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
}

Button.propTypes = propTypes;
