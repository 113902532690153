import {Typography} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';
import React from 'react';
import TopBar from 'components/TopBar/TopBar';
import entitytypeCategory from 'config/entitytypeCategory';
import EntitiesSelect from 'containers/EntitiesSelect/EntitiesSelect';
import PlayersSelect from 'containers/PlayersSelect/PlayersSelect';
import m from '../../messages/de.json';
import cs from '../Entityroles/EntityrolesTopBar.module.scss';
import AccounttypeDropDown from './AccounttypeDropDown';

const propTypes = {
  filter: PropTypes.object.isRequired,
  onChangeFilter: PropTypes.func.isRequired
};

export default function EconomicaccsTopBar({filter, onChangeFilter}) {
  function onChangeAccounttype(accounttype) {
    onChangeFilter({accounttype});
  }

  function onChangePlayer(player) {
    onChangeFilter({player});
  }

  function onChangeEntity(entity) {
    onChangeFilter({entity});
  }

  return (
    <TopBar className={cs.root}>
      <Toolbar className={cs.toolbar} variant='dense'>
        <Typography className={cs.filter}>Typ</Typography>
        <AccounttypeDropDown
          name='accounttype'
          onChange={(e) => onChangeAccounttype(e.target.value)}
          selectedAccounttype={filter.accounttype}
          value={filter.accounttype}
        />
        <Typography className={cs.filter}>
          {m.EconomicaccsTopBar.player}
        </Typography>
        <PlayersSelect
          onChangePlayer={onChangePlayer}
          selectedPlayer={filter.player}
        />
        <Typography className={cs.filter}>
          {m.EconomicaccsTopBar.entity}
        </Typography>
        <EntitiesSelect
          entitytypeCategory={entitytypeCategory.product}
          onChangeEntity={onChangeEntity}
          selectedEntity={filter.entity}
        />
      </Toolbar>
    </TopBar>
  );
}

EconomicaccsTopBar.propTypes = propTypes;
