// eslint-disable-next-line import/no-extraneous-dependencies
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React from 'react';
import cs from './TrashPerPlayerTable.module.scss';

const propTypes = {
  economicaccs: PropTypes.array
};

export default function TrashPerPlayerTable({economicaccs}) {
  function filterByBalanceNotNull(dataEconomicaccs) {
    const dataToFilter = [...dataEconomicaccs];
    return dataToFilter.filter(
      (row) => row.economicaccpostings[0]?.balance > 0
    );
  }

  function sumBalance(dataEconomicaccs) {
    const sum = dataEconomicaccs.reduce((a, b) => {
      const bSum = b.economicaccpostings[0]?.balance || 0;
      return a + bSum;
    }, 0);
    return sum;
  }

  return (
    <div className={cs.root}>
      <TableContainer component={Paper}>
        <Table aria-label='Table' size='small'>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Rolle</b>
              </TableCell>
              <TableCell align='right'>
                <b>Summe lagernd</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterByBalanceNotNull(economicaccs).map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.player.title}</TableCell>
                <TableCell align='right'>
                  {row.economicaccpostings[0]?.balance}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell align='right'>
                <b>Summe:</b>
              </TableCell>
              <TableCell align='right'>
                <b>{sumBalance(economicaccs)}</b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

TrashPerPlayerTable.propTypes = propTypes;
