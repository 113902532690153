import PauseIcon from '@mui/icons-material/Pause';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import TableFixedInfiniteLoading from 'components/TableFixedInfiniteLoading/TableFixedInfiniteLoading';
import m from '../../messages/de.json';
import cs from './EntityrolesTable.module.scss';
import EntityrolesTableMenu from './EntityrolesTableMenu';
import {EntityrolesTable as labels} from './labels';

const propTypes = {
  className: PropTypes.string,
  entityroles: PropTypes.array,
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onDesctructionEntityrole: PropTypes.func.isRequired,
  onFetchNextPage: PropTypes.func,
  onToggleEntityroleStatus: PropTypes.func.isRequired,
  selectedId: PropTypes.string
};

function EntityrolesTable({
  className,
  entityroles,
  loading,
  loadingMore,
  onDesctructionEntityrole,
  onFetchNextPage,
  onToggleEntityroleStatus,
  selectedId
}) {
  const {url} = useRouteMatch();

  function renderTableHead() {
    return (
      <TableHead component='div'>
        <TableRow component='div'>
          <TableCell align='left' component='div' />
          <TableCell align='left' component='div'>
            {labels.header.landscaperegion}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.entity}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.player}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.status}
          </TableCell>
          <TableCell align='center' component='div'>
            {labels.header.transformationpause}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Paper className={cx(cs.root, className)}>
      <TableFixedInfiniteLoading
        loading={loading}
        loadingMore={loadingMore}
        onFetchNextPage={onFetchNextPage}
      >
        {() => (
          <>
            {renderTableHead()}
            <TableBody component='div'>
              {entityroles.map((entityrole) => (
                <TableRow
                  key={entityrole.id}
                  className={cx(cs.rowLink, {
                    [cs.rowLink_selected]:
                      entityrole.id === parseFloat(selectedId)
                  })}
                  component={(props) => (
                    <Link to={`${url}/${entityrole.id}`} {...props} />
                  )}
                  hover
                >
                  <TableCell
                    align='left'
                    component='div'
                    onClick={(e) => e.preventDefault()}
                  >
                    <EntityrolesTableMenu
                      entityrole={entityrole}
                      onDesctructionEntityrole={onDesctructionEntityrole}
                      onToggleEntityroleStatus={onToggleEntityroleStatus}
                    />
                  </TableCell>
                  <TableCell
                    align='left'
                    className={cs.roleColumn}
                    component='div'
                  >
                    {entityrole?.region?.name}
                  </TableCell>
                  <TableCell align='left' component='div'>
                    {entityrole.entity?.name}
                  </TableCell>
                  <TableCell align='left' component='div'>
                    {entityrole.player?.title}
                  </TableCell>
                  <TableCell align='left' component='div'>
                    {entityrole.status in m.EntityrolesTable.status
                      ? m.EntityrolesTable.status[entityrole.status]
                      : entityrole.status}
                  </TableCell>
                  <TableCell align='center' component='div'>
                    {entityrole.transformationpause && (
                      <Chip
                        color='primary'
                        icon={<PauseIcon />}
                        label='ja'
                        size='small'
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </>
        )}
      </TableFixedInfiniteLoading>
    </Paper>
  );
}

EntityrolesTable.propTypes = propTypes;
export default EntityrolesTable;
