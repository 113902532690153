import PropTypes from 'prop-types';
import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import ATabs from 'components/ATabs/ATabs';
import EconomicaccForm from 'components/EconomicaccForm/EconomicaccForm';
import Economicaccpostings from 'containers/Economicaccpostings/Economicaccpostings';

const propTypes = {
  onClose: PropTypes.func,
  resultEconomicacc: PropTypes.object
};

export default function EconomicaccContent({onClose, resultEconomicacc}) {
  // const {path} = useRouteMatch();
  const {params, path} = useRouteMatch([
    '/economicaccs/new',
    '/economicaccs/:id?'
  ]);

  const tabs = [
    {
      title: 'Stammdaten',
      to: `/economicaccs/${
        resultEconomicacc.economicacc !== null
          ? resultEconomicacc.economicacc.id
          : null
      }`
    }
  ];

  if (resultEconomicacc.economicacc !== null) {
    tabs.push({
      title: 'Buchungen',
      to: `/economicaccs/${resultEconomicacc.economicacc.id}/economicaccpostings`
    });
  }

  return (
    <>
      <ATabs onClose={onClose} tabs={tabs} />
      <Switch>
        <Route path={`${path}/${params.id}/economicaccpostings`}>
          <Economicaccpostings
            id={
              resultEconomicacc.economicacc !== null
                ? resultEconomicacc.economicacc.id
                : undefined
            }
          />
        </Route>
        <Route>
          <EconomicaccForm
            economicacc={
              resultEconomicacc ? resultEconomicacc.economicacc : undefined
            }
            onClose={onClose}
          />
        </Route>
      </Switch>
    </>
  );
}

EconomicaccContent.propTypes = propTypes;
