import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import DropDown from 'components/DropDown/DropDown';
import cs from 'containers/Entitylink/EntityDropDown.module.scss';
import usePlayerModel from 'containers/Entityrole/usePlayerModel';

export default function PlayerDropDown({
  autoComplete,
  code,
  id,
  name,
  onChange,
  value
}) {
  const {error, loading, resultPlayers} = usePlayerModel(code);

  return (
    <div className={cs.root}>
      <AsyncContent error={error} loading={loading}>
        <DropDown
          autoComplete={autoComplete}
          data={resultPlayers?.players}
          emptyrow='-'
          id={id}
          keyfield='id'
          name={name}
          onChange={onChange}
          showfield='title'
          value={value}
        />
      </AsyncContent>
    </div>
  );
}
