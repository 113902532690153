import PropTypes from 'prop-types';
import React from 'react';
import {useRouteMatch, useHistory} from 'react-router-dom';
import EconomicaccTable from 'components/EconomicaccTable/EconomicaccTable';
import SidebarDrawer from 'components/SidebarDrawer/SidebarDrawer';
import Economicacc from 'containers/Economicacc/Economicacc';
import cs from 'containers/Economicacc/Economicacc.module.scss';

const propTypes = {
  refetch: PropTypes.func,
  resultEconomicaccs: PropTypes.object
};

export default function EconomicaccsContent({refetch, resultEconomicaccs}) {
  const history = useHistory();
  // const {path, url} = useRouteMatch();
  const {params, path} = useRouteMatch([
    '/economicaccs/new',
    '/economicaccs/:id?'
  ]);

  const openSidebarDrawer = params.id != null || path === '/economicaccs/new';

  function onRequestCloseMainContent() {
    refetch();
    history.push('/economicaccs');
  }

  return (
    <div className={cs.root}>
      <EconomicaccTable
        economicaccs={resultEconomicaccs.economicaccs}
        selectedId={parseInt(params.id)}
      />
      <SidebarDrawer open={openSidebarDrawer}>
        <Economicacc id={params.id} onClose={onRequestCloseMainContent} />
      </SidebarDrawer>
    </div>
  );
}

EconomicaccsContent.propTypes = propTypes;
