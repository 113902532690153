import format from 'date-fns/format';
import {de} from 'date-fns/locale';
import parseISO from 'date-fns/parseISO';

export default {
  formatDate(date) {
    return format(date, 'dd.MM.yyyy', {locale: de});
  },

  formatDateTime(date) {
    return format(date, 'dd.MM.yyyy HH:mm', {locale: de});
  },

  parseAndFormatDate(date) {
    return this.formatDate(parseISO(date));
  },

  getTodayIsoDate() {
    const todayDate = new Date();
    return todayDate.toISOString().split('T')[0];
  },

  getTodayDateDeFormat() {
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    return format(today, 'dd.MM.yyyy', {locale: de});
  }
};
