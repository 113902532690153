import {CircularProgress} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import usePromiseState, {PromiseState} from 'hooks/usePromiseState';
import cs from './StatusToggleDialog.module.scss';
import labels from './labels';

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.node
};

export default function StatusToggleDialog({
  isOpen,
  onConfirm,
  onClose,
  title = labels.title
}) {
  const [confirmPromise, setConfirmPromise] = usePromiseState();

  function onClickConfirm() {
    setConfirmPromise(onConfirm);
  }

  useEffect(() => {
    if (confirmPromise.state === PromiseState.FULFILLED) {
      onClose();
    }
  }, [onClose, confirmPromise]);

  const isPending = confirmPromise.state === PromiseState.PENDING;
  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <DialogContentText>{labels.description}</DialogContentText>
        {confirmPromise.state === PromiseState.REJECTED && (
          <Typography className={cs.error} variant='body2'>
            {labels.error}
          </Typography>
        )}
      </DialogContent>

      <DialogActions className={cs.actions}>
        <Button disabled={isPending} onClick={onClose}>
          {labels.cancel}
        </Button>
        <Button
          key='confirm'
          className={cx(cs.delete, {[cs.delete_disabled]: isPending})}
          color='primary'
          disabled={isPending}
          onClick={onClickConfirm}
        >
          {labels.delete}
        </Button>
        {isPending && <CircularProgress className={cs.progress} size={24} />}
      </DialogActions>
    </Dialog>
  );
}

StatusToggleDialog.propTypes = propTypes;
