import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import LandscapecategoryChip from 'components/LandscapecategoryChip/LandscapecategoryChip';
import TableFixedInfiniteLoading from 'components/TableFixedInfiniteLoading/TableFixedInfiniteLoading';
import Formater from 'utils/Formater';
import cs from './ProtocolsTable.module.scss';
import {ProtocolsTableLandscaperegion as labels} from './labels';

const propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onFetchNextPage: PropTypes.func,
  protocols: PropTypes.array
};

function ProtocolsTable({
  className,
  loading,
  loadingMore,
  onFetchNextPage,
  protocols
}) {
  const {url} = useRouteMatch();

  function renderTableHead() {
    return (
      <TableHead component='div'>
        <TableRow component='div'>
          <TableCell align='left' component='div'>
            {labels.header.gameperiod}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.ts}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.region}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.code}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.landscapecategorie}
          </TableCell>
          <TableCell align='left' component='div'>
            {labels.header.delta}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Paper className={cx(cs.root, className)}>
      <TableFixedInfiniteLoading
        loading={loading}
        loadingMore={loadingMore}
        onFetchNextPage={onFetchNextPage}
      >
        {() => (
          <>
            {renderTableHead()}
            <TableBody component='div'>
              {protocols.map((protocol) => (
                <TableRow
                  key={protocol.id}
                  className={cs.rowLink}
                  component={(props) => (
                    <Link to={`${url}/${protocol.id}`} {...props} />
                  )}
                  hover
                >
                  <TableCell align='left' component='div'>
                    {protocol.gameperiod.name}
                  </TableCell>
                  <TableCell
                    align='left'
                    className={cs.entityColumn}
                    component='div'
                  >
                    {Formater.postgresTsFormat(protocol.tstamp)}
                  </TableCell>
                  <TableCell align='left' component='div'>
                    {protocol.region.name}
                  </TableCell>
                  <TableCell align='left' component='div'>
                    {protocol.landscaperegion.landscape.code}
                  </TableCell>
                  <TableCell align='left' component='div'>
                    <LandscapecategoryChip
                      value={
                        protocol.landscaperegion.landscape?.landscapetype
                          ?.categorie
                      }
                    />
                  </TableCell>
                  <TableCell align='left' component='div'>
                    {getDetails(protocol)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </>
        )}
      </TableFixedInfiniteLoading>
    </Paper>
  );

  function getDetails(protocol) {
    if (protocol.operation === 'INSERT' || protocol.operation === 'DELETE') {
      return protocol.operation in labels.operations
        ? labels.operations[protocol.operation]
        : 'NaN';
    } else {
      const s = [];
      if (protocol?.delta?.regionid) {
        s.push('Neue Region: ' + protocol.new_region?.name);
      }
      if (protocol?.delta?.status) {
        s.push(
          protocol.delta.status in labels.status
            ? labels.status[protocol.delta.status]
            : protocol.delta.status
        );
      }
      if (s !== '') {
        return s.toString();
      } else {
        return Formater.jsonToText(protocol.delta);
      }
    }
  }
}

ProtocolsTable.propTypes = propTypes;
export default ProtocolsTable;
