import gql from 'graphql-tag';

export default gql`
  fragment Entityrole_entity_role_mutable on entity_role {
    id
    entityid
    gameperiodid
    number
    playerid
    regionid
    status
    transformationpause
  }
`;
