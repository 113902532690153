import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {ApolloProvider} from '@apollo/react-hooks';
import {ThemeProvider} from '@mui/material/styles';
import React from 'react';
import {createRoot} from 'react-dom/client';
import Modal from 'react-modal';
import GameSelectProvider from 'containers/GameSelect/GameSelectProvider';
import EnvUtils from 'utils/EnvUtils';
import App from './App';
import * as serviceWorker from './serviceWorker';
import theme from './theme';
import './global.scss';

const cache = new InMemoryCache({
  dataIdFromObject: (result) => {
    // Merging entity_role and entity_role_view types
    if (
      result.__typename === 'entity_role_view' ||
      result.__typename === 'entity_role'
    ) {
      return `entity_role__${result.id}`;
    }
    if (result.id && result.__typename) {
      return `${result.__typename}__${result.id}`;
    }

    return null;
  }
});

// ensure that the cookies are sent with each graphql querie sent to the server
const requestLink = new HttpLink({
  uri: EnvUtils.getApiUrl()
});

// @ todo: later those error could be visualised in the UI
// @todo: catch not autorised here (exmple https://www.youtube.com/watch?v=dr9I4xPYkdw min 1:21)
const errorLink = onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors) {
    graphQLErrors.map(({locations, message, path}) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

/*
14.6.2020: unfortunatly this causes in the live environment an refresh per minute
const retryLink = new RetryLink({
  max: 10,
  delay: 5000,
  interval: (delay, count) => (count > 5 ? 10000 : delay),
}); 
const link = ApolloLink.from([retryLink, errorLink, requestLink]);
*/
const link = ApolloLink.from([errorLink, requestLink]);
const authLink = setContext((_, {headers}) => {
  const token = localStorage.getItem('jsonwebtoken');
  if (token) {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    };
  }
  return {};
});

const client = new ApolloClient({
  cache,
  link: authLink.concat(link),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network'
    },
    query: {
      fetchPolicy: 'network-only'
    }
  }
});

function getApp() {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme.light}>
        <GameSelectProvider>
          <App />
        </GameSelectProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

Modal.setAppElement('#root');
const root = createRoot(document.getElementById('root'));
root.render(getApp());

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
