import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import {useRouteMatch, Link, useHistory} from 'react-router-dom';
import OrganisationsTable from 'components/OrganisationsTable/OrganisationsTable';
import SidebarDrawer from 'components/SidebarDrawer/SidebarDrawer';
import cs from 'containers/Entity/Entity.module.scss';
import m from '../../messages/de.json';
import Organisation from '../Organisation/Organisation';

const propTypes = {
  onDeleteOrganisation: PropTypes.func,
  refetch: PropTypes.func,
  resultOrganisations: PropTypes.object
};

export default function OrganisationsContent({
  onDeleteOrganisation,
  refetch,
  resultOrganisations
}) {
  const history = useHistory();
  const {params, path} = useRouteMatch([
    '/organisations/new',
    '/organisations/:id?'
  ]);

  function onClose() {
    refetch();
    history.push('/organisations');
  }

  const openSidebarDrawer = params.id != null || path === '/organisations/new';

  return (
    <div className={cs.root}>
      <Typography gutterBottom variant='h5'>
        {m.OrganisatinsContent.title}
      </Typography>
      <Button
        color='primary'
        component={Link}
        startIcon={<AddIcon />}
        to='/organisations/new'
        variant='contained'
      >
        {m.OrganisatinsContent.new}
      </Button>
      <OrganisationsTable
        onDeleteOrganisation={onDeleteOrganisation}
        organisations={resultOrganisations.organisations}
        selectedId={parseInt(params.id)}
      />

      <SidebarDrawer onClose={onClose} open={openSidebarDrawer}>
        <Organisation id={params.id} onClose={onClose} />
      </SidebarDrawer>
    </div>
  );
}

OrganisationsContent.propTypes = propTypes;
