import gql from 'graphql-tag';

const GET_SUPPLYRATIO = gql`
  query SupplyratioGraph(
    $gameid: Int!
    $where: economictranslog_supplyratio_basic_bool_exp! = {}
  ) {
    players: player(
      where: {role: {code: {_eq: "CONSUMERROLE"}}, gameid: {_eq: $gameid}}
    ) {
      title
      id
      region {
        color
      }
    }

    supplyratios: gameperiod(
      where: {gameid: {_eq: $gameid}}
      order_by: {id: asc}
    ) {
      id
      name
      economictranslog_supplyratio_basic(where: $where) {
        supplyratio
        playerid
      }
    }

    gameperiods: gameperiod(
      order_by: {id: asc}
      where: {gameid: {_eq: $gameid}, closed: {_eq: true}}
    ) {
      id
      gameid
      period
      name
      closed
    }
  }
`;

export default GET_SUPPLYRATIO;
