import {Chip} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  buildingsInfrastructure: PropTypes.number,
  buildingsInfrastructureToBe: PropTypes.number
};

export default function BuildingToInfraRatioQuality({
  buildingsInfrastructure,
  buildingsInfrastructureToBe
}) {
  let label = 'sehr gut';
  let color = 'primary';

  if (buildingsInfrastructure < buildingsInfrastructureToBe) {
    label = 'schwach';
    color = 'secondary';
  } else if (buildingsInfrastructure === buildingsInfrastructureToBe) {
    label = 'gut';
    color = 'primary';
  }

  return <Chip color={color} label={label} />;
}

BuildingToInfraRatioQuality.propTypes = propTypes;
