import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import Form from 'components/Form/Form';
import FormColumn from 'components/Form/FormColumn';
import FormItem from 'components/Form/FormItem';
import TextInput from 'components/TextInput/TextInput';
import EntityDropDown from 'containers/Entitylink/EntityDropDown';
import EntityroleStatusDrowDown from 'containers/Entityrole/EntityroleStatusDropDown';
import EntityroleTransfpauseDrowDown from 'containers/Entityrole/EntityroleTransfpauseDropDown';
import PlayerDropDown from 'containers/Entityrole/PlayerDropDown';
import RegionDropDown from 'containers/Entityrole/RegionDropDown';
import m from '../../messages/de.json';
import cs from './EntityroleForm.module.scss';
import labels from './labels';

const validate = (values) => {
  const errors = {};

  if (!values.regionid || values.regionid === '0') {
    errors.regionid = 'Region ist ein Pflichtfeld';
  }

  if (!values.entityid || values.entityid === '0') {
    errors.entityid = 'Entität ist ein Pflichtfeld';
  }

  if (!values.playerid || values.playerid === '0') {
    errors.playerid = 'Besitzer ist ein Pflichtfeld';
  }

  if (!values.status || values.status === '0') {
    errors.status = 'Status ist ein Pflichtfeld';
  }

  return errors;
};

const initialValues = {entityid: 0};

const propTypes = {
  className: PropTypes.string,
  entityrole: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitFinish: PropTypes.func
};
export default function EntityroleForm({
  className,
  entityrole,
  onCancel,
  onSubmit,
  onSubmitFinish
}) {
  const formik = useFormik({
    initialValues: entityrole || initialValues,
    validate,
    onSubmit: onSubmitForm,
    enableReinitialize: true
  });

  const isCreateForm = formik.values.id === undefined;

  const {enqueueSnackbar} = useSnackbar();

  function onSubmitForm(values) {
    return onSubmit(values)
      .then(() => {
        // console.log('Result-Data: ', data);
        enqueueSnackbar(m.dataHandlingPromise.fulfilled, {
          variant: 'success'
        });
        formik.resetForm();
        onSubmitFinish();
      })
      .catch((error) => {
        enqueueSnackbar(`${m.dataHandlingPromise.rejected} (Error: ${error})`, {
          variant: 'error'
        });
      });
  }

  return (
    <div className={cx(className, cs.root)}>
      <Typography gutterBottom variant='h6'>
        {isCreateForm ? labels.title.create : labels.title.edit}
      </Typography>
      <Form
        actions={
          <>
            <Button onClick={onCancel} variant='outlined'>
              {labels.cancel}
            </Button>
            <Button
              color='primary'
              disabled={formik.isSubmitting || !formik.isValid}
              type='submit'
              variant='contained'
            >
              {m.dataHandlingForm.save}
            </Button>
          </>
        }
        onSubmit={formik.handleSubmit}
      >
        <FormColumn>
          <FormItem
            error={formik.errors.entityid}
            label='Entität'
            name='entityid'
            onChange={formik.handleChange}
            value={formik.values.entityid}
          >
            {isCreateForm ? (
              <EntityDropDown
                filterEntitytype={[
                  'company',
                  'miningarea',
                  'energy',
                  'landuse',
                  'city',
                  'traffic',
                  'ghost'
                ]}
              />
            ) : (
              <TextInput readonly />
            )}
          </FormItem>
          <FormItem
            error={formik.errors.regionid}
            label='Region'
            name='regionid'
            onChange={formik.handleChange}
            value={formik.values.regionid}
          >
            {isCreateForm ? <RegionDropDown /> : <TextInput readonly />}
          </FormItem>{' '}
          <FormItem
            error={formik.errors.playerid}
            label='Besitzer'
            name='playerid'
            onChange={formik.handleChange}
            value={formik.values.playerid}
          >
            <PlayerDropDown />
          </FormItem>
          <FormItem
            error={formik.errors.status}
            label='Nachhaltigkeits Status'
            name='status'
            onChange={formik.handleChange}
            value={formik.values.status}
          >
            {isCreateForm ? (
              <EntityroleStatusDrowDown />
            ) : (
              <TextInput readonly />
            )}
          </FormItem>
          {!isCreateForm ? (
            <FormItem
              error={formik.errors.transformationpause}
              label='Pause (die Transformation ist deaktiviert z.B. für Streik)'
              name='transformationpause'
              onChange={formik.handleChange}
              value={formik.values.transformationpause}
            >
              <EntityroleTransfpauseDrowDown />
            </FormItem>
          ) : null}
        </FormColumn>
      </Form>
    </div>
  );
}
EntityroleForm.propTypes = propTypes;
